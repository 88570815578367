import { Navigate } from 'react-router-dom'
import { RootState } from '../store'
import Loader from 'shared/components/data-visualization/indicators/Loader'
import { useAppSelector } from 'shared/hooks/useReduxHooks'

const SuperAdminRoute = ({ children }: { children: React.ReactNode }) => {
  const authIsLoading = useAppSelector((state: RootState) => state.auth.authIsLoading)
  const user = useAppSelector((state: RootState) => state.auth.user)

  if (!user?.superAdmin) {
    if (!authIsLoading) {
      return <Navigate to="/" />
    }

    if (authIsLoading) {
      return (
        <div className="w-full h-[100vh] flex justify-center items-center">
          <Loader />
        </div>
      )
    }
  }

  return <>{children}</>
}

export default SuperAdminRoute
