import React from 'react'
import { chartColorCodes } from 'shared/utils/business/chart/chartColorCodes'
import { PerformancePercentage } from 'shared/types/vessel'
import { Tooltip } from 'react-tooltip'

interface Props {
  isAdvanced?: boolean
  performanceData?: PerformancePercentage
}

interface PerformanceItem {
  name: string
  value: number
  color: string
  unit: string
  tooltipText: string
}

function PerformanceSummary({ isAdvanced = false, performanceData }: Props) {
  const performanceItems: PerformanceItem[] = performanceData
    ? [
        {
          name: 'aboveLimit',
          value: performanceData.aboveLimit,
          color: `rgb(${chartColorCodes.red})`,
          unit: '%',
          tooltipText: 'Above Limit',
        },
        {
          name: 'inRange',
          value: performanceData.inRange,
          color: `rgb(${chartColorCodes.green})`,
          unit: '%',
          tooltipText: 'In Range',
        },
        {
          name: 'belowLimit',
          value: performanceData.belowLimit,
          color: `rgb(${chartColorCodes.orange})`,
          unit: '%',
          tooltipText: 'Below Limit',
        },
        {
          name: 'maneuvering',
          value: performanceData.maneuvering,
          color: `rgb(${chartColorCodes.darkGray})`,
          unit: '%',
          tooltipText: 'Manoeuvring',
        },
      ]
    : []

  if (!isAdvanced) {
    return (
      <div className="flex flex-col items-center w-full">
        <p className="text-grayText mb-2">Upgrade plan</p>
        <div className="grid grid-cols-4 w-full gap-4">
          {[1, 2, 3, 4].map((i) => (
            <div
              key={i}
              className="flex flex-col items-center"
            >
              <div className="w-full h-1.5 mt-1 rounded-md bg-morning-mist" />
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="grid grid-cols-4 w-full gap-4">
      {performanceItems.map((item) => {
        const tooltipId = `performance-${item.name}-${performanceData?.aboveLimit}-${performanceData?.inRange}-${performanceData?.belowLimit}-${performanceData?.maneuvering}-tooltip`

        return (
          <div
            key={item.name}
            className="flex flex-col items-center"
          >
            <div
              className="w-full flex flex-col items-center cursor-pointer"
              data-tooltip-id={tooltipId}
            >
              <p>
                {item.value}
                {item.unit}
              </p>
              <div
                className="w-full h-1.5 mt-1 rounded-md"
                style={{ backgroundColor: item.color }}
              />
            </div>
            <Tooltip
              id={tooltipId}
              border="1px solid #8990A3"
              content={`${item.value}% ${item.tooltipText}`}
              style={{
                borderRadius: '8px',
                backgroundColor: 'white',
                color: '#5D657A',
                zIndex: 1000,
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

export default PerformanceSummary
