import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { functions } from '../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import {
  BarChartData,
  GraphQueryArgs,
  MissedSavingsBarChartData,
  PerformanceBarChartData,
  PerformanceBarChartValue,
  SingleDataMetrics,
  ValuePercentage,
  MassFlowRateDistributionRange,
  MassFlowRateDistributionResponse,
  VesselDataForDashboardResponse,
} from 'shared/types/graphApi'

const totalsGraphApi = createApi({
  reducerPath: 'totalsGraphApi',
  baseQuery: fakeBaseQuery(),
  keepUnusedDataFor: 600,
  endpoints(build) {
    return {
      getAverageSFOCInRange: build.query<ValuePercentage, GraphQueryArgs>({
        queryFn: async ({ vesselId, mainEngineFlowMeter, shaftMeterId, startTime, endTime }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getAverageSFOCInRange')
            const result: any = await getData({ vesselId, mainEngineFlowMeter, shaftMeterId, startTime, endTime })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getAverageFuelEfficiencyInRange: build.query<ValuePercentage, GraphQueryArgs>({
        queryFn: async ({ vesselId, allEngineFlowMeters, startTime, endTime, isSplit }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getAverageFuelEfficiencyInRange')
            const result: any = await getData({ vesselId, allEngineFlowMeters, startTime, endTime, isSplit })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getSingleDataMetricsWithChangeInRange: build.query<SingleDataMetrics[], GraphQueryArgs>({
        queryFn: async ({
          vesselId,
          mainEngineFlowMeter,
          allEngineFlowMeters,
          shaftMeterId,
          startTime,
          endTime,
        }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getSingleDataMetricsWithChangeInRange')
            const result: any = await getData({
              vesselId,
              mainEngineFlowMeter,
              allEngineFlowMeters,
              shaftMeterId,
              startTime,
              endTime,
            })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getTotalFuelConsumedBarChartInRange: build.query<BarChartData[], GraphQueryArgs>({
        queryFn: async ({
          vesselId,
          mainEngineFlowMeters,
          auxEngineFlowMeters,
          startTime,
          endTime,
        }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getTotalFuelConsumedBarChartInRange')
            const result: any = await getData({
              vesselId,
              mainEngineFlowMeters,
              auxEngineFlowMeters,
              startTime,
              endTime,
            })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getCO2EmissionsBarChartInRange: build.query<BarChartData, GraphQueryArgs>({
        queryFn: async ({
          vesselId,
          mainEngineFlowMeters,
          auxEngineFlowMeters,
          startTime,
          endTime,
        }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getCO2EmissionsBarChartInRange')
            const result: any = await getData({
              vesselId,
              mainEngineFlowMeters,
              auxEngineFlowMeters,
              startTime,
              endTime,
            })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getPerformanceBarChartInRange: build.query<PerformanceBarChartData, GraphQueryArgs>({
        queryFn: async ({
          vesselId,
          mainEngineFlowMeterId,
          isSplit,
          startTime,
          endTime,
          massFlowRateUpperThreshold,
          massFlowRateLowerThreshold,
        }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getPerformanceBarChartInRange')
            const result: any = await getData({
              vesselId,
              mainEngineFlowMeterId,
              isSplit,
              startTime,
              endTime,
              massFlowRateUpperThreshold,
              massFlowRateLowerThreshold,
            })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getTripPathInRange: build.query<any, GraphQueryArgs>({
        queryFn: async ({
          vesselId,
          mainEngineFlowMeterId,
          isSplit,
          startTime,
          endTime,
          massFlowRateUpperThreshold,
          massFlowRateLowerThreshold,
          maxPower,
        }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getTripPathInRange')
            const result: any = await getData({
              vesselId,
              mainEngineFlowMeterId,
              isSplit,
              startTime,
              endTime,
              massFlowRateUpperThreshold,
              massFlowRateLowerThreshold,
              maxPower,
            })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getMissedSavingsBarChart: build.query<MissedSavingsBarChartData, GraphQueryArgs>({
        queryFn: async ({
          vesselId,
          mainEngineFlowMeterId,
          isSplit,
          startTime,
          endTime,
          massFlowRateUpperThreshold,
          massFlowRateLowerThreshold,
        }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getMissedSavingsBarChart')
            const result: any = await getData({
              vesselId,
              mainEngineFlowMeterId,
              isSplit,
              startTime,
              endTime,
              massFlowRateUpperThreshold,
              massFlowRateLowerThreshold,
            })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getMassFlowRateDistribution: build.query<MassFlowRateDistributionResponse, GraphQueryArgs>({
        queryFn: async ({ vesselId, mainEngineFlowMeterId, isSplit, startTime, endTime }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getMassFlowRateDistribution')
            const result: any = await getData({
              vesselId,
              mainEngineFlowMeterId,
              isSplit,
              startTime,
              endTime,
            })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getVesselDataForDashboard: build.query<VesselDataForDashboardResponse[], void>({
        queryFn: async () => {
          try {
            const getData = httpsCallable(functions, 'getVesselDataForDashboard')
            const result: any = await getData()
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
    }
  },
})

export const {
  useGetAverageSFOCInRangeQuery,
  useGetAverageFuelEfficiencyInRangeQuery,
  useGetSingleDataMetricsWithChangeInRangeQuery,
  useGetTotalFuelConsumedBarChartInRangeQuery,
  useGetCO2EmissionsBarChartInRangeQuery,
  useGetPerformanceBarChartInRangeQuery,
  useGetTripPathInRangeQuery,
  useGetMissedSavingsBarChartQuery,
  useGetMassFlowRateDistributionQuery,
  useGetVesselDataForDashboardQuery,
} = totalsGraphApi

export { totalsGraphApi }
