import { useNavigate } from 'react-router-dom'
import Logo from 'assets/images/Periscope_Logo.svg'
import HelpIcon from 'assets/help_icon.png'
import Select from 'shared/components/forms/Select'
import { useTimezoneSelect, allTimezones, ITimezoneOption } from 'react-timezone-select'
import { changeTimezoneOffset, RootState } from 'core/store'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useReduxHooks'
import Button from 'shared/components/ui/buttons/Button'

function MainHeader() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleButtonClick = (route: string) => {
    navigate(route)
  }

  const user = useAppSelector((state: RootState) => {
    return state.auth.user
  })
  const selectedTimezone = useAppSelector((state: RootState) => {
    return state.app.selectedTimezone
  })

  // const showTimeZoneSelect = user?.email === 'tests@gmail.com'

  // options: 'original' | 'altName' | 'abbrev' | 'offsetHidden'
  const labelStyle = 'original'
  const timezones = {
    ...allTimezones,
  }

  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })

  function handleTimezoneChange(selectedOptions: ITimezoneOption[] | ITimezoneOption) {
    const newValues = selectedOptions.constructor === Array ? selectedOptions : [selectedOptions]
    const newTimezone = newValues[0]
    dispatch(changeTimezoneOffset(newTimezone))
  }

  function setLocalTimezone() {
    const offset = new Date().getTimezoneOffset()
    const localTimezone = timezoneOptions.find((timezone) => {
      return timezone.offset === offset / -60
    })
    dispatch(changeTimezoneOffset(localTimezone))
  }

  return (
    <div className="header w-screen justify-between h-20 bg-white flex border-b border-grayOutlineColor">
      <img
        src={Logo}
        className="h-8 m-auto mx-4 cursor-pointer"
        alt="Ship Project Logo"
        onClick={() => handleButtonClick('/')}
      />

      <div className="flex items-center h-full mx-4 gap-6">
        <Button
          primary
          large
          onClick={setLocalTimezone}
        >
          Use local time
        </Button>
        <div className="w-96">
          <Select
            options={timezoneOptions}
            placeholder="Choose your timezone"
            onChange={handleTimezoneChange}
            isSearchable
            defaultValue={selectedTimezone}
            value={selectedTimezone}
          />
        </div>
        <img
          src={HelpIcon}
          className="w-5 h-auto cursor-pointer"
          onClick={() => handleButtonClick('/help-centre')}
        ></img>

        {/* <div className="w-12 h-12 bg-accentColor rounded-full">
          <p className="flex items-center justify-center h-full text-black font-semibold text-xl">JD</p>
        </div> */}
      </div>
    </div>
  )
}

export default MainHeader
