import { RootState } from 'core/store'
import { useAppSelector } from 'shared/hooks/useReduxHooks'
import TotalFuelConsumed from '../../../features/performance/components/calculations/TotalFuelConsumed'
import TotalCO2Emitted from '../../../features/performance/components/calculations/TotalCO2Emitted'
function Calculations() {
  const { startTime, endTime } = useAppSelector((state: RootState) => {
    return state.app.dateRange
  })
  const { offset } = useAppSelector((state: RootState) => {
    return state.app.selectedTimezone
  })
  const selectedVessel = useAppSelector((state: RootState) => {
    return state.app.selectedVessel
  })

  return (
    <div className="flex flex-col gap-4 pb-6">
      <div className="mt-4">
        {startTime && endTime && (
          <div className="mb-8">
            <TotalFuelConsumed
              vessel={selectedVessel || null}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
            />
          </div>
        )}

        {startTime && endTime && (
          <TotalCO2Emitted
            vessel={selectedVessel || null}
            startTime={startTime}
            endTime={endTime}
            offset={offset || 0}
          />
        )}
      </div>
    </div>
  )
}

export default Calculations
