import Alert from 'shared/components/ui/feedback/Alert'
import { useBlocker } from 'react-router-dom'

const LeavePageBlocker = ({ dirty }: { dirty: boolean }) => {
  const message = 'You have unsaved changes. Are you sure you want to leave?'

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) => dirty && currentLocation.pathname !== nextLocation.pathname
  )

  return (
    <>
      {blocker.state === 'blocked' && (
        <div className="min-h-screen">
          <Alert
            open
            title="Are you sure?"
            type="warning"
            alertText={message}
            hasCancelButton
            eventButtonText="Leave"
            onCancel={blocker.reset}
            onEvent={blocker.proceed}
            onOutsideClick={blocker.reset}
          />
        </div>
      )}
    </>
  )
}

export default LeavePageBlocker
