import { resetLineChartState, RootState } from 'core/store'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useReduxHooks'
import { useEffect } from 'react'
import FuelConsumption from '../../../features/performance/components/overview/FuelConsumption'
import ShaftSpeedAndPower from '../../../features/performance/components/overview/ShaftSpeedAndPower'
import Speed from '../../../features/performance/components/overview/Speed'
import FuelConsumptionVsSpeedTrend from '../../../features/performance/components/overview/FuelConsumptionVsSpeedTrend'

function Overview() {
  const dispatch = useAppDispatch()
  const { startTime, endTime } = useAppSelector((state: RootState) => {
    return state.app.dateRange
  })
  const { offset } = useAppSelector((state: RootState) => {
    return state.app.selectedTimezone
  })
  const selectedVessel = useAppSelector((state: RootState) => {
    return state.app.selectedVessel
  })

  useEffect(() => {
    dispatch(resetLineChartState())
  }, [])

  return (
    <div className="flex flex-col gap-4 pb-6">
      <div className="mt-4">
        {selectedVessel && (
          <div className="mb-8">
            <ShaftSpeedAndPower
              vessel={selectedVessel || null}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <FuelConsumption
              vessel={selectedVessel || null}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <Speed
              vessel={selectedVessel || null}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <FuelConsumptionVsSpeedTrend
              vessel={selectedVessel || null}
              startTime={startTime}
              endTime={endTime}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Overview
