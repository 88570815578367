import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { functions } from '../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import { User } from 'shared/types/user'

const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['User'],
  endpoints(build) {
    return {
      getAllUsers: build.query<User[], void>({
        providesTags: ['User'],
        keepUnusedDataFor: 86400, // 24 hours
        queryFn: async () => {
          const getAllUsers = httpsCallable(functions, 'getAllUsers')
          const result = await getAllUsers()
          const data: any = result.data

          if (!data[1]) {
            throw new Error(data[2])
          }

          return { data: data[0] }
        },
      }),
      createUser: build.mutation<any, { name: string; surname: string; email: string; company: string | null }>({
        invalidatesTags: ['User'],
        queryFn: async ({ name, surname, email, company }) => {
          const createNewUser = httpsCallable(functions, 'createNewUser')
          const result = await createNewUser({ name, surname, email, company })
          const data: any = result.data

          if (!data[1]) {
            return { success: false, error: data[2] }
          }
          return { success: true, data: data[0] }
        },
      }),
      deleteUser: build.mutation<any, string>({
        invalidatesTags: ['User'],
        queryFn: async (targetUid) => {
          const deleteFunction = httpsCallable(functions, 'deleteUser')
          const result = await deleteFunction(targetUid)
          const data: any = result.data

          if (!data[0]) {
            return { success: false, error: data[1] }
          }

          return { success: true, data: data[1] }
        },
      }),
      resetPassword: build.mutation<any, string>({
        queryFn: async (email) => {
          const resetPasswordFunction = httpsCallable(functions, 'resetPassword')
          const result = await resetPasswordFunction(email)
          const data: any = result.data

          if (!data[0]) {
            return { success: false, error: data[1] }
          }

          return { success: true, data: data[1] }
        },
      }),
      setUserCustomClaims: build.mutation<any, { targetUid: string; isAdmin: boolean }>({
        invalidatesTags: ['User'],
        queryFn: async ({ targetUid, isAdmin }) => {
          const setUserCustomClaimsFunction = httpsCallable(functions, 'setUserCustomClaims')
          const result = await setUserCustomClaimsFunction({ targetUid, isAdmin })
          const data: any = result.data

          if (!data[1]) {
            return { success: false, error: data[2] }
          }

          return { success: true, data: data[0] }
        },
      }),
    }
  },
})

export const {
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useResetPasswordMutation,
  useSetUserCustomClaimsMutation,
} = userApi
export { userApi }
