import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { functions } from 'core/config/firebase'
import { httpsCallable } from 'firebase/functions'
import { Company } from 'shared/types/company'

const handleFirebaseResponse = (data: any) => {
  if (data && data.message) {
    return { error: data.message }
  }
  return { data }
}

const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Company'],
  endpoints(build) {
    return {
      getAllCompanies: build.query<Company[], void>({
        providesTags: ['Company'],
        keepUnusedDataFor: 86400, // 24 hours
        queryFn: async () => {
          const getCompanies = httpsCallable(functions, 'getCompanies')
          const result = await getCompanies()
          return handleFirebaseResponse(result.data)
        },
      }),
      createCompany: build.mutation<any, { name: string }>({
        invalidatesTags: ['Company'],
        queryFn: async ({ name }) => {
          const createCompanyFunction = httpsCallable(functions, 'createCompany')
          const result = await createCompanyFunction({ name })
          return handleFirebaseResponse(result.data)
        },
      }),
      deleteCompany: build.mutation<any, string>({
        invalidatesTags: ['Company'],
        queryFn: async (companyId) => {
          const deleteCompanyFunction = httpsCallable(functions, 'deleteCompany')
          const result = await deleteCompanyFunction({ companyId })
          return handleFirebaseResponse(result.data)
        },
      }),
    }
  },
})

export const { useGetAllCompaniesQuery, useCreateCompanyMutation, useDeleteCompanyMutation } = companyApi
export { companyApi }
