import { VesselDataForDashboardResponse } from 'shared/types/graphApi'

type thresholdStatus = {
  status: 'In range' | 'Out of range'
  message: string
}

const getThresholdStatus = (minutesSinceUpdate: number, vessel: VesselDataForDashboardResponse): thresholdStatus => {
  console.log(Object.entries(vessel))
  if (
    vessel.power === null ||
    vessel.massFlowRate === null ||
    vessel.latitude === null ||
    vessel.longitude === null ||
    vessel.aisStatus === null ||
    vessel.speed === null ||
    vessel.latestDataTimestamp === null ||
    vessel.vesselName === null ||
    vessel.vesselId === null
  ) {
    return {
      status: 'Out of range',
      message: `null values for ${Object.entries(vessel)
        .filter(([key, value]) => value === null && key !== 'beaufortNumber')
        .map(([key]) => key)
        .join(', ')}`,
    }
  }
  if (minutesSinceUpdate <= 240 && minutesSinceUpdate >= 0) {
    if ((vessel.power > 1 && vessel.massFlowRate <= 0) || (vessel.power <= 0 && vessel.massFlowRate > 0)) {
      return {
        status: 'Out of range',
        message: 'Power and Mass Flow Rate are out of range',
      }
    }
    return {
      status: 'In range',
      message: 'Power and Mass Flow Rate are in range',
    }
  }
  return {
    status: 'Out of range',
    message: 'No data available for the last 4 hours',
  }
}

export default getThresholdStatus
