import { VesselData } from 'shared/types/vessel'
import { changeChartIsRendering, RootState, useGetMassFlowRateInRangeInTimeQuery } from 'core/store'
import NoData from 'shared/components/data-visualization/indicators/NoData'
import Loader from 'shared/components/data-visualization/indicators/Loader'
import { useEffect, useState } from 'react'
import lineChartWorker from 'shared/utils/core/workers/charts/lineChartWorker'
import ChartWrapper from 'shared/components/data-visualization/charts/wrappers/ChartWrapper'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useReduxHooks'
import LineChart from 'shared/components/data-visualization/charts/line-chart/LineChart'
interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
}

function FuelConsumption({ vessel, startTime, endTime, offset }: Props) {
  const dispatch = useAppDispatch()
  const [lineData, setLineData] = useState<any>(null)
  const dataAvailable = true
  const chartIsRendering = useAppSelector((state: RootState) => state.app.chartIsRendering)

  const { data: mainEngineData, isFetching: mainEngineDataIsFetching } = useGetMassFlowRateInRangeInTimeQuery({
    vesselId: vessel.id,
    flowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
    isSplit: vessel.main_engines[0].flowMeter[0].split,
    startTime: startTime,
    endTime: endTime,
  })

  const auxEngine = vessel.auxiliary_engines?.[0]
  const auxEngineFlowMeter = auxEngine?.flowMeter?.[0]
  const auxEngineFlowMeterId = auxEngineFlowMeter?.sensorId

  const { data: auxEngineData, isFetching: auxEngineDataIsFetching } = useGetMassFlowRateInRangeInTimeQuery(
    {
      vesselId: vessel.id,
      flowMeterId: auxEngineFlowMeterId,
      startTime: startTime,
      endTime: endTime,
      isSplit: auxEngineFlowMeter?.split,
    },
    { skip: !auxEngineFlowMeterId }
  )

  let chartData = null
  const vesselLastUpdated = vessel?.last_updated?._seconds || 0

  if (lineData) {
    const { timestamps, dataset1Values, dataset2Values } = lineData

    const datasets = []
    if (dataset1Values?.length) {
      datasets.push({
        label: 'M/E Fuel Consumption [kg/hr]',
        data: dataset1Values,
        borderColor: '#CC3939',
        yAxisID: 'y',
      })
    }
    if (dataset2Values?.length) {
      datasets.push({
        label: 'A/E Fuel Consumption [kg/hr]',
        data: dataset2Values,
        borderColor: '#118F1E',
        yAxisID: 'y1',
      })
    }

    if (datasets.length && timestamps?.length) {
      chartData = {
        datasets,
        timestamps,
      }
    }
  }

  const loading = chartIsRendering || mainEngineDataIsFetching || auxEngineDataIsFetching || !chartData

  useEffect(() => {
    setLineData(null)
  }, [startTime, endTime])

  useEffect(() => {
    if (mainEngineDataIsFetching || auxEngineDataIsFetching) return
    const worker = new Worker(lineChartWorker)

    // Only include auxiliary engine data if it exists and has a flowMeterId
    const workerData = {
      graph1: mainEngineData,
      graph2: auxEngineFlowMeterId ? auxEngineData : null,
      tzOffset: offset,
    }

    worker.postMessage(workerData)
    worker.onmessage = (e: MessageEvent) => {
      setLineData(e.data)
      dispatch(changeChartIsRendering(false))
    }
    return () => {
      worker.terminate()
    }
  }, [mainEngineData, auxEngineData, auxEngineFlowMeterId])

  // Clear line data when auxiliary engine configuration changes
  useEffect(() => {
    setLineData(null)
  }, [auxEngineFlowMeterId])

  return (
    <ChartWrapper
      title="M/E and A/E Fuel Consumption [kg/hr]"
      tooltipText="Main Engine and Aux engine Fuel Consumption based on selected interval"
      tooltipId="fuel-consumption-tooltip"
      className="h-96 FuelConsumption"
      chartHelpText="( Click on legend items to show or hide specific lines in the chart )"
    >
      {loading ? (
        <Loader />
      ) : !dataAvailable ? (
        <NoData
          type="warning"
          title="No data available for selected period"
          text="Please, try different date range."
        />
      ) : (
        <div className="w-full h-full">
          <LineChart
            datasets={chartData!.datasets}
            timestamps={chartData!.timestamps}
            config={{
              axes: {
                y: {
                  label: 'M/E Fuel Consumption [kg/hr]',
                },
                y1: {
                  label: 'A/E Fuel Consumption [kg/hr]',
                },
              },
            }}
          />
        </div>
      )}
    </ChartWrapper>
  )
}

export default FuelConsumption
