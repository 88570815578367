import { useEffect, useState } from 'react'
import ReusableInputField from '../../../shared/components/forms/inputs/Input'
import { useNavigate } from 'react-router-dom'
import MainLogo from 'assets/images/Periscope_Logo_Powered.svg'
import EyeIcon from '../../../assets/eye_icon.png'
import {
  RootState,
  login,
  logout,
  getUserCustomClaims,
  changeInitiallyRequestedUrl,
  changeAuthError,
  setTermsAccepted,
} from 'core/store'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useReduxHooks'
import { isUserExpired } from 'shared/utils/core/date/isUserExpired'
import Button from 'shared/components/ui/buttons/Button'
import Checkmark from 'shared/components/forms/Checkmark'

function Login() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const { initiallyRequestedPath, user, authError, authIsLoading, authSuccessful } = useAppSelector(
    (state: RootState) => {
      return state.auth
    }
  )

  const { termsAccepted } = useAppSelector((state: RootState) => state.app)

  useEffect(() => {
    const emailField = document.getElementsByName('email')
    emailField[0]?.focus()
  }, [])

  useEffect(() => {
    if (!user) return
    dispatch(getUserCustomClaims({ uid: user.uid }))
  }, [user?.uid])

  useEffect(() => {
    if (!user) return
    if (isUserExpired(user.validUntil)) {
      dispatch(changeAuthError('This account has expired.'))
      dispatch(logout())
      return
    }
  }, [user?.validUntil])

  useEffect(() => {
    if (authSuccessful) {
      if (initiallyRequestedPath) {
        dispatch(changeInitiallyRequestedUrl(''))
        navigate(initiallyRequestedPath)
      } else {
        navigate('/')
      }
    }
  }, [authSuccessful])

  const handleEmailChange = (newEmail: string) => {
    setEmail(newEmail)
  }

  const handlePasswordChange = (newPassword: string) => {
    setPassword(newPassword)
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    dispatch(login({ email, password }))
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleTermsChange = () => {
    dispatch(setTermsAccepted(!termsAccepted))
  }

  return (
    <div className="pt-16 pb-12 bg-primary flex not-phone:h-screen flex-1 flex-col justify-center overflow-y-hidden">
      <div>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm mb-12">
          <img
            className="mx-auto h-18 w-auto"
            src={MainLogo}
            alt="Shipprojects"
          />
        </div>

        <div className="flex justify-center mt-4">
          <div className="bg-white px-8 py-8 border border-grayOutlineColor rounded-xl">
            <div className="flex font-semibold mb-6 text-2xl lg:text-4xl">Log in to your account</div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <ReusableInputField
                  label="Your email"
                  type="email"
                  width="458px"
                  height="48px"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter email"
                  required
                  className="text-lg"
                  name="email"
                />
              </div>

              <div className="mb-2">
                <ReusableInputField
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  width="458px"
                  height="48px"
                  icon={EyeIcon}
                  onIconClick={togglePasswordVisibility}
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Enter password"
                  required
                  className="text-lg"
                />
              </div>

              <div
                className="flex items-center mb-4 cursor-pointer pt-2"
                onClick={handleTermsChange}
              >
                <Checkmark checked={termsAccepted} />
                <span className="ml-2 text-sm text-grayText">
                  I agree to the{' '}
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/periscope-e2ac5.appspot.com/o/Terms%20of%20Service.pdf?alt=media&token=0e90c893-ff4b-4bb2-b41e-d5bc9952fe6c"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-accentColor hover:underline"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/periscope-e2ac5.appspot.com/o/Privacy%20Policy.pdf?alt=media&token=2d0afe88-e868-4692-ae26-7724032fe2e6"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-accentColor hover:underline"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Privacy Policy
                  </a>
                </span>
              </div>

              <p className="text-[#f3230c] text-[12px]">{authError}</p>

              <div className="mt-10">
                <div>
                  <Button
                    secondary
                    large
                    fullWidth
                    semibold
                    loading={authIsLoading}
                    className="ease-in-out duration-100"
                  >
                    Log in
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
