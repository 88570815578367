// predicate function for object (not array)
function isObject(item: any): item is Record<string, any> {
  return item !== null && typeof item === 'object' && !Array.isArray(item)
}

// predicate function for array
function isArray(item: any): item is any[] {
  return Array.isArray(item)
}

// function for safe deep cloning
function safeClone<T>(value: T): T {
  // if value is a function, return it directly
  if (typeof value === 'function') {
    return value
  }
  // primitives and null are returned as is
  if (value === null || typeof value !== 'object') {
    return value
  }
  // if array, clone each element
  if (isArray(value)) {
    return value.map((item) => safeClone(item)) as unknown as T
  }
  // if object, recursively clone its properties
  const cloned: Record<string, any> = {}
  for (const key in value) {
    if (Object.prototype.hasOwnProperty.call(value, key)) {
      cloned[key] = safeClone(value[key])
    }
  }
  return cloned as T
}

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P]
}

export default function deepMerge<T extends object>(target: T, ...sources: Array<DeepPartial<T>>): T {
  // use safeClone to create a copy of target
  let output = safeClone(target) as T

  if (!sources.length) return output

  for (const source of sources) {
    if (source && typeof source === 'object') {
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          const sourceValue = source[key]
          const targetValue = output[key]

          // if value is a function, copy the reference
          if (typeof sourceValue === 'function') {
            output[key as keyof T] = sourceValue as any
          }
          // if value is an array, clone it using safeClone
          else if (isArray(sourceValue)) {
            output[key as keyof T] = safeClone(sourceValue) as any
          }
          // if sourceValue and targetValue are objects, merge them recursively
          else if (isObject(sourceValue) && isObject(targetValue)) {
            output[key as keyof T] = deepMerge(targetValue, sourceValue as any) as any
          }
          // if sourceValue is an object, and targetValue is not, clone it
          else if (isObject(sourceValue)) {
            output[key as keyof T] = safeClone(sourceValue) as any
          }
          // otherwise, just assign the value
          else {
            output[key as keyof T] = sourceValue as any
          }
        }
      }
    }
  }

  return output
}
