import { VesselData } from 'shared/types/vessel'
import {
  changeChartIsRendering,
  RootState,
  useGetPowerInRangeInTimeQuery,
  useGetRPMInRangeInTimeQuery,
} from 'core/store'
import NoData from 'shared/components/data-visualization/indicators/NoData'
import Loader from 'shared/components/data-visualization/indicators/Loader'
import { useEffect, useState } from 'react'
import lineChartWorker from 'shared/utils/core/workers/charts/lineChartWorker'
import ChartWrapper from 'shared/components/data-visualization/charts/wrappers/ChartWrapper'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useReduxHooks'
import LineChart from 'shared/components/data-visualization/charts/line-chart/LineChart'
interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
}

function ShaftSpeedAndPower({ vessel, startTime, endTime, offset }: Props) {
  const dispatch = useAppDispatch()
  const [lineData, setLineData] = useState<any>(null)
  const dataAvailable = true
  const chartIsRendering = useAppSelector((state: RootState) => state.app.chartIsRendering)

  const { data: rpmData, isFetching: rpmDataIsFetching } = useGetRPMInRangeInTimeQuery({
    vesselId: vessel.id,
    shaftMeterId: vessel.main_engines[0].shaftMeter[0].sensorId,
    startTime: startTime,
    endTime: endTime,
  })

  const { data: powerData, isFetching: powerDataIsFetching } = useGetPowerInRangeInTimeQuery({
    vesselId: vessel.id,
    shaftMeterId: vessel.main_engines[0].shaftMeter[0].sensorId,
    startTime: startTime,
    endTime: endTime,
  })

  let chartData = null
  const vesselLastUpdated = vessel?.last_updated?._seconds || 0

  if (lineData) {
    const { timestamps, dataset1Values, dataset2Values } = lineData

    const datasets = []
    if (dataset1Values?.length) {
      datasets.push({
        label: 'M/E RPM',
        data: dataset1Values,
        borderColor: '#CC3939',
        yAxisID: 'y',
      })
    }
    if (dataset2Values?.length) {
      datasets.push({
        label: 'M/E Shaft Power [kW]',
        data: dataset2Values,
        borderColor: '#118F1E',
        yAxisID: 'y1',
      })
    }

    if (datasets.length && timestamps?.length) {
      chartData = {
        datasets,
        timestamps,
      }
    }
  }

  const loading = chartIsRendering || rpmDataIsFetching || powerDataIsFetching || !chartData

  useEffect(() => {
    setLineData(null)
  }, [startTime, endTime])

  useEffect(() => {
    if (rpmDataIsFetching || powerDataIsFetching) return
    const worker = new Worker(lineChartWorker)
    worker.postMessage({ graph1: rpmData, graph2: powerData, tzOffset: offset })
    worker.onmessage = (e: MessageEvent) => {
      setLineData(e.data)
      dispatch(changeChartIsRendering(false))
    }
    return () => {
      worker.terminate()
    }
  }, [rpmData, powerData])

  return (
    <ChartWrapper
      title="M/E RPM and Shaft Power [kW]"
      tooltipText="Main Engine Shaft Speed and Shaft Power based on selected interval"
      tooltipId="rpm-shaft-power-tooltip"
      className="h-96 ShaftSpeedAndPower"
      chartHelpText="( Click on legend items to show or hide specific lines in the chart )"
    >
      {loading ? (
        <Loader />
      ) : !dataAvailable ? (
        <NoData
          type="warning"
          title="No data available for selected period"
          text="Please, try different date range."
        />
      ) : (
        <div className="w-full h-full">
          <LineChart
            datasets={chartData!.datasets}
            timestamps={chartData!.timestamps}
            config={{
              axes: {
                y: {
                  label: 'Main Engine RPM',
                },
                y1: {
                  label: 'Shaft Power [kW]',
                },
              },
            }}
          />
        </div>
      )}
    </ChartWrapper>
  )
}

export default ShaftSpeedAndPower
