import { useGetCaptainListQuery, useGetVesselLastTripsQuery } from 'core/store'
import CustomTable, {
  CustomTableContentRow,
  CustomTableHeaderCol,
  CustomTableSettings,
} from 'shared/components/data-visualization/tables/CustomTable'
import { VesselData, CaptainData } from 'shared/types/vessel'
import { getUTCDateString } from 'shared/utils/core/date/getUTCDateString'
import Percentage from 'shared/components/data-visualization/indicators/Percentage'
import PerformanceSummary from 'shared/components/data-visualization/indicators/PerformanceSummary'
import InfoIcon from '../../../../../assets/info_icon.png'
import { Tooltip } from 'react-tooltip'
import Checkmark from 'shared/components/forms/Checkmark'
import { useState } from 'react'
import GridView from 'shared/components/layout/grid/GridView'
import classNames from 'classnames'
import { anonymizeValue } from 'shared/utils/core/security/anonymizeData'

interface Props {
  vessel: VesselData
  isFetching: boolean
  selectedCaptain: string | null
  onCaptainSelect: (captain: string) => void
  captainList: CaptainData[]
  isCaptainListFetching: boolean
  shouldAnonymize?: boolean
}

function Captains({
  vessel,
  selectedCaptain,
  onCaptainSelect,
  captainList,
  isCaptainListFetching,
  shouldAnonymize = false,
}: Props) {
  const tableHeaderData: CustomTableHeaderCol[] = [
    {
      id: 'check',
      content: (
        <div className="min-w-4">
          <Checkmark disabled />
        </div>
      ),
    },
    {
      id: 'captain',
      content: <p>Captain</p>,
    },
    {
      id: 'contractStart',
      content: <p>Contract Commencement</p>,
    },
    {
      id: 'contractEnd',
      content: <p>Contract Termination</p>,
    },
    {
      id: 'fuelEfficiencyChange',
      content: (
        <>
          <p>Fuel efficiency change [%]</p>
          <img
            src={InfoIcon}
            className="w-4 h-auto"
            data-tooltip-id="fuel-efficiency-change-tooltip"
            data-tooltip-delay-hide={200}
          />
          <Tooltip
            id="fuel-efficiency-change-tooltip"
            border="1px solid #8990A3"
            content="Shows the trend in fuel efficiency during the captain's contract period"
            style={{
              width: '350px',
              borderRadius: '8px',
              backgroundColor: 'white',
              color: '#5D657A',
            }}
          />
        </>
      ),
    },
    {
      id: 'performance',
      content: (
        <>
          <p>Policy Adherence</p>
          <img
            src={InfoIcon}
            className="w-4 h auto"
            data-tooltip-id="performance-tooltip"
            data-tooltip-delay-hide={200}
          ></img>
          <Tooltip
            id="performance-tooltip"
            border="1px solid #8990A3"
            content="Percentage distribution of time spent by the vessel at various power levels according to Company Policy"
            style={{
              width: '350px',
              borderRadius: '8px',
              backgroundColor: 'white',
              color: '#5D657A',
            }}
          />
        </>
      ),
    },
  ]

  const tableRowData: CustomTableContentRow[] = (captainList || []).map((captain: CaptainData) => ({
    id: captain.captain,
    onRowClick: () => handleCaptainSelect(captain.captain),
    haystack: [shouldAnonymize ? anonymizeValue(captain.captain) : captain.captain],
    cols: [
      {
        id: 'check',
        value: '',
        content: <Checkmark checked={isChecked(captain.captain)} />,
      },
      {
        id: 'captain',
        value: captain.captain,
        content: <p>{shouldAnonymize ? anonymizeValue(captain.captain) : captain.captain}</p>,
      },
      {
        id: 'contractStart',
        value: new Date(captain.startDate as any).getTime(),
        content: <p>{getUTCDateString(new Date(captain.startDate || 0))}</p>,
      },
      {
        id: 'contractEnd',
        value: captain.endDate ? new Date(captain.endDate as any).getTime() : 0,
        content: <p>{captain.endDate ? getUTCDateString(new Date(captain.endDate)) : 'Present'}</p>,
      },
      {
        id: 'fuelEfficiencyChange',
        value: captain.fuelEfficiencyTrend,
        content: (
          <div className="flex flex-col w-full">
            <Percentage
              percentage={Number(captain.fuelEfficiencyTrend.toFixed(1))}
              isReversed={true}
            />
          </div>
        ),
      },
      {
        id: 'performance',
        value: captain.performanceDistribution.inRange,
        content: (
          <PerformanceSummary
            isAdvanced={true}
            performanceData={captain.performanceDistribution}
          />
        ),
      },
    ],
  }))

  function handleCaptainSelect(captainId: string) {
    if (selectedCaptain !== captainId) {
      onCaptainSelect(captainId)
    }
  }

  function isChecked(captainId: string) {
    return selectedCaptain === captainId
  }

  const columnClassNames = [
    'w-14',
    'w-20 min-w-44',
    'w-60 min-w-40',
    'w-20 min-w-52',
    'w-60 min-w-52',
    'flex-1 min-w-52',
  ]

  const CaptainGridView = ({
    data,
    isLoading,
    sortState,
  }: {
    data: CustomTableContentRow[]
    isLoading: boolean
    sortState: { columnIndex: number; isAscending: boolean; isDefaultSorting: boolean }
  }) => {
    const renderCaptainCard = (row: CustomTableContentRow) => {
      const captainCol = row.cols.find((col) => col.id === 'captain')
      const performanceCol = row.cols.find((col) => col.id === 'performance')
      const fuelEfficiencyCol = row.cols.find((col) => col.id === 'fuelEfficiencyChange')
      const contractStartCol = row.cols.find((col) => col.id === 'contractStart')
      const contractEndCol = row.cols.find((col) => col.id === 'contractEnd')
      const checkCol = row.cols.find((col) => col.id === 'check')

      return (
        <div
          className="bg-white rounded-xl p-6 border border-grayOutlineColor hover:shadow-lg transition-shadow cursor-pointer"
          onClick={row.onRowClick}
        >
          <div className="flex flex-col justify-between items-start mb- gap-2">
            <div className="flex items-center gap-3">
              {checkCol?.content}
              <p className="font-bold">{captainCol?.content}</p>
            </div>
            <p className="flex w-full text-sm text-grayText whitespace-pre">
              Contract Period: {contractStartCol?.content} - {contractEndCol?.content}
            </p>
          </div>

          <div className="h-[1px] bg-grayOutlineColor my-4 mx-[-1.5rem]"></div>

          <div className="mb-10 mt-6">
            <p className="text-sm text-grayText mb-1">Policy Adherence</p>
            {performanceCol?.content}
          </div>

          <div className="mb-6">
            <p className="text-sm text-grayText mb-1">Fuel efficiency change [%]</p>
            {fuelEfficiencyCol?.content}
          </div>
        </div>
      )
    }

    return (
      <GridView
        data={data}
        isLoading={isLoading}
        settings={{
          gridClassName: 'grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 p-6',
          renderCard: renderCaptainCard,
        }}
      />
    )
  }

  const tableSettings: CustomTableSettings = {
    heading: 'Captains',
    text: `${shouldAnonymize ? anonymizeValue(vessel?.name) : vessel?.name} had ${tableRowData.length} captains.`,
    searchable: true,
    sortable: true,
    horizontallyScrollable: true,
    columnExtraClassNames: columnClassNames,
    showViewModeSelector: true,
    gridViewComponent: CaptainGridView,
  }

  return (
    <CustomTable
      settings={tableSettings}
      headerData={tableHeaderData}
      rowData={tableRowData}
      isLoading={isCaptainListFetching}
      placeholder="Search captain"
    />
  )
}

export default Captains
