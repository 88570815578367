import { useEffect, useState } from 'react'
import ReusableInputField from '../../../shared/components/forms/inputs/Input'
import { useNavigate } from 'react-router-dom'
import { useCreateUserMutation } from 'core/store'
import { useGetAllCompaniesQuery } from 'core/store/apis/companyApi'
import Button from 'shared/components/ui/buttons/Button'
import Loader from 'shared/components/data-visualization/indicators/Loader'
import Dropdown, { DropdownOption } from 'shared/components/forms/inputs/Dropdown'
import { useAppSelector } from 'shared/hooks/useReduxHooks'
import { RootState } from 'core/store'

interface Props {
  isAdminDashboard?: boolean
}

function CreateNewUser({ isAdminDashboard = false }: Props) {
  const navigate = useNavigate()
  const user = useAppSelector((state: RootState) => state.auth.user)
  const [name, setName] = useState<string>('')
  const [surname, setSurname] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [selectedCompany, setSelectedCompany] = useState<DropdownOption | undefined>(undefined)

  const [createUser, createUserStatus] = useCreateUserMutation()
  const { data: companiesData, isLoading: isLoadingCompanies } = useGetAllCompaniesQuery(undefined, {
    skip: !isAdminDashboard,
  })

  const companies = companiesData && Array.isArray(companiesData[0]) ? companiesData[0] : []
  const companyOptions: DropdownOption[] = companies.map((company) => ({
    value: company.id,
    label: company.name,
  }))

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (name && surname && email && (isAdminDashboard || selectedCompany)) {
      const userData = {
        name,
        surname,
        email,
        company: isAdminDashboard ? selectedCompany?.value || null : null,
      }
      await createUser(userData)
    }
  }

  useEffect(() => {
    if (createUserStatus.isSuccess) {
      navigate('/users')
    }
  }, [createUserStatus.isSuccess])

  useEffect(() => {
    if (createUserStatus.isError) {
      console.error('User creation failed:', createUserStatus.error)
    }
  }, [createUserStatus.isError])

  return (
    <div
      className={`${!isAdminDashboard ? 'pt-16 pb-12 bg-primary not-phone:h-full flex flex-1 flex-col justify-center overflow-x-hidden' : ''}`}
    >
      <div>
        {createUserStatus.isLoading || (user?.superAdmin && isLoadingCompanies) ? (
          <div className="w-full flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className={`${!isAdminDashboard ? 'flex justify-center mt-4' : ''}`}>
            <div className="bg-white px-8 py-8 border border-grayOutlineColor rounded-xl w-[508px]">
              <div className={`flex font-semibold mb-6 ${isAdminDashboard ? 'text-lg' : 'text-2xl lg:text-4xl'}`}>
                {isAdminDashboard ? <h3>Create New User</h3> : 'Create new user'}
              </div>

              <p className="text-sm font-base text-[#373F52] mb-8">
                Verification email will be sent to verify the account and get temporary password.
              </p>

              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <ReusableInputField
                    label="Name"
                    type="text"
                    value={name}
                    onChange={(value) => setName(value)}
                    placeholder="Enter name"
                    height="48px"
                    required
                    className="text-lg"
                  />
                </div>

                <div className="mb-4">
                  <ReusableInputField
                    label="Surname"
                    type="text"
                    value={surname}
                    onChange={(value) => setSurname(value)}
                    placeholder="Enter surname"
                    height="48px"
                    required
                    className="text-lg"
                  />
                </div>

                <div className="mb-4">
                  <ReusableInputField
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(value) => setEmail(value)}
                    placeholder="Enter email"
                    height="48px"
                    required
                    className="text-lg"
                  />
                </div>

                {isAdminDashboard && (
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Company</label>
                    <Dropdown
                      options={companyOptions}
                      onSelect={(option) => setSelectedCompany(option)}
                      value={selectedCompany}
                      width="w-full"
                      dropdownTitle={selectedCompany?.label || 'Select a company'}
                      hideSplitLine={true}
                    />
                  </div>
                )}

                <div className="text-sm text-left">
                  {createUserStatus.error?.constructor === String && (
                    <p className="text-[#E1341E] mb-4 ml-6">{createUserStatus.error}</p>
                  )}
                </div>

                <div className="flex flex-col gap-4 mt-8">
                  <Button
                    secondary
                    semibold
                    large
                    fullWidth
                  >
                    {createUserStatus.isLoading ? 'Creating...' : 'Create new user'}
                  </Button>
                  <Button
                    type="button"
                    onClick={() => navigate(-1)}
                    primary
                    semibold
                    large
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CreateNewUser
