import { VesselData } from 'shared/types/vessel'

export default function getEngineLabel(vessel: VesselData | null, flowMeterId: string) {
  if (!vessel) return
  return vessel.main_engines.find((engine: any) =>
    engine.flowMeter.find((sensor: any) => sensor.sensorId === parseInt(flowMeterId))
  )
    ? 'M/E'
    : 'A/E'
}
