import * as d3 from 'd3'
import { chartColorCodes } from '../../../../utils/business/chart/chartColorCodes'
import { getBeaufortTextColor } from 'shared/utils/business/chart/beaufortColors'

interface LegendItem {
  label: string
  color: string
  isDashed?: boolean
  isHidden?: boolean
  textInside?: boolean
}

interface ChartLegendProps {
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>
  items: LegendItem[]
  width: number
  height: number
  margin: { top: number; right: number; bottom: number; left: number }
  onLegendClick?: (index: number) => void
  bottomPadding?: number
  clickable?: boolean
  isOutside?: boolean
  activeIndices?: boolean[]
  legendGap?: number
}

export function createChartLegend({
  svg,
  items,
  width,
  height,
  margin,
  onLegendClick,
  bottomPadding = 64,
  clickable = true,
  isOutside = false,
  activeIndices,
  legendGap = 20,
}: ChartLegendProps) {
  const legendGroup = svg.append('g').attr('class', 'legend-group')

  // Create temporary text element to measure text widths
  const tempText = legendGroup
    .append('text')
    .attr('class', 'font-lato')
    .style('font-size', '15px')
    .style('visibility', 'hidden')

  // Calculate widths for each legend item
  const itemWidths = items.map((item) => {
    tempText.text(item.label)
    const textWidth = tempText.node()?.getBBox().width || 0
    return textWidth + 40 // 16 for rect + 24 for spacing between rect and text
  })

  tempText.remove()

  const gap = legendGap // Gap between legend items
  const rowGap = 25 // Gap between rows

  if (isOutside) {
    // Outside legend code (for doughnut chart)
    const rows: number[][] = []
    let currentRow: number[] = []
    let currentWidth = 0
    const maxWidth = width

    itemWidths.forEach((itemWidth, index) => {
      if (currentWidth + itemWidth + (currentRow.length > 0 ? gap : 0) <= maxWidth) {
        currentWidth += itemWidth + (currentRow.length > 0 ? gap : 0)
        currentRow.push(index)
      } else {
        rows.push(currentRow)
        currentRow = [index]
        currentWidth = itemWidth
      }
    })
    if (currentRow.length > 0) {
      rows.push(currentRow)
    }

    // Calculate total height needed for legend
    const legendHeight = rows.length * rowGap
    const legendY = height - legendHeight

    // Draw items row by row
    rows.forEach((row, rowIndex) => {
      // Calculate total width of current row
      const rowWidth = row.reduce((sum, index, i) => {
        return sum + itemWidths[index] + (i > 0 ? gap : 0)
      }, 0)

      // Calculate starting X position to center the row
      const startX = (width - rowWidth) / 2

      // Draw items in the current row
      row.forEach((itemIndex, positionInRow) => {
        const itemX = startX + row.slice(0, positionInRow).reduce((sum, idx) => sum + itemWidths[idx] + gap, 0)
        const itemY = legendY + rowIndex * rowGap

        const legendItem = legendGroup
          .append('g')
          .attr('class', `legend-item-${itemIndex}`)
          .attr('transform', `translate(${itemX}, ${itemY})`)
          .style('cursor', clickable ? 'pointer' : 'default')

        // Add colored rectangle
        legendItem
          .append('rect')
          .attr('width', 16)
          .attr('height', 16)
          .attr('rx', 2)
          .attr('ry', 4)
          .attr('fill', items[itemIndex].isDashed ? 'none' : items[itemIndex].color)
          .attr('stroke', items[itemIndex].isDashed ? items[itemIndex].color : 'none')
          .attr('stroke-width', items[itemIndex].isDashed ? 2 : 0)
          .attr('stroke-dasharray', items[itemIndex].isDashed ? '4,4' : 'none')
          .style('opacity', items[itemIndex].isHidden ? 0.5 : 1)

        if (items[itemIndex].textInside) {
          // Add text
          legendItem
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'middle')
            .attr('x', 16 / 2)
            .attr('y', 16 / 2 + 1)
            .attr('font-size', '12px')
            .attr('font-weight', '800')
            .attr('fill', getBeaufortTextColor(4))
            .text('4')
        }

        // Add label
        legendItem
          .append('text')
          .attr('class', 'font-lato text-md font-medium')
          .style('fill', `rgb(93, 101, 122)`)
          .style('user-select', 'none')
          .style('-webkit-user-select', 'none')
          .style('-moz-user-select', 'none')
          .style('-ms-user-select', 'none')
          .attr('x', 24)
          .attr('y', 12)
          .text(items[itemIndex].label)
          .style('text-decoration', items[itemIndex].isHidden ? 'line-through' : 'none')

        if (clickable) {
          legendItem.on('click', () => {
            const isDisabled = legendItem.classed('disabled')
            legendItem.classed('disabled', !isDisabled)

            // Toggle text decoration
            const text = legendItem.selectAll('text')
            if (!isDisabled) {
              text.style('text-decoration', 'line-through')
            } else {
              text.style('text-decoration', 'none')
            }

            onLegendClick?.(itemIndex)
          })

          // Set initial state based on activeIndices
          if (activeIndices && !activeIndices[itemIndex]) {
            legendItem.classed('disabled', true)
            legendItem.selectAll('text').style('text-decoration', 'line-through')
          }
        }
      })
    })
  } else {
    // Inside legend code (for line charts)
    const legendY = height - margin.bottom + bottomPadding

    // Calculate total width needed for all items
    const totalWidth = items.reduce((sum, _, index) => {
      return sum + itemWidths[index] + (index > 0 ? gap : 0)
    }, 0)

    // Calculate starting X position to center all items
    const startX = (width - totalWidth) / 2

    items.forEach((item, index) => {
      // Calculate x position for each item
      const itemX = startX + itemWidths.slice(0, index).reduce((sum, w) => sum + w + gap, 0)

      const legendItem = legendGroup
        .append('g')
        .attr('class', `legend-item-${index}`)
        .attr('transform', `translate(${itemX}, ${legendY})`)
        .style('cursor', clickable ? 'pointer' : 'default')

      // Add colored rectangle
      legendItem
        .append('rect')
        .attr('width', 16)
        .attr('height', 16)
        .attr('rx', 2)
        .attr('ry', 4)
        .attr('fill', item.isDashed ? 'none' : item.color)
        .attr('stroke', item.isDashed ? item.color : 'none')
        .attr('stroke-width', item.isDashed ? 2 : 0)
        .attr('stroke-dasharray', item.isDashed ? '4,4' : 'none')
        .style('opacity', item.isHidden ? 0.5 : 1)

      if (item.textInside) {
        // Add text
        legendItem
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'middle')
          .attr('x', 16 / 2)
          .attr('y', 16 / 2 + 1)
          .attr('font-size', '12px')
          .attr('font-weight', '800')
          .attr('fill', getBeaufortTextColor(4))
          .text('4')
      }

      // Add label
      legendItem
        .append('text')
        .attr('class', 'font-lato text-md font-medium')
        .style('fill', `rgb(93, 101, 122)`)
        .style('user-select', 'none')
        .style('-webkit-user-select', 'none')
        .style('-moz-user-select', 'none')
        .style('-ms-user-select', 'none')
        .attr('x', 24)
        .attr('y', 14)
        .text(item.label)
        .style('text-decoration', item.isHidden ? 'line-through' : 'none')

      if (clickable) {
        legendItem.on('click', () => {
          const isDisabled = legendItem.classed('disabled')
          legendItem.classed('disabled', !isDisabled)

          // Toggle text decoration
          const text = legendItem.selectAll('text')
          if (!isDisabled) {
            text.style('text-decoration', 'line-through')
          } else {
            text.style('text-decoration', 'none')
          }

          onLegendClick?.(index)
        })

        // Set initial state based on activeIndices
        if (activeIndices && !activeIndices[index]) {
          legendItem.classed('disabled', true)
          legendItem.selectAll('text').style('text-decoration', 'line-through')
        }
      }
    })
  }

  return legendGroup
}
