import React, { useEffect, useState, useMemo, useRef } from 'react'
import DataStreamsMap from './DataStreamsMap'
import StreamsTable from './StreamsTable'
import L from 'leaflet'
import { useGetVesselDataForDashboardQuery } from 'core/store/apis/totalsGraphApi'
import NoData from 'shared/components/data-visualization/indicators/NoData'
import Loader from 'shared/components/data-visualization/indicators/Loader'
interface DataStreamsProps {
  refreshKey?: number
}

const DataStreams: React.FC<DataStreamsProps> = ({ refreshKey = 0 }) => {
  const mapRef = useRef<L.Map | null>(null)

  const {
    data: vesselData,
    isFetching: isVesselDataFetching,
    error: vesselDataError,
    refetch: refetchVesselData,
  } = useGetVesselDataForDashboardQuery(undefined, {
    pollingInterval: 300000, // 5 minutes in milliseconds
  })
  useEffect(() => {
    refetchVesselData()
  }, [refreshKey, refetchVesselData])

  const handleCardClick = (vesselId: string) => {
    const vessel = vesselData?.find((v) => v.vesselId === vesselId)

    if (vessel && mapRef.current) {
      mapRef.current.setView([vessel.latitude, vessel.longitude], 13)
      const mapElement = document.getElementById('streams-map')
      if (mapElement) {
        mapElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }

  return (
    <div className="p-4 flex items:start 2xl:items-center">
      {vesselDataError ? (
        <NoData
          type="warning"
          title="Error fetching vessel data"
        />
      ) : isVesselDataFetching ? (
        <div className="flex-1 flex items-center justify-center h-[100vh]">
          <Loader />
        </div>
      ) : (
        <>
          <DataStreamsMap
            vesselData={vesselData || []}
            isVesselDataFetching={isVesselDataFetching}
            mapRef={mapRef}
          />
          <StreamsTable
            vesselData={vesselData}
            isLoading={isVesselDataFetching}
            onCardClick={handleCardClick}
          />
        </>
      )}
    </div>
  )
}

export default DataStreams
