import { VesselData } from 'shared/types/vessel'
import { useGetMassFlowRateInKnotsQuery } from 'core/store'
import NoData from 'shared/components/data-visualization/indicators/NoData'
import Loader from 'shared/components/data-visualization/indicators/Loader'
import { useEffect, useState, useMemo } from 'react'
import ChartWrapper from 'shared/components/data-visualization/charts/wrappers/ChartWrapper'
import NumericLineChart from 'shared/components/data-visualization/charts/numeric-line-chart/NumericLineChart'
import scatterChartWorker from 'shared/utils/core/workers/charts/scatterChartWorker'

interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
}

function FuelConsumptionVsSpeedTrend({ vessel, startTime, endTime }: Props) {
  const [scatterData, setScatterData] = useState<any>(null)
  const [error, setError] = useState<string | null>(null)

  const { data, isFetching } = useGetMassFlowRateInKnotsQuery({
    vesselId: vessel.id,
    flowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
    startTime: startTime,
    endTime: endTime,
    isSplit: vessel.main_engines[0].flowMeter[0].split,
  })

  // Transform data for D3 NumericLineChart
  const chartData = useMemo(() => {
    if (!scatterData?.graph1TrendlineData) {
      return null
    }

    const trendlineData = scatterData.graph1TrendlineData

    const transformedData = trendlineData.map((point: any) => ({
      nameX: 'Speed [knots]', // Speed on X axis
      nameY: 'M/E Consumption [kg/h]', //  Consumption on Y axis
      valueX: point.x, // Speed on X axis
      valueY: point.y, // Consumption on Y axis
    }))

    return transformedData
  }, [scatterData])

  // Prepare data for NumericLineChart
  const lineChartData = useMemo(() => {
    if (!chartData) return []

    return [
      {
        label: 'M/E Consumption [kg/h] vs Speed [knots]',
        data: chartData,
        color: '#118F1E',
        strokeWidth: 3,
      },
    ]
  }, [chartData])

  useEffect(() => {
    setScatterData(null)
    setError(null)
  }, [startTime, endTime])

  useEffect(() => {
    if (isFetching || !data) return

    const worker = new Worker(scatterChartWorker)
    worker.postMessage({ graph1: data, graph2: null })

    worker.onmessage = (e: MessageEvent) => {
      if (!e.data) {
        setError('No data received from worker')
        return
      }
      setScatterData(e.data)
    }

    worker.onerror = (error) => {
      console.error('Worker error:', error)
      setError('Error processing data')
    }

    return () => {
      worker.terminate()
    }
  }, [data, isFetching])

  const dataAvailable = !!chartData && chartData.length > 0

  return (
    <ChartWrapper
      title="M/E Consumption [kg/h] vs Speed [knots]"
      tooltipText="Relationship between ME Consumption and Speed based on selected interval"
      tooltipId="fc-vs-speed-tooltip"
      chartHelpText="( Click on legend items to show or hide specific lines in the chart )"
      className="h-96 FuelConsumptionVsSpeed"
    >
      {isFetching ? (
        <Loader />
      ) : error ? (
        <NoData
          type="warning"
          title="Error processing data"
          text={error}
        />
      ) : !dataAvailable ? (
        <NoData
          type="warning"
          title="No data available for selected period"
          text="Please, try different date range."
        />
      ) : (
        <NumericLineChart
          datasets={lineChartData}
          config={{
            layout: {
              margin: {
                top: 20,
                right: 100,
                bottom: 80,
                left: 80,
              },
              lineWidth: 2,
            },
            interactions: {
              tooltip: {
                enabled: false,
              },
            },
            axes: {
              x: {
                label: 'Speed [knots]',
                bottomLabelMargin: 37,
              },
              y: {
                label: 'M/E Consumption [kg/h]',
                startFromZero: false,
              },
            },
            chartSections: {
              enabled: false,
            },
          }}
        />
      )}
    </ChartWrapper>
  )
}

export default FuelConsumptionVsSpeedTrend
