import { useState } from 'react'
import ContentWrapper from 'shared/components/layout/ContentWrapper'
import PageHeading from 'shared/components/ui/typography/PageHeading'
import ButtonGroup from 'shared/components/ui/buttons/ButtonGroup'
import { useAppSelector } from 'shared/hooks/useReduxHooks'
import { RootState } from 'core/store'
import CreateCompanyForm from '../../features/admin/components/companies/CreateCompanyForm'
import CompanyList from '../../features/admin/components/companies/CompanyList'
import CreateNewUser from '../users/create/CreateNewUser'
import DataStreams from '../../features/admin/components/data-streams/DataStreams'
import Button from 'shared/components/ui/buttons/Button'

function Admin() {
  const [activeTab, setActiveTab] = useState('companies')
  const [refreshKey, setRefreshKey] = useState(0)
  const user = useAppSelector((state: RootState) => state.auth.user)

  const tabButtons = [
    { value: 'companies', label: 'Companies' },
    { value: 'users', label: 'Users' },
    { value: 'vessels', label: 'Vessels' },
    { value: 'data-streams', label: 'Data Streams' },
  ]

  const handleTabChange = (value: string) => {
    setActiveTab(value)
  }

  const handleRefresh = () => {
    setRefreshKey((prev) => prev + 1)
  }

  return (
    <ContentWrapper>
      <div className="flex justify-between mb-6">
        <PageHeading>Admin Dashboard</PageHeading>
      </div>

      <div className="bg-white rounded-xl flex-grow border border-grayOutlineColor overflow-hidden">
        <div className="border-b border-grayOutlineColor p-6 flex justify-between">
          <ButtonGroup
            buttons={tabButtons}
            value={activeTab}
            onChange={handleTabChange}
            selectedColorName="bg-new-blue"
          />
          {activeTab === 'data-streams' && (
            <Button onClick={handleRefresh}>
              <p>Refresh</p>
            </Button>
          )}
        </div>

        <div className="p-6">
          {activeTab === 'companies' && (
            <div>
              <div className="mb-8 flex justify-center">
                <CreateCompanyForm />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-4">Existing Companies</h3>
                <CompanyList />
              </div>
            </div>
          )}

          {activeTab === 'users' && (
            <div>
              <div className="mb-8 flex justify-center">
                <CreateNewUser isAdminDashboard />
              </div>
            </div>
          )}

          {activeTab === 'vessels' && (
            <div>
              {/* Vessels tab content */}
              <p className="text-grayText">Manage vessel data, configurations, and assignments.</p>
            </div>
          )}

          {activeTab === 'data-streams' && (
            <div>
              <DataStreams refreshKey={refreshKey} />
            </div>
          )}
        </div>
      </div>
    </ContentWrapper>
  )
}

export default Admin
