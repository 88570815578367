import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import RootLayout from '../../shared/components/layout/RootLayout'
import HeaderLayout from '../../shared/components/layout/HeaderLayout'
import NavbarLayout from '../../shared/components/layout/NavbarLayout'
import Login from '../../pages/auth/login/Login'
import ChangePassword from '../../pages/auth/change-password/ChangePassword'
import Users from '../../pages/users/list/Users'
import Vessels from '../../pages/vessels/Vessels'
import NotFound from '../../pages/system/not-found/NotFound'
import PrivateRoute from '../provider/PrivateRoute'
import CreateNewUser from '../../pages/users/create/CreateNewUser'
import WrongScreenSize from '../../shared/components/ui/feedback/WrongScreenSize'
import UserDetails from '../../pages/users/details/UsersDetails'
import HelpCentre from '../../pages/help-centre/HelpCentre'
import Settings from '../../pages/settings/Settings'
import { changeInitiallyRequestedUrl, RootState } from 'core/store'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useReduxHooks'
import { AuthUser } from 'shared/types/auth'
import Performance from '../../pages/performance/Performance'
import Overview from '../../pages/performance/overview/Overview'
import Calculations from '../../pages/performance/calculations/Calculations'
import Analytics from '../../pages/analytics/Analytics'
import VesselReport from '../../pages/analytics/vessel-report/VesselReport'
import TripReport from '../../pages/analytics/trip-report/TripReport'
import CaptainReport from '../../pages/analytics/captain-report/CaptainReport'
import hotjar from '@hotjar/browser'
import { isMobile } from 'react-device-detect'
import Admin from '../../pages/admin/Admin'
import SuperAdminRoute from '../provider/SuperAdminRoute'
import { isAdminEmail } from 'shared/utils/core/security/isAdminEmail'

function AppNavigator({ user }: { user: AuthUser | null }) {
  const [narrowScreen, setNarrowScreen] = useState<boolean>(false)
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { termsAccepted } = useAppSelector((state: RootState) => state.app)

  useEffect(() => {
    try {
      if (termsAccepted && !isAdminEmail(user?.email)) {
        hotjar.init(5206059, 6)

        if (user?.uid) {
          hotjar.identify(user.uid, {
            email: user.email || '',
            companyId: user.companyId || '',
            isAdmin: user.isAdmin || false,
          })
        }
      }
    } catch (error) {
      console.debug('Hotjar initialization error:', error)
    }
  }, [user, termsAccepted])

  useEffect(() => {
    const requestedPath = location.pathname
    dispatch(changeInitiallyRequestedUrl(requestedPath))

    if (termsAccepted && !isAdminEmail(user?.email)) {
      hotjar.stateChange(location.pathname)
    }
  }, [location.pathname, termsAccepted, user?.email])

  useEffect(() => {
    const updateScreenStatus = () => {
      if (window.innerWidth < 600) {
        setNarrowScreen(true)
      } else {
        setNarrowScreen(false)
      }
    }

    window.addEventListener('resize', updateScreenStatus)

    return () => window.removeEventListener('resize', updateScreenStatus)
  }, [])

  return (
    <>
      {isMobile || narrowScreen ? (
        <WrongScreenSize />
      ) : (
        <Routes>
          <Route
            path="/"
            element={<RootLayout />}
          >
            <Route
              path="login"
              element={<Login />}
            />
            <Route
              path="/reset-password/"
              element={<ChangePassword />}
            />
          </Route>

          <Route
            path="/"
            element={
              <PrivateRoute>
                <HeaderLayout />
              </PrivateRoute>
            }
          >
            <Route
              path="/"
              element={<NavbarLayout admin={user?.isAdmin || false} />}
            >
              <Route
                index
                path="/"
                element={<Vessels />}
              />
              <Route
                path="/performance/:vesselId"
                element={<Performance />}
              >
                <Route
                  path="overview"
                  element={<Overview />}
                />
                <Route
                  path="calculations"
                  element={<Calculations />}
                />
              </Route>
              <Route
                path="/analytics/:vesselId"
                element={<Analytics />}
              >
                <Route
                  path="vessel-report"
                  element={<VesselReport />}
                />
                <Route
                  path="trip-report"
                  element={<TripReport />}
                />
                <Route
                  path="captain-report"
                  element={<CaptainReport />}
                />
              </Route>
              <Route
                path="/users"
                element={<Users />}
              />
              <Route
                path="/user-details"
                element={<UserDetails />}
              />
              <Route
                path="/users/create-user"
                element={<CreateNewUser />}
              />
              <Route
                path="/help-centre"
                element={<HelpCentre />}
              />
              <Route
                path="/settings"
                element={<Settings />}
              />
              <Route
                path="/admin"
                element={
                  <SuperAdminRoute>
                    <Admin />
                  </SuperAdminRoute>
                }
              />
            </Route>
          </Route>

          <Route
            path="*"
            element={<NotFound />}
          />
        </Routes>
      )}
    </>
  )
}
export default AppNavigator
