import { VesselData } from 'shared/types/vessel'
import { useGetTotalFuelConsumedBarChartInRangeQuery } from 'core/store'
import NoData from 'shared/components/data-visualization/indicators/NoData'
import BreakdownTable from 'shared/components/data-visualization/tables/BreakdownTable'
import Loader from 'shared/components/data-visualization/indicators/Loader'
import { getUTCDateString } from 'shared/utils/core/date/getUTCDateString'
import getEngineLabel from 'shared/utils/business/vessel/getEngineLabel'
import ChartWrapper from 'shared/components/data-visualization/charts/wrappers/ChartWrapper'
import { getTimestampWithOffset } from 'shared/utils/core/date/getTimestampWithOffset'
import BarChart from '../../../../shared/components/data-visualization/charts/bar-chart/BarChart'
import { defaultConfig as barChartDefaultConfig } from '../../../../shared/components/data-visualization/charts/bar-chart/BarChart'
interface Props {
  vessel: VesselData | null
  startTime: number
  endTime: number
  offset: number
}

function TotalFuelConsumed({ vessel, startTime, endTime, offset }: Props) {
  const { data, isFetching } = useGetTotalFuelConsumedBarChartInRangeQuery(
    {
      vesselId: (vessel && vessel.id) || '',
      mainEngineFlowMeters: (vessel && vessel.main_engines[0].flowMeter) || [],
      auxEngineFlowMeters: (vessel && vessel?.auxiliary_engines?.[0]?.flowMeter) || [],
      startTime: startTime,
      endTime: endTime,
    },
    { skip: !vessel }
  )

  const chartData =
    data?.map((dataset: any) => ({
      label: getEngineLabel(vessel, dataset.label) || dataset.label,
      data: dataset.data,
      backgroundColor: getEngineLabel(vessel, dataset.label) === 'M/E' ? '#223FA7' : '#2EA5DB',
    })) || []

  const breakdownTableHeader = {
    label: {
      classname: 'bg-grayText',
      text: 'Date',
    },
    data:
      data?.[0]?.data.map((item: any) => {
        const timestamp = parseInt(item.category)
        return getUTCDateString(new Date(getTimestampWithOffset(timestamp, offset)))
      }) || [],
  }

  const breakdownTableRows = [
    ...(data?.map((dataset: any) => ({
      label: {
        classname: getEngineLabel(vessel, dataset.label) === 'M/E' ? 'bg-[#223FA7]' : 'bg-[#2EA5DB]',
        text: getEngineLabel(vessel, dataset.label) || '',
      },
      data: dataset.data.map((item: any) => item.value),
    })) || []),
  ]

  return (
    <div>
      <ChartWrapper
        title="Total fuel consumed"
        tooltipText="Total fuel consumed based on selected interval"
        tooltipId="total-fuel-consumed-tooltip"
        className="h-96"
      >
        {isFetching ? (
          <Loader />
        ) : !data || data.length === 0 ? (
          <NoData
            type="warning"
            title="No data available for selected period"
            text="Please, try different date range."
          />
        ) : (
          <div className="w-full h-full">
            <BarChart
              datasets={chartData}
              tzOffset={offset}
              config={{
                layout: {
                  margin: { top: 6, right: 80, bottom: 90, left: 70 },
                },
                axes: {
                  x: {
                    diagonalText: true,
                  },
                  y: {
                    label: data[0].unitOfMeasurement,
                  },
                },
              }}
            />
          </div>
        )}
      </ChartWrapper>

      <BreakdownTable
        title="Breakdown table"
        header={breakdownTableHeader}
        rows={breakdownTableRows}
      />
    </div>
  )
}

export default TotalFuelConsumed
