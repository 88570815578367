import { VesselData } from 'shared/types/vessel'
import { useGetCO2EmissionsBarChartInRangeQuery } from 'core/store'
import NoData from 'shared/components/data-visualization/indicators/NoData'
import BreakdownTable from 'shared/components/data-visualization/tables/BreakdownTable'
import Loader from 'shared/components/data-visualization/indicators/Loader'
import { getUTCDateString } from 'shared/utils/core/date/getUTCDateString'
import ChartWrapper from 'shared/components/data-visualization/charts/wrappers/ChartWrapper'
import { getTimestampWithOffset } from 'shared/utils/core/date/getTimestampWithOffset'
import BarChart from '../../../../shared/components/data-visualization/charts/bar-chart/BarChart'
import { defaultConfig as barChartDefaultConfig } from '../../../../shared/components/data-visualization/charts/bar-chart/BarChart'
interface Props {
  vessel: VesselData | null
  startTime: number
  endTime: number
  offset: number
}

function TotalCO2Emitted({ vessel, startTime, endTime, offset }: Props) {
  const { data, isFetching } = useGetCO2EmissionsBarChartInRangeQuery(
    {
      vesselId: (vessel && vessel.id) || '',
      mainEngineFlowMeters: (vessel && vessel.main_engines[0].flowMeter) || [],
      auxEngineFlowMeters: (vessel && vessel?.auxiliary_engines?.[0]?.flowMeter) || [],
      startTime: startTime,
      endTime: endTime,
    },
    { skip: !vessel }
  )

  const chartData = data
    ? [
        {
          label: 'Total',
          data: data.data,
          backgroundColor: '#F5A445',
        },
      ]
    : []

  const breakdownTableHeader = {
    label: {
      classname: 'bg-grayText',
      text: 'Date',
    },
    data:
      data?.data.map((item: any) => {
        const timestamp = parseInt(item.category)
        return getUTCDateString(new Date(getTimestampWithOffset(timestamp, offset)))
      }) || [],
  }

  const breakdownTableRows = [
    {
      label: {
        classname: 'bg-sunburst-orange',
        text: 'Total',
      },
      data: data?.data.map((item: any) => item.value) || [],
    },
  ]

  return (
    <div>
      <ChartWrapper
        title="Total CO2 emitted"
        tooltipText="Total CO2 emitted based on selected interval"
        tooltipId="total-co2-emitted-tooltip"
        className="h-96"
      >
        {isFetching ? (
          <Loader />
        ) : !data || data.data.length === 0 ? (
          <NoData
            type="warning"
            title="No data available for selected period"
            text="Please, try different date range."
          />
        ) : (
          <div className="w-full h-full">
            <BarChart
              datasets={chartData}
              tzOffset={offset}
              config={{
                layout: {
                  margin: { top: 6, right: 80, bottom: 90, left: 70 },
                },
                axes: {
                  x: {
                    diagonalText: true,
                  },
                  y: {
                    label: data.unitOfMeasurement,
                  },
                },
              }}
            />
          </div>
        )}
      </ChartWrapper>

      <BreakdownTable
        title="Breakdown table"
        header={breakdownTableHeader}
        rows={breakdownTableRows}
      />
    </div>
  )
}

export default TotalCO2Emitted
