import { VesselData } from 'shared/types/vessel'
import { useGetPerformanceBarChartInRangeQuery } from 'core/store/apis/totalsGraphApi'
import { useEffect, useMemo } from 'react'
import DoughnutChartV2 from '../../../shared/components/data-visualization/charts/doughnut-chart/DoughnutChartV2'
import BarChart from '../../../shared/components/data-visualization/charts/bar-chart/BarChart'
import ChartWrapper from '../../../shared/components/data-visualization/charts/wrappers/ChartWrapper'
import Loader from '../../../shared/components/data-visualization/indicators/Loader'
import NoData from '../../../shared/components/data-visualization/indicators/NoData'
import { chartColorCodes } from 'shared/utils/business/chart/chartColorCodes'
import { defaultConfig as barChartDefaultConfig } from '../../../shared/components/data-visualization/charts/bar-chart/BarChart'
interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
  tooltipText?: string
  tooltipId?: string
}

function Performance({ vessel, startTime, endTime, offset, tooltipText, tooltipId }: Props) {
  const {
    data: apiData,
    isFetching,
    error,
  } = useGetPerformanceBarChartInRangeQuery(
    {
      vesselId: vessel.id,
      mainEngineFlowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
      isSplit: vessel.main_engines[0].flowMeter[0].split,
      startTime: startTime,
      endTime: endTime,
      massFlowRateUpperThreshold: vessel.main_engines[0].massFlowRateUpperThreshold,
      massFlowRateLowerThreshold: vessel.main_engines[0].massFlowRateLowerThreshold,
    },
    { skip: startTime === 0 || endTime === 0 }
  )

  const hasNonZeroValues = useMemo(() => {
    if (!apiData?.data) return false
    return apiData.data.some(
      (item) =>
        item.value.belowLimit > 0 || item.value.inRange > 0 || item.value.aboveLimit > 0 || item.value.maneuvering > 0
    )
  }, [apiData])

  const dataAvailable = !!apiData?.data && apiData?.data.length > 0 && hasNonZeroValues

  const barChartData = useMemo(() => {
    if (!apiData) return []

    return [
      {
        label: 'Below Limit',
        data: apiData.data.map((item) => ({
          category: item.category,
          value: item.value.belowLimit,
          beaufortNumber: item.value.beaufortNumber,
        })),
        backgroundColor: `rgb(${chartColorCodes.orange})`,
      },
      {
        label: 'In Range',
        data: apiData.data.map((item) => ({
          category: item.category,
          value: item.value.inRange,
          beaufortNumber: item.value.beaufortNumber,
        })),
        backgroundColor: `rgb(${chartColorCodes.green})`,
      },
      {
        label: 'Above Limit',
        data: apiData.data.map((item) => ({
          category: item.category,
          value: item.value.aboveLimit,
          beaufortNumber: item.value.beaufortNumber,
        })),
        backgroundColor: `rgb(${chartColorCodes.red})`,
      },
      {
        label: 'Maneuvering',
        data: apiData.data.map((item) => ({
          category: item.category,
          value: item.value.maneuvering,
          beaufortNumber: item.value.beaufortNumber,
        })),
        backgroundColor: `rgb(${chartColorCodes.darkGray})`,
      },
    ]
  }, [apiData])

  const doughnutChartData = useMemo(() => {
    if (!apiData) return []
    return [
      {
        name: 'Below Limit',
        value: apiData.totalPercentage.belowLimit,
        unit: '%',
        color: `rgb(${chartColorCodes.orange})`,
      },
      {
        name: 'In Range',
        value: apiData.totalPercentage.inRange,
        unit: '%',
        color: `rgb(${chartColorCodes.green})`,
      },
      {
        name: 'Above Limit',
        value: apiData.totalPercentage.aboveLimit,
        unit: '%',
        color: `rgb(${chartColorCodes.red})`,
      },
      {
        name: 'Maneuvering',
        value: apiData.totalPercentage.maneuvering,
        unit: '%',
        color: `rgb(${chartColorCodes.darkGray})`,
      },
    ]
  }, [apiData])

  return (
    <div className="flex gap-4">
      <div className="w-80 xl:w-96 3xl:w-[28rem]">
        <DoughnutChartV2
          data={doughnutChartData}
          title="Summary of Policy Adherence"
          loading={isFetching}
          dataAvailable={dataAvailable}
          tooltipText={tooltipText}
          tooltipId={tooltipId}
          className="h-72"
          noMarginBottom={true}
          config={{
            interactions: {
              legend: {
                showUnits: true,
              },
            },
          }}
        />
      </div>
      <div className="flex-1">
        <ChartWrapper
          title="Percentage distribution of Policy Adherence"
          tooltipText={tooltipText}
          tooltipId={tooltipId}
          className="h-72"
        >
          {isFetching ? (
            <Loader />
          ) : !dataAvailable ? (
            <NoData
              type="warning"
              title="No data available for selected period"
              text="Please, try different date range."
            />
          ) : (
            <BarChart
              datasets={barChartData}
              tzOffset={offset}
              config={{
                layout: {
                  margin: { top: 6, right: 65, bottom: 90, left: 55 },
                },
                axes: {
                  x: {
                    diagonalText: true,
                  },
                  y: {
                    label: 'Percentage distribution',
                    range: {
                      min: 0,
                      max: 120,
                    },
                    hideValues: true,
                  },
                },
                interactions: {
                  legend: {
                    legendGap: 0,
                  },
                },
              }}
            />
          )}
        </ChartWrapper>
      </div>
    </div>
  )
}

export default Performance
