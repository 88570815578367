import { Outlet, useLocation } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import ContentWrapper from 'shared/components/layout/ContentWrapper'
import {
  changeSelectedVessel,
  changeDateRange,
  RootState,
  useGetVesselByIdQuery,
  changeChartIsRendering,
  useLazyExportVesselDataQuery,
  changeNumberOfTripsToShow,
} from 'core/store'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useReduxHooks'
import ButtonGroup, { ButtonGroupItem } from 'shared/components/ui/buttons/ButtonGroup'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg'
import classNames from 'classnames'
import DatePicker from 'shared/components/forms/DatePicker'
import { getCustomDateTimeStamps } from 'shared/utils/core/date/getCustomDateTimeStamps'
import getTimePeriodData from 'shared/utils/core/date/getTimePeriodData'
import VesselInfo from 'features/performance/components/VesselInfo'
import Dropdown from 'shared/components/forms/inputs/Dropdown'

function Analytics() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { vesselId } = useParams()
  const datepickerContainer = useRef<HTMLInputElement>(null)
  const [selectedPeriod, setSelectedPeriod] = useState<string>('sevenDays')
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false)
  const [isCustomRange, setIsCustomRange] = useState<boolean>(false)
  const [currentDownload, setCurrentDownload] = useState<string | null>(null)
  const [exportLoading, setExportLoading] = useState<boolean>(false)
  const { data: vessel, isFetching } = useGetVesselByIdQuery(vesselId || '', { skip: !vesselId })
  const [launchExport, exportResult, lastPromiseInfo] = useLazyExportVesselDataQuery()
  const user = useAppSelector((state: RootState) => {
    return state.auth.user
  })
  const { startTime, endTime } = useAppSelector((state: RootState) => {
    return state.app.dateRange
  })
  const { offset } = useAppSelector((state: RootState) => {
    return state.app.selectedTimezone
  })
  const numberOfTripsToShow = useAppSelector((state: RootState) => {
    return state.newAnalyticsSlice.numberOfTripsToShow
  })

  const keepDatePickerAlive = isCustomRange || showDatePicker

  const tabButtons: ButtonGroupItem[] = [
    { label: 'Vessel report', value: 'vessel-report' },
    { label: 'Trip report', value: 'trip-report' },
    { label: 'Captain report', value: 'captain-report' },
  ]

  const rangeButtons: ButtonGroupItem[] = [
    { label: 'Custom', value: 'custom-suspend', children: <CalendarIcon /> },
    { label: 'Last 7 days', value: 'sevenDays' },
    { label: 'Last month', value: 'month' },
    { label: 'Last 3 months', value: '3months' },
  ]

  const tripRangeButtons: ButtonGroupItem[] = [
    //{ label: 'Last trip', value: '1' },
    //{ label: 'Last 3 trips', value: '3' },
    //{ label: 'Last 10 trips', value: '10' },
  ]

  const exportOptions = [
    {
      label: 'Excel',
      value: 'excel',
    },
    {
      label: 'CSV',
      value: 'csv',
    },
    {
      label: 'JSON',
      value: 'json',
    },
  ]

  const currentPathSegments = location.pathname.split('/')
  const selectedTab = currentPathSegments[currentPathSegments.length - 1]

  useEffect(() => {
    if (vessel) {
      dispatch(changeSelectedVessel(vessel))
    }
  }, [vessel])

  useEffect(() => {
    if (user?.isDemo) {
      const demoTime = getTimePeriodData('demoTime', offset)
      if (!demoTime) return
      dispatch(changeDateRange(demoTime))
    } else {
      const lastWeek = getTimePeriodData('sevenDays', offset)
      if (!lastWeek) return
      dispatch(changeDateRange(lastWeek))
    }
  }, [user])

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!datepickerContainer.current) return
      if (!datepickerContainer.current.contains(event.target as any)) {
        setShowDatePicker(false)
      }
    }

    document.addEventListener('click', handler, true)

    return () => {
      document.removeEventListener('click', handler, true)
    }
  }, [])

  useEffect(() => {
    if (!exportResult.data) return
    if (currentDownload === exportResult.data) return
    setCurrentDownload(exportResult.data)
    const link = document.createElement('a')
    link.href = exportResult.data
    link.setAttribute('download', 'exported-data')
    document.body.appendChild(link)

    link.click()
    link.remove()
    setExportLoading(false)
  }, [exportResult])

  useEffect(() => {
    if (typeof offset !== 'number') return
    if (selectedPeriod === 'custom-suspend') {
      const startTimeDate = new Date(startTime)
      const endTimeDate = new Date(endTime)
      const selectedPeriodData = getCustomDateTimeStamps(startTimeDate, endTimeDate, offset)
      dispatch(changeChartIsRendering(true))
      dispatch(changeDateRange(selectedPeriodData))
    } else {
      updateRange(selectedPeriod, offset)
    }
  }, [offset])

  useEffect(() => {
    if (vessel && vessel.payment_plan !== 'advanced') {
      navigate(`/performance/${vesselId}/overview`, { replace: true })
    }
  }, [vessel, vesselId])

  if (!vessel || vessel.payment_plan !== 'advanced') {
    return null
  }

  function updateSelectedPeriod(value: string) {
    if (typeof offset !== 'number') return
    setSelectedPeriod(value)
    if (value === 'custom-suspend') {
      setShowDatePicker(true)
      return
    } else {
      setIsCustomRange(false)
    }
    updateRange(value, offset)
  }

  function updateTripRange(value: string) {
    const numberOfTrips = parseInt(value)
    dispatch(changeNumberOfTripsToShow(numberOfTrips))
  }

  function updateRange(value: string, offset: number) {
    const selectedPeriodData = getTimePeriodData(value, offset)
    dispatch(changeChartIsRendering(true))
    dispatch(changeDateRange(selectedPeriodData))
  }

  function handleTabChange(value: string) {
    navigate(`/analytics/${vesselId}/${value}`)
  }

  function applyCustomDateRange({ startTime, endTime }: { startTime: Date | null; endTime: Date | null }) {
    setShowDatePicker(false)
    if (startTime && endTime) {
      setIsCustomRange(true)
      const selectedPeriodData = getCustomDateTimeStamps(startTime, endTime, offset)
      dispatch(changeChartIsRendering(true))
      dispatch(changeDateRange(selectedPeriodData))
    }
  }

  function hideDatePicker() {
    setShowDatePicker(false)
  }

  function handleExportSelect(option: any) {
    if (!vesselId || !user?.companyId) return
    setExportLoading(true)
    launchExport({
      companyId: user?.companyId,
      vesselId: vesselId,
      startTime: startTime,
      endTime: endTime,
      format: option.value,
    })
  }

  return (
    <>
      <ContentWrapper>
        <div className="mb-10">
          <VesselInfo
            vessel={vessel || null}
            isLoading={isFetching}
          />
        </div>
        <div className="flex justify-between">
          <ButtonGroup
            buttons={tabButtons}
            value={selectedTab}
            onChange={handleTabChange}
            selectedColorName="bg-new-blue"
          />
          <div className="flex gap-4 relative">
            {selectedTab === 'vessel-report' ? (
              <ButtonGroup
                buttons={rangeButtons}
                value="sevenDays"
                activeButton={isCustomRange ? 'custom-suspend' : 'sevenDays'}
                onChange={updateSelectedPeriod}
                disabled={user?.isDemo || isFetching}
              />
            ) : (
              <ButtonGroup
                buttons={tripRangeButtons}
                value={numberOfTripsToShow.toString()}
                activeButton={numberOfTripsToShow.toString()}
                onChange={updateTripRange}
                disabled={user?.isDemo || isFetching}
              />
            )}
            {keepDatePickerAlive && (
              <div
                ref={datepickerContainer}
                className={classNames('absolute top-12 right-0 z-20', { hidden: !showDatePicker })}
              >
                <DatePicker
                  onApply={applyCustomDateRange}
                  onCancel={hideDatePicker}
                  initialStartDate={new Date(startTime)}
                  initialEndDate={new Date(endTime)}
                />
              </div>
            )}

            <Dropdown
              dropdownTitle="Export data"
              options={exportOptions}
              onSelect={handleExportSelect}
              disabled={isFetching || exportLoading}
              loading={exportLoading}
            />
          </div>
        </div>
        <Outlet />
      </ContentWrapper>
    </>
  )
}

export default Analytics
