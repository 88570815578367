import { useState, useEffect, useMemo } from 'react'
import { useAppSelector } from 'shared/hooks/useReduxHooks'
import { anonymizeValue, shouldAnonymizeData } from 'shared/utils/core/security/anonymizeData'
import getAdjustedDateString from 'shared/utils/core/date/getAdjustedDateString'
import getAdjustedTimeString from 'shared/utils/core/date/getAdjustedTimeString'
import GridView from 'shared/components/layout/grid/GridView'
import formatTimeAgo from 'shared/utils/core/date/formatTimeAge'
import Loader from 'shared/components/data-visualization/indicators/Loader'
import { CustomTableSettings } from 'shared/components/data-visualization/tables/CustomTable'
import CustomTable from 'shared/components/data-visualization/tables/CustomTable'
import getThresholdStatus from 'shared/utils/core/date/getThresholdStatus'
import { VesselDataForDashboardResponse } from 'shared/types/graphApi'
import getAISStatus from '../../utils/getAISStatus'

type StreamsTableProps = {
  vesselData: VesselDataForDashboardResponse[] | undefined
  isLoading: boolean
  onCardClick: (vesselId: string) => void
}

export default function StreamsTable({ vesselData, isLoading, onCardClick }: StreamsTableProps) {
  const user = useAppSelector((state) => state.auth.user)
  const shouldAnonymize = shouldAnonymizeData(user?.email)
  const [timeUpdate, setTimeUpdate] = useState(0)
  const { offset } = useAppSelector((state) => state.app.selectedTimezone)

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeUpdate((prevTime) => {
        return prevTime + 1
      })
    }, 60000)
    return () => clearInterval(interval)
  }, [])

  const headerData = [
    {
      id: 'name',
      content: 'Ship Name',
    },
    {
      id: 'last_updated',
      content: 'Last Updated',
    },
    {
      id: 'since_last_update',
      content: 'Since Last Update',
    },
    {
      id: 'threshold_status',
      content: 'Threshold Status',
    },
    {
      id: 'company_name',
      content: 'Company Name',
    },
    {
      id: 'company_id',
      content: 'Company ID',
    },
    {
      id: 'vessel_id',
      content: 'Vessel ID',
    },
    {
      id: 'beaufort_number',
      content: 'Beaufort Number',
    },
    {
      id: 'ais_status',
      content: 'AIS Status',
    },
    {
      id: 'heading',
      content: 'Heading',
    },
    {
      id: 'latitude',
      content: 'Latitude',
    },
    {
      id: 'longitude',
      content: 'Longitude',
    },
    {
      id: 'mass_flow_rate',
      content: 'Mass Flow Rate',
    },
    {
      id: 'power',
      content: 'Power',
    },
    {
      id: 'speed',
      content: 'Speed',
    },
    {
      id: 'latest_data_timestamp',
      content: 'Latest Data Timestamp',
    },
  ]

  const rowData = useMemo(() => {
    return vesselData?.map((vessel) => {
      const timestamp = vessel.latestDataTimestamp
      const lastUpdated =
        timestamp > 0
          ? `${offset ? getAdjustedDateString(timestamp / 1000, offset) : getAdjustedDateString(timestamp / 1000, 0)}, ${offset ? getAdjustedTimeString(timestamp / 1000, offset) : getAdjustedTimeString(timestamp / 1000, 0)}`
          : 'No data available'

      const currentTime = Date.now()
      const minutesSinceUpdate = timestamp > 0 ? Math.floor((currentTime - timestamp) / 60000) : -1.25
      const sinceLastUpdate = timestamp > 0 ? formatTimeAgo(minutesSinceUpdate) : 'No data available'

      const thresholdStatus = getThresholdStatus(minutesSinceUpdate, vessel)

      return {
        id: vessel.vesselId,
        haystack: [
          vessel.vesselName ? vessel.vesselName?.toLowerCase() : '',
          vessel.companyName ? vessel.companyName?.toLowerCase() : '',
          lastUpdated,
          sinceLastUpdate,
          thresholdStatus,
          vessel.companyName,
          vessel.companyId,
          vessel.vesselId,
          vessel.beaufortNumber?.toString(),
          vessel.aisStatus?.toString(),
          vessel.heading?.toString(),
        ],
        cols: [
          {
            id: 'name',
            value:
              `${vessel.vesselName ? vessel.vesselName : 'N/A'} (${vessel.companyName ? vessel.companyName : 'N/A'})` ||
              '',
            content: (
              <div>
                <p className="mb-1 uppercase">
                  {`${vessel.vesselName ? vessel.vesselName : 'N/A'} (${vessel.companyName ? vessel.companyName : 'N/A'})` ||
                    ''}
                </p>
                {/* <p className="text-grayText text-sm">
                  IMO {shouldAnonymize ? anonymizeValue(vessel.vesselId) : vessel.vesselId || ''}
                </p> */}
                <p className="text-grayText text-sm">AIS Status: {getAISStatus(vessel.aisStatus?.toString())}</p>
              </div>
            ),
          },
          {
            id: 'last_updated',
            value: timestamp,
            content: <span>{lastUpdated}</span>,
          },
          {
            id: 'since_last_update',
            value: minutesSinceUpdate,
            content: <span>{sinceLastUpdate}</span>,
          },
          {
            id: 'threshold_status',
            value: thresholdStatus.status,
            content: (
              <span
                className={
                  thresholdStatus.status === 'In range'
                    ? 'text-lush-meadow text-lg font-bold uppercase'
                    : 'text-fiery-red text-lg font-bold uppercase'
                }
              >
                {thresholdStatus.status}
              </span>
            ),
          },
          {
            id: 'threshold_message',
            value: thresholdStatus.message,
            content: <span>{thresholdStatus.message}</span>,
          },
          {
            id: 'beaufort_number',
            value: vessel.beaufortNumber,
            content: <span>{vessel.beaufortNumber}</span>,
          },
          {
            id: 'ais_status',
            value: vessel.aisStatus,
            content: <span>{vessel.aisStatus}</span>,
          },
          {
            id: 'heading',
            value: vessel.heading,
            content: <span>{vessel.heading}</span>,
          },
          {
            id: 'latitude',
            value: vessel.latitude,
            content: <span>{vessel.latitude}</span>,
          },
          {
            id: 'longitude',
            value: vessel.longitude,
            content: <span>{vessel.longitude}</span>,
          },
          {
            id: 'mass_flow_rate',
            value: vessel.massFlowRate,
            content: <span>{vessel.massFlowRate}</span>,
          },
          {
            id: 'power',
            value: vessel.power,
            content: <span>{vessel.power}</span>,
          },
          {
            id: 'speed',
            value: vessel.speed,
            content: <span>{vessel.speed}</span>,
          },
          {
            id: 'vessel_id',
            value: vessel.vesselId,
            content: <span>{vessel.vesselId}</span>,
          },
        ],
      }
    })
  }, [vesselData, shouldAnonymize, offset, timeUpdate])

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader />
      </div>
    )
  }

  const VesselGridView = ({
    data,
    isLoading,
    sortState,
  }: {
    data: any[]
    isLoading: boolean
    sortState: { columnIndex: number; isAscending: boolean; isDefaultSorting: boolean }
  }) => {
    if (!data || data?.length === 0 || isLoading)
      return (
        <div className="flex justify-center items-center h-full">
          <Loader />
        </div>
      )

    const sortedData = [...data].sort((a, b) => {
      if (sortState.isDefaultSorting) {
        const aThresholdStatus = a.cols.find((col: any) => col.id === 'threshold_status')?.value || 'In range'
        const bThresholdStatus = b.cols.find((col: any) => col.id === 'threshold_status')?.value || 'In range'
        if (aThresholdStatus === 'Out of range' && bThresholdStatus === 'In range') return -1
        if (aThresholdStatus === 'In range' && bThresholdStatus === 'Out of range') return 1
        const aLastUpdated = new Date(a.cols.find((col: any) => col.id === 'last_updated')?.value || 0).getTime()
        const bLastUpdated = new Date(b.cols.find((col: any) => col.id === 'last_updated')?.value || 0).getTime()

        return aLastUpdated - bLastUpdated
      }

      const aVal = a.cols[sortState.columnIndex]?.value
      const bVal = b.cols[sortState.columnIndex]?.value

      if (typeof aVal === 'number' && typeof bVal === 'number') {
        return sortState.isAscending ? aVal - bVal : bVal - aVal
      }

      if (aVal < bVal) {
        return sortState.isAscending ? -1 : 1
      }
      if (aVal > bVal) {
        return sortState.isAscending ? 1 : -1
      }
      return 0
    })

    const renderVesselCard = (row: any) => {
      const nameCol = row.cols.find((col: any) => col.id === 'name')
      const lastUpdatedCol = row.cols.find((col: any) => col.id === 'last_updated')
      const sinceLastUpdateCol = row.cols.find((col: any) => col.id === 'since_last_update')
      const thresholdStatusCol = row.cols.find((col: any) => col.id === 'threshold_status')
      const thresholdMessageCol = row.cols.find((col: any) => col.id === 'threshold_message')

      return (
        <div
          id={`${row.id}-card`}
          onClick={() => onCardClick(row.id)}
          className={`rounded-xl py-6 px-4 border border-grayOutlineColor hover:shadow-lg transition-shadow cursor-pointer ${
            thresholdStatusCol?.value === 'Out of range' ? 'bg-[#fdeded]' : 'bg-[#edf7ed]'
          }`}
        >
          <div className="flex justify-between items-start mb-6">
            <div>{nameCol?.content}</div>
            <div>
              <p className="text-grayText text-sm text-right">{lastUpdatedCol?.content}</p>
            </div>
          </div>

          <div className="h-[1px] bg-grayOutlineColor my-4 mx-[-1.5rem]"></div>

          <div className="flex flex-col-reverse gap-2 justify-between items-start">
            <div className="space-y-2 text-left flex flex-col items-start">
              <div className="flex items-start gap-2">
                <p className="text-sm text-grayText mb-1">Since Last Update</p>
                <p>{sinceLastUpdateCol?.content}</p>
              </div>

              <div className="flex items-start gap-2">
                <p className="text-sm text-grayText mb-1">Threshold Status</p>
                <p className="flex items-center gap-2">
                  {thresholdStatusCol?.value === 'Out of range' ? (
                    <img
                      src="https://cdn-icons-png.flaticon.com/128/13989/13989156.png"
                      className="w-5 h-5"
                    />
                  ) : (
                    <img
                      src="https://cdn-icons-png.flaticon.com/128/190/190411.png"
                      className="w-5 h-5"
                    />
                  )}
                  {thresholdStatusCol?.content}
                </p>
              </div>

              {thresholdMessageCol?.value && (
                <div className="flex items-start gap-2">
                  <p className="text-sm text-grayText mb-1">Threshold Message</p>
                  <p>{thresholdMessageCol?.content}</p>
                </div>
              )}
            </div>

            <div className="space-y-2 flex flex-col mt-2 items-start  text-left w-full">
              <div className="flex items-start gap-2 w-full">
                <p className="text-sm text-grayText mb-1">Beaufort Number</p>
                <p>
                  {row.cols.find((col: any) => col.id === 'beaufort_number')?.value !== null
                    ? `${row.cols.find((col: any) => col.id === 'beaufort_number')?.value} Bft`
                    : 'N/A'}
                </p>
              </div>
              <div className="flex items-start gap-2 w-full">
                <p className="text-sm text-grayText mb-1">Mass Flow Rate</p>
                <p>
                  {row.cols.find((col: any) => col.id === 'mass_flow_rate')?.value !== null
                    ? `${row.cols.find((col: any) => col.id === 'mass_flow_rate')?.value} kg/h`
                    : 'N/A'}
                </p>
              </div>
              <div className="flex items-start gap-2 w-full">
                <p className="text-sm text-grayText mb-1">Power</p>
                <p>
                  {row.cols.find((col: any) => col.id === 'power')?.value !== null
                    ? `${row.cols.find((col: any) => col.id === 'power')?.value} kW`
                    : 'N/A'}
                </p>
              </div>
              <div className="flex items-start gap-2 w-full">
                <p className="text-sm text-grayText mb-1">Speed</p>
                <p>
                  {row.cols.find((col: any) => col.id === 'speed')?.value !== null
                    ? `${row.cols.find((col: any) => col.id === 'speed')?.value} knots`
                    : 'N/A'}
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return data && data?.length > 0 ? (
      <GridView
        data={sortedData}
        isLoading={isLoading}
        settings={{
          gridClassName:
            'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-4 4xl:grid-cols-4 gap-6 p-6 ',
          renderCard: renderVesselCard,
          excludeDefaultClasses: true,
        }}
      />
    ) : (
      <div>No data available</div>
    )
  }

  const settings: CustomTableSettings = {
    heading: 'Data Streams',
    searchable: false,
    sortable: true,
    showViewModeSelector: false,
    defaultViewMode: 'grid' as const,
    sortableColumns: ['name', 'last_updated', 'since_last_update', 'threshold_status'],
    gridViewComponent: VesselGridView,
    defaultSort: {
      columnId: 'threshold_status',
      value: 'In range',
      priority: 'high',
    },
    bypassState: true,
  }

  return (
    <div className="p-4 flex-1">
      <CustomTable
        settings={settings}
        headerData={headerData}
        rowData={rowData}
        isLoading={isLoading}
      />
    </div>
  )
}
