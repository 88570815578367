import TripMap from './TripMap'
import { DoughnutChartData } from 'shared/components/data-visualization/charts/doughnut-chart/DoughnutChartV2'
import DoughnutChartV2 from 'shared/components/data-visualization/charts/doughnut-chart/DoughnutChartV2'
import { useGetMissedSavingsBarChartQuery, useGetPerformanceBarChartInRangeQuery } from 'core/store/apis/totalsGraphApi'
import { VesselData } from 'shared/types/vessel'
import { useMemo } from 'react'
import { chartColorCodes } from 'shared/utils/business/chart/chartColorCodes'

interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
}

function TripMapSection({ vessel, startTime, endTime, offset }: Props) {
  const {
    data: performanceData,
    isFetching: isPerformanceFetching,
    error: performanceError,
  } = useGetPerformanceBarChartInRangeQuery(
    {
      vesselId: vessel.id,
      mainEngineFlowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
      isSplit: vessel.main_engines[0].flowMeter[0].split,
      startTime: startTime,
      endTime: endTime,
      massFlowRateUpperThreshold: vessel.main_engines[0].massFlowRateUpperThreshold,
      massFlowRateLowerThreshold: vessel.main_engines[0].massFlowRateLowerThreshold,
    },
    { skip: startTime === 0 || endTime === 0 }
  )

  const {
    data: missedSavingsData,
    isFetching: isMissedSavingsFetching,
    error: missedSavingsError,
  } = useGetMissedSavingsBarChartQuery(
    {
      vesselId: vessel.id,
      mainEngineFlowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
      isSplit: false,
      startTime: startTime,
      endTime: endTime,
      massFlowRateUpperThreshold: vessel.main_engines[0].massFlowRateUpperThreshold,
      massFlowRateLowerThreshold: vessel.main_engines[0].massFlowRateLowerThreshold,
    },
    { skip: startTime === 0 || endTime === 0 }
  )

  const doughnutChartData: DoughnutChartData[] = useMemo(
    () => [
      {
        name: 'Below Limit',
        value: performanceData?.totalPercentage.belowLimit || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.orange})`,
      },
      {
        name: 'In Range',
        value: performanceData?.totalPercentage.inRange || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.green})`,
      },
      {
        name: 'Above Limit',
        value: performanceData?.totalPercentage.aboveLimit || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.red})`,
      },
      {
        name: 'Maneuvering',
        value: performanceData?.totalPercentage.maneuvering || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.darkGray})`,
      },
    ],
    [performanceData]
  )

  const missedSavingsChartData: DoughnutChartData[] = useMemo(() => {
    if (!missedSavingsData?.data?.length) return []

    const totalPerfectConsumption = Number(
      missedSavingsData.data.reduce((acc, curr) => acc + curr.value.perfectConsumption, 0).toFixed(1)
    )
    const totalMissedSavings = Number(
      missedSavingsData.data.reduce((acc, curr) => acc + curr.value.missedSavings, 0).toFixed(1)
    )

    return [
      {
        name: 'Perfect Consumption',
        value: totalPerfectConsumption,
        unit: 't',
        color: `rgb(${chartColorCodes.blue})`,
      },
      {
        name: 'Missed Savings',
        value: totalMissedSavings,
        unit: 't',
        color: `rgb(${chartColorCodes.gray})`,
      },
    ]
  }, [missedSavingsData])
  return (
    <div className="flex flex-row gap-4">
      <TripMap
        vessel={vessel}
        startTime={startTime}
        endTime={endTime}
        offset={offset}
      />
      <div className="flex flex-col">
        <DoughnutChartV2
          data={missedSavingsChartData}
          title="M/E Fuel Consumption and Missed Savings"
          loading={isMissedSavingsFetching}
          dataAvailable={!!missedSavingsData?.data?.length}
          tooltipText="Shows the actual fuel consumption and missed savings for the main engine"
          tooltipId="me-fuel-consumption"
          className="h-[300px]"
          config={{
            center: {
              enabled: true,
              text: {
                primary: `${missedSavingsChartData[1]?.value || 0} t`,
                secondary: 'Missed Savings',
              },
            },
            interactions: {
              legend: {
                showUnits: true,
              },
            },
          }}
        />

        <DoughnutChartV2
          data={doughnutChartData}
          title="Summary of Policy Adherence"
          loading={isPerformanceFetching}
          dataAvailable={!!performanceData?.totalPercentage}
          tooltipText="Percentage distribution of time spent by the vessel at various power levels according to Company Policy"
          tooltipId="performance-tooltip"
          className="h-[300px]"
          noMarginBottom={true}
          config={{
            interactions: {
              legend: {
                showUnits: true,
              },
            },
          }}
        />
      </div>
    </div>
  )
}

export default TripMapSection
