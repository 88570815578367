import { Outlet } from 'react-router-dom'
import Navbar from './Navbar'
import SidePanel from './SidePanel'
import PageWrapper from './PageWrapper'

interface Props {
  admin: boolean
}

function NavbarLayout({ admin }: Props) {
  return (
    <>
      <SidePanel className="h-screen border-r">
        <Navbar admin={admin} />
      </SidePanel>
      <PageWrapper>
        <Outlet />
      </PageWrapper>
    </>
  )
}

export default NavbarLayout
