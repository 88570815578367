import { useState } from 'react'
import { useGetAllCompaniesQuery, useDeleteCompanyMutation } from 'core/store/apis/companyApi'
import { Company } from 'shared/types/company'

const CompanyList = () => {
  const { data, isLoading, error, refetch } = useGetAllCompaniesQuery()
  const [deleteCompany, { isLoading: isDeleting }] = useDeleteCompanyMutation()
  const [deletingId, setDeletingId] = useState<string | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [confirmText, setConfirmText] = useState('')
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)

  const companies = data && Array.isArray(data[0]) ? data[0] : []

  const handleDeleteClick = (company: Company) => {
    setSelectedCompany(company)
    setShowModal(true)
    setConfirmText('')
  }

  const handleDelete = async () => {
    if (!selectedCompany) return

    try {
      setDeletingId(selectedCompany.id)
      await deleteCompany(selectedCompany.id).unwrap()
      await refetch()
      setShowModal(false)
      setSelectedCompany(null)
      setConfirmText('')
    } catch (err) {
      console.error('Failed to delete company:', err)
    } finally {
      setDeletingId(null)
    }
  }

  const closeModal = () => {
    setShowModal(false)
    setSelectedCompany(null)
    setConfirmText('')
  }

  const isDeleteConfirmed = () => {
    const expected = `DELETE ${selectedCompany?.name}`.trim()
    const actual = confirmText.trim()
    return expected === actual
  }

  if (isLoading)
    return (
      <tr>
        <td
          colSpan={3}
          className="py-4 text-center"
        >
          Loading companies...
        </td>
      </tr>
    )
  if (error)
    return (
      <tr>
        <td
          colSpan={3}
          className="py-4 text-center text-red-600"
        >
          Error loading companies: {JSON.stringify(error)}
        </td>
      </tr>
    )
  if (!companies || companies.length === 0)
    return (
      <tr>
        <td
          colSpan={3}
          className="py-4 text-center"
        >
          No companies found.
        </td>
      </tr>
    )

  return (
    <>
      <div className="overflow-hidden border border-grayOutlineColor rounded-lg">
        <table className="min-w-full divide-y divide-grayOutlineColor">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Company Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                ID
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-right text-xs font-medium text-gray-500"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-grayOutlineColor">
            {companies.map((company: Company) => (
              <tr
                key={`company-row-${company.id}`}
                className="hover:bg-gray-50"
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  <div
                    id={`company-name-${company.id}`}
                    className="text-sm font-medium text-gray-900"
                  >
                    {company.name}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div
                    id={`company-id-${company.id}`}
                    className="text-sm text-gray-500"
                  >
                    {company.id}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    id={`delete-company-${company.id}`}
                    onClick={() => handleDeleteClick(company)}
                    disabled={deletingId === company.id}
                    className="inline-flex items-center px-3 py-1.5 bg-[#ff4444] hover:bg-[#cc0000] text-white text-sm font-medium rounded-md"
                  >
                    {deletingId === company.id ? (
                      <>
                        <svg
                          className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Deleting...
                      </>
                    ) : (
                      'Delete'
                    )}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Confirmation Modal */}
      {showModal && selectedCompany && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <h3 className="text-lg font-semibold mb-4">Delete Company</h3>
            <p className="mb-4">
              Are you sure you want to delete <span className="font-semibold">{selectedCompany.name}</span> company?
              This action cannot be undone.
            </p>
            <p className="mb-4 text-sm text-gray-600">
              To confirm, please type "<span className="font-semibold">DELETE {selectedCompany.name}</span>"
            </p>
            <input
              type="text"
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder={`DELETE ${selectedCompany.name}`}
              autoComplete="off"
              autoFocus
            />
            <div className="flex justify-end gap-3">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded font-medium transition-colors duration-200"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                disabled={!isDeleteConfirmed()}
                className="px-4 py-2 bg-[#ff4444] hover:bg-[#cc0000] text-white rounded font-medium disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
              >
                Delete Company
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CompanyList
