export default function getAISStatus(aisStatus: string) {
  switch (aisStatus) {
    case '1':
      return '1 - Anchored'
    case '5':
      return '5 - Moored'
    case '0':
      return '0 - Underway'
    default:
      return 'Unknown'
  }
}
