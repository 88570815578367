import React, { useEffect, useRef, useMemo } from 'react'
import * as d3 from 'd3'
import { createChartLegend } from '../shared/ChartLegend'
import { Tooltip, TooltipValue } from '../shared/Tooltip'
import ChartWrapper from 'shared/components/data-visualization/charts/wrappers/ChartWrapper'
import NoData from 'shared/components/data-visualization/indicators/NoData'
import Loader from 'shared/components/data-visualization/indicators/Loader'
import { useD3 } from '../hooks/useD3'
import deepMerge from 'shared/utils/core/helpers/objects/deepObjectsMerge'

// chart data type
export interface DoughnutChartData {
  name: string
  value: number
  unit: string
  color: string
}

// config
export interface DoughnutChartConfig {
  layout: {
    margin: {
      top: number
      right: number
      bottom: number
      left: number
    }
    innerRadiusRatio: number
    legendGap: number
    rowGap: number
  }
  styles: {
    colors: {
      primary: string[]
      text: {
        primary: string
        secondary: string
      }
    }
    font: {
      size: {
        title: number
        label: number
        legend: number
      }
      family: string
      weight: {
        normal: string
        bold: string
      }
    }
    /**
     * @description Not used in DoughnutChartV2
     */
    arc: {
      strokeWidth: number
      strokeColor: string
    }
  }
  interactions: {
    tooltip: {
      enabled: boolean
      /**
       * @description (NOT WORKING) Format the tooltip value.
       */
      format: (value: number) => string
    }
    legend: {
      enabled: boolean
      clickable: boolean
      showUnits: boolean
      position: 'top' | 'bottom'
      cursor?: 'pointer' | 'default'
      onLegendClick?: (index: number) => void
    }
  }
  center: {
    enabled: boolean
    text: {
      primary: string
      secondary: string
    }
    style: {
      primary: {
        size: number
        weight: string
      }
      secondary: {
        size: number
        weight: string
      }
    }
  }
}

// default config
const defaultConfig: DoughnutChartConfig = {
  layout: {
    margin: { top: 10, right: 20, bottom: 10, left: 20 },
    innerRadiusRatio: 0.7,
    legendGap: 20,
    rowGap: 25,
  },
  styles: {
    colors: {
      primary: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd'],
      text: {
        primary: '#333333',
        secondary: '#666666',
      },
    },
    font: {
      size: {
        title: 24,
        label: 14,
        legend: 15,
      },
      family: 'Lato',
      weight: {
        normal: 'normal',
        bold: 'bold',
      },
    },
    arc: {
      strokeWidth: 0,
      strokeColor: 'none',
    },
  },
  interactions: {
    tooltip: {
      enabled: true,
      format: (value) => value.toString(),
    },
    legend: {
      enabled: true,
      clickable: false,
      showUnits: false,
      position: 'bottom',
      onLegendClick: () => {},
    },
  },
  center: {
    enabled: false,
    text: {
      primary: '',
      secondary: '',
    },
    style: {
      primary: {
        size: 24,
        weight: 'bold',
      },
      secondary: {
        size: 14,
        weight: 'normal',
      },
    },
  },
}

export type DoughnutChartCustomConfig = {
  layout?: {
    margin?: Partial<DoughnutChartConfig['layout']['margin']>
    innerRadiusRatio?: number
    legendGap?: number
    rowGap?: number
  }
  styles?: {
    colors?: {
      primary?: string[]
      text?: Partial<DoughnutChartConfig['styles']['colors']['text']>
    }
    font?: {
      size?: Partial<DoughnutChartConfig['styles']['font']['size']>
      family?: string
      weight?: Partial<DoughnutChartConfig['styles']['font']['weight']>
    }
    arc?: Partial<DoughnutChartConfig['styles']['arc']>
  }
  interactions?: {
    tooltip?: Partial<DoughnutChartConfig['interactions']['tooltip']>
    legend?: Partial<DoughnutChartConfig['interactions']['legend']>
  }
  center?: {
    enabled?: boolean
    text?: Partial<DoughnutChartConfig['center']['text']>
    style?: {
      primary?: Partial<DoughnutChartConfig['center']['style']['primary']>
      secondary?: Partial<DoughnutChartConfig['center']['style']['secondary']>
    }
  }
}

interface Props {
  data: DoughnutChartData[]
  title: string
  loading: boolean
  dataAvailable: boolean
  tooltipText?: string
  tooltipId?: string
  className?: string
  noMarginBottom?: boolean
  config?: DoughnutChartCustomConfig
  onLegendClick?: (index: number) => void
}

const DoughnutChartV2: React.FC<Props> = ({
  data,
  title,
  loading,
  dataAvailable,
  tooltipText,
  tooltipId,
  className,
  noMarginBottom,
  config: customConfig,
  onLegendClick,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<HTMLDivElement | null>(null)
  const tooltipInstanceRef = useRef<Tooltip | null>(null)

  // merge default config with custom config
  const config = useMemo(
    () => deepMerge({ ...defaultConfig }, customConfig || {}) as DoughnutChartConfig,
    [customConfig]
  )

  useEffect(() => {
    return () => {
      if (tooltipInstanceRef.current) {
        tooltipInstanceRef.current.destroy()
      }
    }
  }, [])

  const createChart = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => {
    if (!containerRef.current || !data.length) return

    // Clean up previous chart
    svg.selectAll('*').remove()
    if (tooltipInstanceRef.current) {
      tooltipInstanceRef.current.destroy()
      tooltipInstanceRef.current = null
    }

    // Get container dimensions
    const containerRect = containerRef.current.getBoundingClientRect()
    const width = containerRect.width
    const { margin, legendGap, rowGap } = config.layout

    // Calculate legend dimensions
    const tempSvg = svg
    const tempGroup = tempSvg.append('g')
    const tempText = tempGroup
      .append('text')
      .attr('class', config.styles.font.family)
      .style('font-size', `${config.styles.font.size.legend}px`)
      .style('visibility', 'hidden')

    // Calculate widths for legend items
    const itemWidths = data.map((item) => {
      const label = config.interactions.legend.showUnits ? `${item.name} (${item.value}${item.unit})` : item.name
      tempText.text(label)
      const textWidth = tempText.node()?.getBBox().width || 0
      return textWidth + 40
    })

    tempGroup.remove()

    // Calculate number of rows needed for legend
    let currentWidth = 0
    let rows = 1
    const maxWidth = width

    itemWidths.forEach((itemWidth) => {
      if (currentWidth + itemWidth + (currentWidth > 0 ? legendGap : 0) <= maxWidth) {
        currentWidth += itemWidth + (currentWidth > 0 ? legendGap : 0)
      } else {
        rows++
        currentWidth = itemWidth
      }
    })

    const legendHeight = rows * rowGap + 8

    // Calculate available height for chart
    const height = containerRect.height - legendHeight

    // Calculate radius for the donut
    const radius = Math.min(width - margin.left - margin.right, height - margin.top - margin.bottom) / 2
    const innerRadius = radius * config.layout.innerRadiusRatio

    // Create SVG
    svg
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${width} ${containerRect.height}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')

    // Create chart group
    const chartGroup = svg.append('g').attr('transform', `translate(${width / 2},${(height - margin.bottom) / 2})`)

    // Create pie generator
    const pie = d3
      .pie<DoughnutChartData>()
      .value((d) => d.value)
      .sort(null)

    // Create arc generator
    const arc = d3.arc<d3.PieArcDatum<DoughnutChartData>>().innerRadius(innerRadius).outerRadius(radius)

    // Create arcs
    const arcs = chartGroup
      .selectAll('path')
      .data(pie(data))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d) => d.data.color)
      .attr('stroke', config.styles.arc.strokeColor)
      .attr('stroke-width', config.styles.arc.strokeWidth)
      .style('cursor', 'pointer')

    // Add center text if enabled
    if (config.center.enabled) {
      // Add primary text
      chartGroup
        .append('text')
        .attr('class', config.styles.font.family)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('y', -10)
        .style('font-size', `${config.center.style.primary.size}px`)
        .style('font-weight', config.center.style.primary.weight)
        .style('fill', config.styles.colors.text.primary)
        .text(config.center.text.primary)

      // Add secondary text
      chartGroup
        .append('text')
        .attr('class', config.styles.font.family)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('y', 15)
        .style('font-size', `${config.center.style.secondary.size}px`)
        .style('font-weight', config.center.style.secondary.weight)
        .style('fill', config.styles.colors.text.secondary)
        .text(config.center.text.secondary)
    }

    // Initialize tooltip if enabled
    if (config.interactions.tooltip.enabled) {
      if (!tooltipRef.current) {
        tooltipRef.current = document.createElement('div')
        tooltipRef.current.id = 'doughnut-chart-tooltip'
        document.body.appendChild(tooltipRef.current)
      }

      tooltipInstanceRef.current = new Tooltip({
        container: tooltipRef.current,
        getValues: (d: d3.PieArcDatum<DoughnutChartData>) => {
          const values: TooltipValue[] = [
            {
              label: d.data.name,
              value: d.data.value,
              unit: d.data.unit || '',
              color: d.data.color,
            },
          ]
          return values
        },
      })

      tooltipInstanceRef.current.addTo(arcs)
    }

    // Create legend if enabled
    if (config.interactions.legend.enabled) {
      const legendItems = data.map((item) => ({
        label: config.interactions.legend.showUnits ? `${item.name} (${item.value}${item.unit})` : item.name,
        color: item.color,
      }))

      createChartLegend({
        svg,
        items: legendItems,
        width,
        height: containerRect.height,
        margin,
        bottomPadding: 0,
        clickable: config.interactions.legend.clickable,
        onLegendClick: config.interactions.legend.onLegendClick,
        isOutside: config.interactions.legend.position === 'bottom',
      })
    }
  }

  const svgRef = useD3(createChart, [data, config])

  return (
    <ChartWrapper
      title={title}
      tooltipText={tooltipText}
      tooltipId={tooltipId}
      className={className}
      noMarginBottom={noMarginBottom}
    >
      {loading ? (
        <Loader />
      ) : !dataAvailable ? (
        <NoData
          type="warning"
          title="No data available for selected period"
          text="Please, try different date range."
        />
      ) : (
        <div className="flex flex-col h-full w-full">
          <div
            ref={containerRef}
            className="flex-1 w-full"
          >
            <svg
              ref={svgRef}
              style={{
                width: '100%',
                height: '100%',
                userSelect: 'none',
                WebkitUserSelect: 'none',
              }}
            />
          </div>
        </div>
      )}
    </ChartWrapper>
  )
}

export default DoughnutChartV2
