import { RootState } from 'core/store'
import { useAppSelector } from 'shared/hooks/useReduxHooks'
import SpeedOverGroundVsFuelConsumption from '../../../features/analytics/components/SpeedOverGroundVsFuelConsumption'

import MassFlowRate from '../../../features/analytics/components/reports/vessel/MassFlowRate'
import MissedSavings from '../../../features/analytics/components/MissedSavings'
import Performance from '../../../features/analytics/components/Performance'
import FuelEfficiency from '../../../features/analytics/components/reports/vessel/FuelEfficiency'

function VesselReport() {
  const { startTime, endTime } = useAppSelector((state: RootState) => {
    return state.app.dateRange
  })
  const { offset } = useAppSelector((state: RootState) => {
    return state.app.selectedTimezone
  })
  const selectedVessel = useAppSelector((state: RootState) => {
    return state.app.selectedVessel
  })

  return (
    <div className="flex flex-col gap-4 pb-6">
      <div className="mt-4">
        {selectedVessel && (
          <div className="mb-4">
            <MissedSavings
              vessel={selectedVessel}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-4">
            <Performance
              vessel={selectedVessel}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
              tooltipText="Percentage distribution of time spent by the vessel at various power levels according to Company Policy"
              tooltipId="performance-tooltip"
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <FuelEfficiency
              vessel={selectedVessel}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <MassFlowRate
              vessel={selectedVessel}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
              tooltipText="The chart shows the vessel's mass flow rate over time, highlighting the minimum, maximum, and average values."
              tooltipId="mass-flow-rate-tooltip"
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <SpeedOverGroundVsFuelConsumption
              vessel={selectedVessel}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default VesselReport
