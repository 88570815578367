import * as d3 from 'd3'
import { getUTCDateString } from '../../../../utils/core/date/getUTCDateString'
import { getUTCTimeString } from '../../../../utils/core/date/getUTCTimeString'

export interface TooltipValue {
  label: string
  value: number | string
  unit?: string
  decimals?: number
  color?: string
  xValue?: Date | number
}

export interface TooltipConfig<T> {
  container: HTMLDivElement
  chartGroup?: d3.Selection<SVGGElement, unknown, null, undefined>
  xScale?: d3.ScaleTime<number, number> | d3.ScaleLinear<number, number>
  height?: number
  showVerticalLine?: boolean
  getValues: (d: T) => TooltipValue[]
  showTimeOnXAxis?: boolean
  showTitle?: boolean
}

export class Tooltip<T = any> {
  private tooltip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>
  private focus: d3.Selection<SVGGElement, unknown, null, undefined> | null = null
  private lastValidValues: TooltipValue[] = []
  private lastValidXValue: Date | number | undefined

  constructor(private config: TooltipConfig<T>) {
    this.tooltip = d3
      .select('body')
      .append('div')
      .attr(
        'class',
        `tooltip-${config.container.id} font-lato fixed invisible bg-black/90 text-white px-3 rounded-md py-2 text-sm leading-normal pointer-events-none z-[9999] whitespace-nowrap -translate-x-1/2 -translate-y-full`
      )

    if (config.chartGroup && config.height) {
      this.focus = config.chartGroup.append('g').style('display', 'none')

      this.focus
        .append('line')
        .attr('class', 'hover-line')
        .attr('y1', 0)
        .attr('y2', config.height)
        .style('stroke', 'rgba(0, 0, 0, 0.9)')
        .style('stroke-width', '2px')
        .style('stroke-dasharray', '3,3')
    }
  }

  private formatContent(values: TooltipValue[], xValue?: Date | number): string {
    // If no values, use last valid values
    if (values.length === 0) {
      values = this.lastValidValues
      xValue = this.lastValidXValue
    } else {
      // Store valid values for future use
      this.lastValidValues = values
      this.lastValidXValue = xValue
    }

    let content = ''

    // Add X-axis value if provided and showTitle is true (default to true if not specified)
    if (this.config.showTitle !== false && xValue !== undefined) {
      if (xValue instanceof Date) {
        // Use the exact timestamp from the first value if available
        const exactDate = (values[0]?.xValue as Date) || xValue

        let formattedTime = this.config.showTimeOnXAxis ? getUTCTimeString(exactDate) : ''
        if (formattedTime === '24:00') {
          formattedTime = '00:00'
        }

        const formattedDate = getUTCDateString(exactDate)
        content = `<div class="text-base font-semibold mb-1">${formattedDate}${formattedTime ? ' ' + formattedTime : ''}</div>`
      } else {
        content = `<div class="text-base font-bold mb-1">${typeof xValue === 'number' ? xValue.toFixed(1) : xValue}</div>`
      }
    }

    // Add all other values
    content += values
      .map(({ label, value, unit = '', color }) => {
        const formattedValue = typeof value === 'number' ? value.toFixed(2) : value
        const colorSquare = color
          ? `<span style="display: inline-block; width: 12px; height: 12px; background-color: ${color}; margin-right: 8px; border-radius: 3px;"></span>`
          : ''
        return `<div class="flex items-center">${colorSquare}<span>${label}:</span> <span class="ml-1">${formattedValue}${unit}</span></div>`
      })
      .join('')

    return content
  }

  addTo<E extends SVGElement>(selection: d3.Selection<E, any, any, any>) {
    window.addEventListener(
      'scroll',
      () => {
        this.hideTooltip()
      },
      { passive: true }
    )

    this.config.container.addEventListener('mouseleave', () => {
      this.hideTooltip()
    })

    selection
      .on('mouseover', () => {
        if (this.focus) this.focus.style('display', null)
      })
      .on('mouseout', () => {
        if (this.focus) this.focus.style('display', 'none')
        this.tooltip.style('visibility', 'hidden')
      })
      .on('mousemove', (event, d) => {
        const [x] = d3.pointer(event)

        if (this.focus) {
          this.focus.style('display', null)
          this.focus.select('.hover-line').attr('transform', `translate(${x},0)`)
        }

        let values: TooltipValue[]
        let xValue: Date | number | undefined

        if (this.config.xScale) {
          // Handle time-based data (line charts)
          const date = this.config.xScale.invert(x)
          xValue = date
          values = this.config.getValues(date as unknown as T)
        } else {
          // Handle regular data (hexbin charts)
          values = this.config.getValues(d)
          if (d && Array.isArray(d) && d[0]) {
            xValue = d[0].valueX // For hexbin charts, use the first point's X value
          }
        }

        this.tooltip
          .style('visibility', 'visible')
          .style('left', `${event.clientX}px`)
          .style('top', `${event.clientY - 10}px`)
          .html(this.formatContent(values, xValue))
      })

    return selection
  }

  private hideTooltip() {
    if (this.focus) this.focus.style('display', 'none')
    this.tooltip.style('visibility', 'hidden')
    // Reset stored values when tooltip is hidden
    this.lastValidValues = []
    this.lastValidXValue = undefined
  }

  destroy() {
    this.tooltip.remove()
    if (this.focus) this.focus.remove()
  }
}
