import { RootState, useGetVesselLastTripsQuery } from 'core/store'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useReduxHooks'
import SpeedOverGroundVsFuelConsumption from '../../../features/analytics/components/SpeedOverGroundVsFuelConsumption'
import MassFlowRateDistribution from '../../../features/analytics/components/MassFlowRateDistribution'
import PastTrips from '../../../features/analytics/components/PastTrips'
import Captains from '../../../features/analytics/components/reports/captain/Captains'
import { useEffect, useState, useMemo } from 'react'
import { useGetCaptainListQuery } from 'core/store/apis/vesselsApi'
import { shouldAnonymizeData } from 'shared/utils/core/security/anonymizeData'
import MissedSavings from '../../../features/analytics/components/MissedSavings'
import Performance from '../../../features/analytics/components/Performance'

function CaptainReport() {
  const dispatch = useAppDispatch()
  const [selectedTripId, setSelectedTripId] = useState<string>('')
  const [selectedCaptain, setSelectedCaptain] = useState<string | null>(null)

  const { offset } = useAppSelector((state: RootState) => {
    return state.app.selectedTimezone
  })
  const selectedVessel = useAppSelector((state: RootState) => {
    return state.app.selectedVessel
  })

  const user = useAppSelector((state: RootState) => {
    return state.auth.user
  })

  const shouldAnonymize = shouldAnonymizeData(user?.email)

  const { data: captainList, isFetching: isCaptainListFetching } = useGetCaptainListQuery(
    { vesselId: selectedVessel?.id || '' },
    { skip: !selectedVessel?.id }
  )

  const { data: trips, isFetching } = useGetVesselLastTripsQuery(
    { vesselId: selectedVessel?.id || '', limit: 50 },
    { skip: !selectedVessel?.id || isCaptainListFetching }
  )

  const captainOptions = useMemo(() => {
    if (!trips || !captainList) return []
    const uniqueCaptains = Array.from(new Set(trips.map((trip) => trip.captain).filter(Boolean)))
    return uniqueCaptains.map((captain) => ({
      label: captain,
      value: captain,
    }))
  }, [trips, captainList])

  useEffect(() => {
    if (captainOptions.length > 0 && selectedCaptain === null) {
      setSelectedCaptain(captainOptions[0].value)
    }
  }, [captainOptions, selectedCaptain])

  const filteredTrips = useMemo(() => {
    if (!trips || selectedCaptain === null) return []
    return trips.filter((trip) => trip.captain === selectedCaptain)
  }, [trips, selectedCaptain])

  useEffect(() => {
    if (filteredTrips && filteredTrips.length > 0 && !document.location.hash) {
      setSelectedTripId(filteredTrips[0].id)
    }
  }, [filteredTrips, selectedCaptain])

  const selectedTrip = filteredTrips?.find((trip) => trip.id === selectedTripId)
  const selectedTrips = selectedTrip ? [selectedTrip] : []

  const startTime = selectedTrip?.departureTime || 0
  const endTime = selectedTrip?.status === 0 ? Date.now() : selectedTrip?.arrivalTime || 0

  function changeSelectedTripId(tripId: string) {
    if (selectedTripId === tripId) {
      setSelectedTripId('')
    } else {
      setSelectedTripId(tripId)
    }
  }

  return (
    <div className="flex flex-col gap-4 pb-6">
      <div className="mt-4">
        <div className="flex gap-4">
          {selectedVessel && (
            <div className="mb-8 flex-1">
              <Captains
                captainList={captainList || []}
                isCaptainListFetching={isCaptainListFetching}
                vessel={selectedVessel}
                isFetching={isFetching}
                selectedCaptain={selectedCaptain}
                onCaptainSelect={setSelectedCaptain}
                shouldAnonymize={shouldAnonymize}
              />
            </div>
          )}
        </div>

        {selectedVessel && (
          <div className="mb-8">
            <PastTrips
              vessel={selectedVessel}
              trips={filteredTrips}
              selectedTrips={selectedTrips}
              onTripCheck={changeSelectedTripId}
              isFetching={isCaptainListFetching || isFetching}
              offset={offset || 0}
              shouldAnonymize={shouldAnonymize}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-4">
            <MissedSavings
              vessel={selectedVessel}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
              tooltipText="Percentage distribution of time spent by the vessel at various power levels according to Company Policy"
              tooltipId="performance-tooltip"
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-4">
            <Performance
              vessel={selectedVessel}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
              tooltipText="Percentage distribution of time spent by the vessel at various power levels according to Company Policy"
              tooltipId="performance-tooltip"
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <SpeedOverGroundVsFuelConsumption
              vessel={selectedVessel}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <MassFlowRateDistribution
              vessel={selectedVessel}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
              tooltipText="Mass Flow Rate Distribution Over Time with Percentage Intensity Across 2-Hour Intervals"
              tooltipId="mass-flow-rate-tooltip"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CaptainReport
