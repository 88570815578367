import { useNavigate, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { ReactComponent as BoatIcon } from 'assets/icons/boat.svg'
import { ReactComponent as LighthouseIcon } from 'assets/icons/Lighthouse.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/gearSix.svg'
import { ReactComponent as UsersIcon } from 'assets/icons/usersThree.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/exit.svg'
import { ReactComponent as AdminIcon } from 'assets/icons/adminTools.svg'
import { logout } from 'core/store'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useReduxHooks'
import { RootState } from 'core/store'

interface NavbarProps {
  admin?: boolean
}

function Navbar({ admin }: NavbarProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const currentPath = location.pathname

  const [activeButton, setActiveButton] = useState<string | null>(null)

  const user = useAppSelector((state: RootState) => state.auth.user)

  const handleButtonClick = (route: string) => {
    navigate(route)
  }

  useEffect(() => {
    setActiveButton(currentPath)
  }, [currentPath])

  const activeButtonClassName = 'flex justify-center h-10 w-10 bg-[#F0F1F5] border border-grayOutlineColor rounded-lg'
  return (
    <>
      <div className="w-20 flex">
        <div className="flex flex-col w-full items-center gap-5 py-8">
          <div
            className="flex items-center justify-center h-10 w-10"
            onClick={() => handleButtonClick('/')}
          >
            {activeButton === '/' ? (
              <div className={activeButtonClassName}>
                <BoatIcon className="w-5 h-auto cursor-pointer z-10 fill-cosmic-black" />
              </div>
            ) : (
              <BoatIcon className="w-5 h-auto cursor-pointer z-10 fill-smoky-steel" />
            )}
          </div>

          {/* <div
            className="flex items-center justify-center h-10 w-10"
            onClick={() => handleButtonClick('/vessel-comparison')}
          >
            {activeButton === '/vessel-comparison' ? (
              <div className={activeButtonClassName}>
                <LighthouseIcon className="w-5 h-auto cursor-pointer z-10 fill-cosmic-black" />
              </div>
            ) : (
              <LighthouseIcon className="w-5 h-auto cursor-pointer z-10 fill-smoky-steel" />
            )}
          </div> */}

          {admin && (
            <div
              className="flex items-center justify-center h-10 w-10"
              onClick={() => handleButtonClick('/users')}
            >
              {activeButton?.includes('/users') || activeButton?.includes('/user-details') ? (
                <div className={activeButtonClassName}>
                  <UsersIcon className="w-6 h-auto cursor-pointer fill-cosmic-black" />
                </div>
              ) : (
                <UsersIcon className="w-6 h-auto cursor-pointer fill-smoky-steel" />
              )}
            </div>
          )}

          {user?.superAdmin && (
            <div
              className="flex items-center justify-center h-10 w-10"
              onClick={() => handleButtonClick('/admin')}
            >
              {activeButton === '/admin' ? (
                <div className={activeButtonClassName}>
                  <AdminIcon className="w-5 h-auto cursor-pointer fill-cosmic-black" />
                </div>
              ) : (
                <AdminIcon className="w-5 h-auto cursor-pointer fill-smoky-steel" />
              )}
            </div>
          )}

          <div className="h-0.5 bg-grayOutlineColor w-3/6" />

          {/* <div
            className="flex items-center justify-center h-10 w-10"
            onClick={() => handleButtonClick('/settings')}
          >
            {activeButton === '/settings' ? (
              <div className={activeButtonClassName}>
                <SettingsIcon className="w-5 h-auto cursor-pointer fill-cosmic-black" />
              </div>
            ) : (
              <SettingsIcon className="w-5 h-auto cursor-pointer fill-smoky-steel" />
            )}
          </div> */}

          <div
            className="flex items-center justify-center h-10 w-10 mt-auto"
            onClick={() => {
              dispatch(logout())
            }}
          >
            <LogoutIcon className="w-5 h-auto cursor-pointer fill-smoky-steel rotate-180" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
