import { useState } from 'react'
import ReusableInputField from 'shared/components/forms/inputs/Input'
import Button from 'shared/components/ui/buttons/Button'
import { useCreateCompanyMutation } from 'core/store/apis/companyApi'

interface CreateCompanyFormProps {
  onSuccess?: (companyName: string) => void
}

function CreateCompanyForm({ onSuccess }: CreateCompanyFormProps) {
  const [companyName, setCompanyName] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)

  const [createCompany, { isLoading: isSubmitting }] = useCreateCompanyMutation()

  const handleCompanyNameChange = (value: string) => {
    setCompanyName(value)
    setError(null)
    setSuccess(null)
  }

  const handleCreateCompany = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!companyName.trim()) {
      setError('Company name is required')
      return
    }

    setError(null)
    setSuccess(null)

    try {
      const result = await createCompany({ name: companyName }).unwrap()

      if ('error' in result) {
        setError(result.error)
      } else {
        setSuccess(`Company "${companyName}" created successfully`)
        if (onSuccess) {
          onSuccess(companyName)
        }
        setCompanyName('') // Reset form
      }
    } catch (err: any) {
      setError(err.message || 'Failed to create company')
    }
  }

  return (
    <div className="bg-white rounded-lg border border-grayOutlineColor p-6 max-w-2xl w-[508px]">
      <h3 className="text-lg font-semibold mb-4">Create New Company</h3>

      <form onSubmit={handleCreateCompany}>
        <div className="mb-4">
          <ReusableInputField
            label="Company Name"
            type="text"
            value={companyName}
            onChange={handleCompanyNameChange}
            placeholder="Enter company name"
            required
            className="text-lg"
          />
        </div>

        {error && <div className="mb-4 text-fiery-coral">{error}</div>}

        {success && <div className="mb-4 text-green-600">{success}</div>}

        <Button
          secondary
          semibold
          loading={isSubmitting}
          disabled={isSubmitting || !companyName.trim()}
          type="submit"
        >
          Create Company
        </Button>
      </form>
    </div>
  )
}

export default CreateCompanyForm
