export default function getAdjustedDateString(timestampInSeconds: number, offsetInHours: number) {
  const offsetInMilliseconds = offsetInHours * 3600000
  const adjustedTimestamp = timestampInSeconds * 1000 + offsetInMilliseconds
  const adjustedDate = new Date(adjustedTimestamp)
  const day = String(adjustedDate.getUTCDate()).padStart(2, '0')
  const month = String(adjustedDate.getUTCMonth() + 1).padStart(2, '0')
  const year = String(adjustedDate.getUTCFullYear()).slice(-2)

  return `${day}/${month}/${year}`
}
