import { useEffect, useRef } from 'react'
import * as d3 from 'd3'

export const useD3 = (
  renderChartFn: (selection: d3.Selection<SVGSVGElement, unknown, null, undefined>) => void,
  dependencies: any[]
) => {
  const ref = useRef<SVGSVGElement>(null)

  useEffect(() => {
    const svg = ref.current
    if (!svg) return

    // Initial render
    const selection = d3.select<SVGSVGElement, unknown>(svg)
    renderChartFn(selection)

    // Set up resize observer
    const resizeObserver = new ResizeObserver(() => {
      renderChartFn(selection)
    })

    resizeObserver.observe(svg)

    // Cleanup
    return () => {
      resizeObserver.disconnect()
    }
  }, dependencies)

  return ref
}
