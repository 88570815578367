import * as d3 from 'd3'
import { chartColorCodes } from '../../../../utils/business/chart/chartColorCodes'
import { getUTCDateString } from '../../../../utils/core/date/getUTCDateString'
import { getUTCTimeString } from '../../../../utils/core/date/getUTCTimeString'

interface ChartAxesProps {
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>
  width: number
  height: number
  margin: { top: number; right: number; bottom: number; left: number }
  xScale: d3.ScaleTime<number, number> | d3.ScaleLinear<number, number>
  yScale: d3.ScaleLinear<number, number>
  y1Scale?: d3.ScaleLinear<number, number>
  xAxisLabel?: string
  yAxisLabel?: string
  y1AxisLabel?: string
  xAxisTickValues?: Date[]
  showTimeOnXAxis?: boolean
  diagonalXAxisText?: boolean
  xAxisLabelMargin?: number
  hideYAxisValues?: boolean
  yAxisTickCount?: number
}

export function createChartAxes({
  svg,
  width,
  height,
  margin,
  xScale,
  yScale,
  y1Scale,
  xAxisLabel = '',
  yAxisLabel = '',
  y1AxisLabel = '',
  xAxisTickValues,
  showTimeOnXAxis = true,
  diagonalXAxisText = false,
  xAxisLabelMargin = 5,
  hideYAxisValues = false,
  yAxisTickCount = 7,
}: ChartAxesProps) {
  const chartWidth = width - margin.left - margin.right
  const chartHeight = height - margin.top - margin.bottom

  // Create chart group
  const chartGroup = svg
    .append('g')
    .attr('class', 'chart-group')
    .attr('transform', `translate(${margin.left},${margin.top})`)

  // Calculate common tick values for both axes
  const yDomain = yScale.domain()
  const y1Domain = y1Scale ? y1Scale.domain() : [0, 0]

  // Create evenly spaced ticks
  const tickValues = d3.ticks(yDomain[0], yDomain[1], yAxisTickCount)

  // Add grid lines
  chartGroup
    .append('g')
    .attr('class', 'grid y-grid')
    .style('opacity', 0.1)
    .call(
      d3
        .axisLeft(yScale)
        .tickValues(tickValues)
        .tickSize(-chartWidth)
        .tickFormat(() => '')
    )

  // Generate x-axis ticks first, so we can use them for both grid and axis
  let xAxisTicks: Date[] = xAxisTickValues || []

  if (!xAxisTicks.length && 'invert' in xScale && typeof xScale.invert(0) === 'object') {
    const domain = xScale.domain() as Date[]
    const startDate = domain[0]
    const endDate = domain[1]

    // Calculate optimal number of ticks based on chart width
    const tickCount = Math.max(2, Math.floor(chartWidth / 120)) // At least 2 ticks, roughly one per 120px
    const timeSpan = endDate.getTime() - startDate.getTime()
    const timeStep = timeSpan / (tickCount - 1) // -1 because we want to include both start and end

    // Generate evenly spaced ticks
    xAxisTicks = Array.from({ length: tickCount }, (_, i) => {
      return new Date(startDate.getTime() + i * timeStep)
    })
  }

  // Add x grid using the same ticks
  chartGroup
    .append('g')
    .attr('class', 'grid x-grid')
    .attr('transform', `translate(0,${chartHeight})`)
    .style('opacity', 0.2)
    .call(
      d3
        .axisBottom(xScale)
        .tickValues(xAxisTicks)
        .tickSize(-chartHeight)
        .tickFormat(() => '')
    )

  // Add axes
  const xAxis = chartGroup.append('g').attr('class', 'x-axis').attr('transform', `translate(0,${chartHeight})`)

  if ('invert' in xScale && typeof xScale.invert(0) === 'object') {
    // Get domain to check time range
    const domain = xScale.domain() as Date[]
    const differenceInHours = Math.abs((domain[1].getTime() - domain[0].getTime()) / (1000 * 3600))
    const showDate = differenceInHours > 24

    xAxis.call(
      d3
        .axisBottom(xScale)
        .tickValues(xAxisTicks)
        .tickFormat((d: any) => {
          const date = new Date(d)
          let formattedTime = showTimeOnXAxis ? getUTCTimeString(date) : ''
          if (formattedTime === '24:00') {
            formattedTime = '00:00'
          }
          const formattedDate = getUTCDateString(date)
          return formattedTime ? `${formattedTime}\n${formattedDate}` : formattedDate
        })
    )

    // Style the x-axis text and lines
    const xAxisText = xAxis
      .selectAll('.tick text')
      .attr('class', 'font-lato text-sm font-medium')
      .style('fill', `rgb(${chartColorCodes.darkGray})`)
      .style('text-anchor', 'middle')
      .style('dominant-baseline', 'central')

    if (diagonalXAxisText) {
      // Check if labels overlap
      let maxWidth = 0
      xAxisText.each(function () {
        const bbox = (this as SVGTextElement).getBBox()
        maxWidth = Math.max(maxWidth, bbox.width)
      })

      const tickSpacing = Math.abs(xScale(xAxisTicks[1]) - xScale(xAxisTicks[0]))
      if (maxWidth * 1.1 > tickSpacing) {
        // Calculate how many labels to skip to avoid overlap
        const rotatedWidth = maxWidth * Math.cos(Math.PI / 4) // Width when rotated 45 degrees
        const skipCount = Math.ceil(rotatedWidth / tickSpacing)

        // Hide labels that would overlap
        xAxisText.each(function (_, i) {
          if (i % skipCount !== 0) {
            d3.select(this).style('display', 'none')
          } else {
            d3.select(this)
              .style('text-anchor', 'end')
              .attr('transform', 'rotate(-45)')
              .attr('dx', '-0.5em')
              .attr('dy', '0.5em')
          }
        })

        // Adjust the bottom margin dynamically based on text length
        const newMargin = Math.min(maxWidth * Math.sin(Math.PI / 4) + 40, 120) // Cap at 120px
        margin.bottom = Math.max(margin.bottom, newMargin)

        // Update chart height with new margin
        chartGroup.attr('transform', `translate(${margin.left},${margin.top})`)
      }

      // Split time and date into separate lines with better positioning
      xAxisText.each(function () {
        const text = d3.select(this)
        const words = text.text().split('\n')
        text.text('')

        if (words[0]) {
          text
            .append('tspan')
            .attr('class', 'font-lato text-sm font-medium')
            .style('fill', `rgb(${chartColorCodes.darkGray})`)
            .attr('x', 0)
            .attr('dy', '0.6em') // Increased spacing from top
            .text(words[0]) // Time
        }

        if (words[1]) {
          text
            .append('tspan')
            .attr('class', 'font-lato text-sm font-medium')
            .style('fill', `rgb(${chartColorCodes.darkGray})`)
            .attr('x', 0)
            .attr('dy', '1.2em')
            .text(words[1]) // Date
        }
      })
    } else {
      // Original non-diagonal text formatting with improved positioning
      xAxisText.each(function () {
        const text = d3.select(this)
        const words = text.text().split('\n')
        text.text('')

        if (words[0]) {
          text
            .append('tspan')
            .attr('class', 'font-lato text-sm font-medium')
            .style('fill', `rgb(${chartColorCodes.darkGray})`)
            .attr('x', 0)
            .attr('dy', '1em') // Increased from -1em
            .text(words[0]) // Time
        }

        if (words[1]) {
          text
            .append('tspan')
            .attr('class', 'font-lato text-sm font-medium')
            .style('fill', `rgb(${chartColorCodes.darkGray})`)
            .attr('x', 0)
            .attr('dy', '1.2em')
            .text(words[1]) // Date
        }
      })
    }

    // Style x-axis line
    xAxis.select('path').style('stroke', `rgb(${chartColorCodes.gray})`)
    xAxis.selectAll('.tick line').style('stroke', `rgb(${chartColorCodes.gray})`)
  } else {
    // Numeric axis with consistent styling
    const domain = xScale.domain()
    const start = Number(domain[0])
    const end = Number(domain[1])

    // Generate ticks using d3.ticks()
    const numericTicks = d3.ticks(start, end, 7)

    // Determine if we need decimal places based on the ticks
    const needsDecimals = numericTicks.some((tick) => !Number.isInteger(tick))

    const xAxisCall = d3
      .axisBottom(xScale)
      .tickValues(numericTicks)
      .tickFormat((d) => {
        if (typeof d === 'number') {
          return needsDecimals ? d.toFixed(1) : Math.round(d).toString()
        }
        return d.toString()
      })

    xAxis
      .call(xAxisCall)
      .selectAll('text')
      .attr('class', 'font-lato text-sm font-medium')
      .style('fill', `rgb(${chartColorCodes.darkGray})`)
      .style('text-anchor', 'middle')

    // Style x-axis line and ticks
    xAxis.select('path').style('stroke', `rgb(${chartColorCodes.gray})`)
    xAxis.selectAll('.tick line').style('stroke', `rgb(${chartColorCodes.gray})`)

    // Add grid lines for numeric axis with same tick values
    chartGroup
      .append('g')
      .attr('class', 'grid x-grid')
      .attr('transform', `translate(0,${chartHeight})`)
      .style('opacity', 0.2)
      .call(
        d3
          .axisBottom(xScale)
          .tickValues(numericTicks)
          .tickSize(-chartHeight)
          .tickFormat(() => '')
      )
  }

  // Add y-axis with fixed tick values
  const yAxisGroup = chartGroup
    .append('g')
    .attr('class', 'y-axis')
    .call(d3.axisLeft(yScale).tickValues(hideYAxisValues ? [] : tickValues))

  // Style y-axis text
  yAxisGroup
    .selectAll('text')
    .attr('class', 'font-lato text-sm font-medium')
    .style('fill', `rgb(${chartColorCodes.darkGray})`)
    .attr('dy', '0.3em')

  // Style y-axis line
  yAxisGroup.select('path').style('stroke', `rgb(${chartColorCodes.gray})`)

  yAxisGroup.selectAll('.tick line').style('stroke', `rgb(${chartColorCodes.gray})`)

  // Add y1 axis if scale is provided, with same number of ticks
  if (y1Scale) {
    const y1TickValues = d3.ticks(y1Domain[0], y1Domain[1], 7)

    const y1AxisGroup = chartGroup
      .append('g')
      .attr('class', 'y1-axis')
      .attr('transform', `translate(${chartWidth},0)`)
      .call(d3.axisRight(y1Scale).tickValues(hideYAxisValues ? [] : y1TickValues))

    // Style y1-axis text
    y1AxisGroup
      .selectAll('text')
      .attr('class', 'font-lato text-sm font-medium')
      .style('fill', `rgb(${chartColorCodes.darkGray})`)
      .attr('dx', '0.5em')
      .attr('dy', '0.3em')

    // Style y1-axis line
    y1AxisGroup.select('path').style('stroke', `rgb(${chartColorCodes.gray})`)

    y1AxisGroup.selectAll('.tick line').style('stroke', `rgb(${chartColorCodes.gray})`)
  }

  // Add axis labels with smokey-steel color
  // X-axis label
  chartGroup
    .append('text')
    .attr('class', 'font-lato text-md font-medium')
    .style('fill', `rgb(${chartColorCodes.darkGray})`)
    .attr('x', chartWidth / 2)
    .attr('y', chartHeight + margin.bottom - xAxisLabelMargin)
    .style('text-anchor', 'middle')
    .text(xAxisLabel)

  // Y-axis label
  chartGroup
    .append('text')
    .attr('class', 'font-lato text-md font-medium')
    .style('fill', `rgb(${chartColorCodes.darkGray})`)
    .attr('transform', 'rotate(-90)')
    .attr('x', -chartHeight / 2)
    .attr('y', -margin.left + 15)
    .style('text-anchor', 'middle')
    .text(yAxisLabel)

  // Y1-axis label if provided
  if (y1AxisLabel && y1Scale) {
    chartGroup
      .append('text')
      .attr('class', 'font-lato text-md font-medium')
      .style('fill', `rgb(${chartColorCodes.darkGray})`)
      .attr('transform', 'rotate(90)')
      .attr('x', chartHeight / 2)
      .attr('y', -chartWidth - margin.right + 15)
      .style('text-anchor', 'middle')
      .text(y1AxisLabel)
  }

  return chartGroup
}
