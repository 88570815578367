import classNames from 'classnames'
import trendUp from '../../../../assets/icons/trendUp.png'
import trendDown from '../../../../assets/icons/trendDown.png'

interface Props {
  percentage?: number
  isReversed?: boolean
}

const Percentage = ({ percentage = 0, isReversed = false }: Props) => {
  // if (percentage === 0) {
  //   return null
  // }

  let statusColor
  let trendIcon

  if (isReversed) {
    statusColor = percentage > 0 ? 'bad' : 'good'
    trendIcon = percentage > 0 ? trendDown : trendUp
  } else {
    statusColor = percentage < 0 ? 'bad' : 'good'
    trendIcon = percentage < 0 ? trendDown : trendUp
  }

  return (
    <div>
      <div className="flex pl-2 gap-1 items-center">
        <img
          src={trendIcon}
          alt="trend"
          className="w-5 h-5"
        />
        <p className={classNames('', statusColor === 'bad' ? 'text-coral-blaze' : 'text-lush-meadow')}>
          {Math.abs(percentage)}%
        </p>
      </div>
    </div>
  )
}

export default Percentage
