import React, { useEffect, useMemo, useRef } from 'react'
import * as d3 from 'd3'
import { createChartAxes } from '../shared/ChartAxes'
import { createChartLegend } from '../shared/ChartLegend'
import { Tooltip } from '../shared/Tooltip'
import { useD3 } from '../hooks/useD3'
import deepMerge from 'shared/utils/core/helpers/objects/deepObjectsMerge'

interface Dataset {
  label: string
  data: number[]
  borderColor: string
  yAxisID: string
  fill?: boolean
  backgroundColor?: string
  tension?: number
}

interface LegendItem {
  label: string
  color: string
  isDashed?: boolean
  isHidden?: boolean
}

export interface LineChartConfig {
  layout: {
    margin: {
      top: number
      right: number
      bottom: number
      left: number
    }
    lineWidth: number
  }
  interactions: {
    tooltip: {
      enabled: boolean
      showVerticalLine: boolean
    }
    legend: {
      enabled: boolean
      clickable: boolean
      /**
       * @description (NOT WORKING) The position of the legend.
       */
      position: 'top' | 'bottom'
      /**
       * Legend padding from the bottom of the chart
       */
      bottomPadding: number
      /**
       * Array of dataset indices to exclude from the legend
       */
      excludeFromLegend: number[]
      /**
       * When true, only the external onLegendClick handler will be called
       */
      useExternalLegendClick: boolean
      onLegendClick?: (index: number) => void
    }
  }
  axes: {
    x: {
      label: string
      bottomLabelMargin: number
      maxNormalGapDuration: number
    }
    y: {
      label: string
      /**
       * @description (NOT WORKING, ALWAYS ROUNDED) Whether to round the ticks to the nearest integer.
       */
      nice: boolean
      ticks: number
      fillBetweenDatasets: boolean
    }
    y1: {
      enabled: boolean
      label: string
      /**
       * @description (NOT WORKING, ALWAYS ROUNDED) Whether to round the ticks to the nearest integer.
       */
      nice: boolean
      ticks: number
    }
  }
}

export const defaultConfig: LineChartConfig = {
  layout: {
    margin: { top: 20, right: 80, bottom: 90, left: 80 },
    lineWidth: 2,
  },
  interactions: {
    tooltip: {
      enabled: true,
      showVerticalLine: true,
    },
    legend: {
      enabled: true,
      clickable: true,
      position: 'bottom',
      bottomPadding: 64,
      excludeFromLegend: [],
      useExternalLegendClick: false,
      onLegendClick: () => {},
    },
  },
  axes: {
    x: {
      label: '',
      bottomLabelMargin: 5,
      maxNormalGapDuration: 2 * 60 * 60 * 1000, // 2 hours in milliseconds
    },
    y: {
      label: '',
      nice: true,
      ticks: 7,
      fillBetweenDatasets: false,
    },
    y1: {
      enabled: true,
      label: '',
      nice: true,
      ticks: 7,
    },
  },
}

export type LineChartCustomConfig = {
  layout?: {
    margin?: Partial<LineChartConfig['layout']['margin']>
    lineWidth?: number
  }
  interactions?: {
    tooltip?: Partial<LineChartConfig['interactions']['tooltip']>
    legend?: Partial<LineChartConfig['interactions']['legend']>
  }
  axes?: {
    x?: Partial<LineChartConfig['axes']['x']>
    y?: Partial<LineChartConfig['axes']['y']>
    y1?: Partial<LineChartConfig['axes']['y1']>
  }
}

interface Props {
  datasets: Dataset[]
  timestamps: number[]
  tzOffset?: number
  trend?: number
  config?: LineChartCustomConfig
}

const LineChart: React.FC<Props> = ({ datasets, timestamps, tzOffset = 0, trend, config: customConfig }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<Tooltip | null>(null)

  const config = useMemo(() => deepMerge({ ...defaultConfig }, customConfig || {}) as LineChartConfig, [customConfig])

  useEffect(() => {
    return () => {
      if (tooltipRef.current) {
        tooltipRef.current.destroy()
      }
    }
  }, [])

  const createChart = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => {
    if (!containerRef.current || !datasets.length || !timestamps.length) return

    // Clean up previous chart
    svg.selectAll('*').remove()
    if (tooltipRef.current) {
      tooltipRef.current.destroy()
      tooltipRef.current = null
    }

    // Get container dimensions
    const containerRect = containerRef.current.getBoundingClientRect()
    const width = containerRect.width
    const height = containerRect.height

    // Set up SVG attributes
    svg
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')

    const chartWidth = width - config.layout.margin.left - config.layout.margin.right
    const chartHeight = height - config.layout.margin.top - config.layout.margin.bottom

    // Check if timestamps are dates (large numbers) or regular values
    // Typical timestamp is around 1.6e9 (2020) to 1.7e9 (2024)
    const isDateTimestamp = timestamps.some((t) => t > 1e9 && t < 2e9)

    // Create scales
    let xScale: d3.ScaleTime<number, number> | d3.ScaleLinear<number, number>
    let dates: Date[] = []

    if (isDateTimestamp) {
      // Convert timestamps to dates for date-based charts
      dates = timestamps.map((t) => new Date((t + tzOffset * 3600) * 1000))
      xScale = d3
        .scaleTime()
        .domain(d3.extent(dates) as [Date, Date])
        .range([0, chartWidth])
    } else {
      // Use linear scale for regular numeric values
      xScale = d3
        .scaleLinear()
        .domain(d3.extent(timestamps) as [number, number])
        .nice()
        .range([0, chartWidth])
    }

    const yScale = d3
      .scaleLinear()
      .domain([0, (d3.max(datasets[0].data) || 1) * 1.1])
      .nice(config.axes.y.ticks)
      .range([chartHeight, 0])

    // For fillBetweenDatasets, use the same scale for both axes
    // Otherwise, create separate scale for the second dataset
    const y1Scale = config.axes.y.fillBetweenDatasets
      ? yScale
      : datasets[1]
        ? d3
            .scaleLinear()
            .domain([0, (d3.max(datasets[1].data || [0]) || 0) * 1.1])
            .nice(config.axes.y1.ticks)
            .range([chartHeight, 0])
        : yScale

    // Add axes using ChartAxes component
    const chartGroup = createChartAxes({
      svg,
      width,
      height,
      margin: config.layout.margin,
      xScale,
      yScale,
      y1Scale,
      xAxisLabel: config.axes.x.label,
      yAxisLabel: config.axes.y.label,
      y1AxisLabel: config.axes.y1.label,
      showTimeOnXAxis: isDateTimestamp,
      xAxisLabelMargin: config.axes.x.bottomLabelMargin,
    })

    // Add clip path for the chart area
    chartGroup
      .append('clipPath')
      .attr('id', 'chart-area')
      .append('rect')
      .attr('width', chartWidth)
      .attr('height', chartHeight)

    // Add lines and areas
    datasets.forEach((dataset, i) => {
      // Split data into regular segments and gap segments
      type DataPoint = { date: Date; value: number }
      const regularSegments: DataPoint[][] = []
      const gapSegments: DataPoint[][] = []
      let currentSegment: DataPoint[] = []

      // Calculate average time difference between points
      const timeDiffs: number[] = []
      for (let j = 1; j < dates.length; j++) {
        const diff = dates[j].getTime() - dates[j - 1].getTime()
        if (diff > 0) {
          // Ignore zero or negative differences
          timeDiffs.push(diff)
        }
      }

      // Calculate median time difference as it's more robust to outliers than mean
      const sortedDiffs = [...timeDiffs].sort((a, b) => a - b)
      const medianDiff = sortedDiffs[Math.floor(sortedDiffs.length / 2)] || 0

      // Calculate threshold as 5 times the median difference
      // This means if points usually come every 5 minutes, gaps longer than 25 minutes will be dashed
      const gapThreshold = Math.max(
        medianDiff * 5,
        config.axes.x.maxNormalGapDuration || 2 * 60 * 60 * 1000 // Use provided maxNormalGapDuration as minimum threshold
      )

      dataset.data.forEach((value, idx) => {
        const point = { date: dates[idx], value }

        if (idx === 0) {
          currentSegment = [point]
          return
        }

        const timeDiff = dates[idx].getTime() - dates[idx - 1].getTime()
        if (timeDiff > gapThreshold) {
          if (currentSegment.length > 0) {
            regularSegments.push(currentSegment)
          }
          // Add the gap segment
          gapSegments.push([{ date: dates[idx - 1], value: dataset.data[idx - 1] }, point])
          currentSegment = [point]
        } else {
          currentSegment.push(point)
        }
      })

      // Add the last segment if it exists
      if (currentSegment.length > 0) {
        regularSegments.push(currentSegment)
      }

      const lineGen = d3
        .line<DataPoint>()
        .x((d) => xScale(d.date))
        .y((d) => (dataset.yAxisID === 'y' ? yScale(d.value) : y1Scale(d.value)))
        .curve(dataset.tension ? d3.curveCatmullRom.alpha(dataset.tension) : d3.curveLinear)

      // Draw regular segments
      regularSegments.forEach((segment) => {
        chartGroup
          .append('path')
          .datum(segment)
          .attr('fill', 'none')
          .attr('stroke', dataset.borderColor)
          .attr('stroke-width', config.layout.lineWidth)
          .attr('d', lineGen)
          .attr('class', `dataset-${i} line-${i}`)
          .attr('clip-path', 'url(#chart-area)')
      })

      // Draw gap segments with dashed lines
      gapSegments.forEach((segment) => {
        chartGroup
          .append('path')
          .datum(segment)
          .attr('fill', 'none')
          .attr('stroke', dataset.borderColor)
          .attr('stroke-width', config.layout.lineWidth)
          .attr('stroke-dasharray', '5,5')
          .attr('d', lineGen)
          .attr('class', `dataset-${i} line-${i}-gap`)
          .attr('clip-path', 'url(#chart-area)')
      })

      // Add areas if needed
      if (dataset.fill && dataset.backgroundColor) {
        const areaGen = d3
          .area<DataPoint>()
          .x((d) => xScale(d.date))
          .y0(chartHeight)
          .y1((d) => (dataset.yAxisID === 'y' ? yScale(d.value) : y1Scale(d.value)))
          .curve(dataset.tension ? d3.curveCatmullRom.alpha(dataset.tension) : d3.curveLinear)

        const fillColor = dataset.backgroundColor

        // Draw regular area segments
        regularSegments.forEach((segment) => {
          chartGroup
            .append('path')
            .datum(segment)
            .attr('fill', fillColor)
            .attr('d', areaGen)
            .attr('class', `dataset-${i} area-${i}`)
            .attr('clip-path', 'url(#chart-area)')
        })

        // Draw gap area segments with reduced opacity
        gapSegments.forEach((segment) => {
          chartGroup
            .append('path')
            .datum(segment)
            .attr('fill', fillColor)
            .attr('fill-opacity', 0.3)
            .attr('d', areaGen)
            .attr('class', `dataset-${i} area-${i}-gap`)
            .attr('clip-path', 'url(#chart-area)')
        })
      }

      // Handle fillBetweenDatasets
      if (config.axes.y.fillBetweenDatasets && i === 0 && datasets.length > 1) {
        const areaGen = d3
          .area<DataPoint>()
          .x((d) => xScale(d.date))
          .y0((d, i) => {
            // Find the corresponding index in the original timestamps array
            const timestamp = d.date.getTime() / 1000 - tzOffset * 3600
            const dataIndex = timestamps.indexOf(timestamp)
            return yScale(datasets[1].data[dataIndex])
          })
          .y1((d) => yScale(d.value))
          .curve(dataset.tension ? d3.curveCatmullRom.alpha(dataset.tension) : d3.curveLinear)

        const fillColor = dataset.backgroundColor || 'rgba(0, 0, 0, 0.1)'

        // Draw regular area segments only (no fill in gaps)
        regularSegments.forEach((segment) => {
          chartGroup
            .append('path')
            .datum(segment)
            .attr('fill', fillColor)
            .attr('d', areaGen)
            .attr('class', `dataset-${i} area-${i}`)
            .attr('clip-path', 'url(#chart-area)')
        })

        // For gap segments, draw only dashed lines for upper and lower boundaries
        gapSegments.forEach((segment) => {
          // Upper boundary (dashed line)
          const upperLineGen = d3
            .line<DataPoint>()
            .x((d) => xScale(d.date))
            .y((d) => yScale(d.value))
            .curve(dataset.tension ? d3.curveCatmullRom.alpha(dataset.tension) : d3.curveLinear)

          chartGroup
            .append('path')
            .datum(segment)
            .attr('fill', 'none')
            .attr('stroke', dataset.borderColor)
            .attr('stroke-width', config.layout.lineWidth)
            .attr('stroke-dasharray', '5,5')
            .attr('d', upperLineGen)
            .attr('class', `dataset-${i} line-${i}-gap`)
            .attr('clip-path', 'url(#chart-area)')

          // Lower boundary (dashed line)
          const lowerLineGen = d3
            .line<DataPoint>()
            .x((d) => xScale(d.date))
            .y((d) => {
              const timestamp = d.date.getTime() / 1000 - tzOffset * 3600
              const dataIndex = timestamps.indexOf(timestamp)
              return yScale(datasets[1].data[dataIndex])
            })
            .curve(dataset.tension ? d3.curveCatmullRom.alpha(dataset.tension) : d3.curveLinear)

          chartGroup
            .append('path')
            .datum(segment)
            .attr('fill', 'none')
            .attr('stroke', datasets[1].borderColor)
            .attr('stroke-width', config.layout.lineWidth)
            .attr('stroke-dasharray', '5,5')
            .attr('d', lowerLineGen)
            .attr('class', `dataset-${i} line-${i}-gap`)
            .attr('clip-path', 'url(#chart-area)')
        })
      }
    })

    // Add trend line if trend is provided
    if (typeof trend === 'number') {
      if (trend !== 0) {
        const isPositive = trend >= 0
        const trendColor = datasets[0].borderColor // Use the same color as the main line
        const maxAngle = Math.atan(chartHeight / chartWidth) * (180 / Math.PI) // Maximum angle in degrees
        const normalizedTrend = Math.min(Math.abs(trend) / 20, 1) // Normalize trend to 0-1 range, capping at 20%
        const angle = (isPositive ? 1 : -1) * normalizedTrend * maxAngle

        // Calculate trend line endpoints using full chart width
        const radians = (angle * Math.PI) / 180
        const height = Math.tan(radians) * chartWidth
        const centerY = chartHeight / 2

        chartGroup
          .append('line')
          .attr('x1', 0)
          .attr('y1', centerY + height / 2)
          .attr('x2', chartWidth)
          .attr('y2', centerY - height / 2)
          .attr('stroke', trendColor)
          .attr('stroke-width', config.layout.lineWidth)
          .attr('stroke-dasharray', '10,10')
          .attr('class', 'trend-line')
      }
    }

    // Add legend
    if (config.interactions.legend.enabled) {
      createChartLegend({
        svg,
        items: [
          ...datasets
            .filter((_, index) => !config.interactions.legend.excludeFromLegend.includes(index))
            .map((d) => ({ label: d.label, color: d.borderColor })),
          ...(trend !== undefined
            ? [
                {
                  label: `Trend: ${trend.toFixed(1)}%`,
                  color: datasets[0].borderColor,
                  isDashed: true,
                  isHidden: false,
                },
              ]
            : []),
        ],
        width,
        height,
        margin: config.layout.margin,
        onLegendClick: (index) => {
          if (config.interactions.legend.useExternalLegendClick) {
            config.interactions.legend.onLegendClick?.(index)
            return
          }

          if (index < datasets.length) {
            const elements = svg.selectAll(`.dataset-${index}`)
            const isHidden = elements.style('opacity') === '0'

            elements.style('opacity', isHidden ? 1 : 0)
            config.interactions.legend.onLegendClick?.(index)
          } else {
            const trendLine = svg.select('.trend-line')
            const isHidden = trendLine.style('opacity') === '0'
            trendLine.style('opacity', isHidden ? 1 : 0)
          }
        },
        bottomPadding: config.interactions.legend.bottomPadding,
        clickable: config.interactions.legend.clickable,
      })
    }

    if (config.interactions.tooltip.enabled) {
      // Initialize tooltip
      tooltipRef.current = new Tooltip({
        container: containerRef.current,
        chartGroup,
        xScale,
        height: chartHeight,
        showVerticalLine: config.interactions.tooltip.showVerticalLine,
        showTimeOnXAxis: isDateTimestamp,
        getValues: (x: any) => {
          let index: number = -1

          if (isDateTimestamp) {
            // For date-based charts
            const date = x as Date
            const timestamp = Math.floor(date.getTime() / 1000 - tzOffset * 3600)

            // Find the closest timestamp
            const bisect = d3.bisector((t: number) => t).left
            index = bisect(timestamps, timestamp)

            // Adjust index to closest value
            if (index > 0 && index < timestamps.length) {
              const leftDiff = Math.abs(timestamps[index - 1] - timestamp)
              const rightDiff = Math.abs(timestamps[index] - timestamp)
              if (leftDiff < rightDiff) {
                index--
              }
            }
          } else {
            // For numeric values
            const value = x as number
            const bisect = d3.bisector((t: number) => t).left
            index = bisect(timestamps, value)

            // Adjust index to closest value
            if (index > 0 && index < timestamps.length) {
              const leftDiff = Math.abs(timestamps[index - 1] - value)
              const rightDiff = Math.abs(timestamps[index] - value)
              if (leftDiff < rightDiff) {
                index--
              }
            }
          }

          // Ensure index is within bounds
          if (index < 0) index = 0
          if (index >= timestamps.length) index = timestamps.length - 1

          return datasets.map((dataset) => ({
            label: dataset.label,
            value: dataset.data[index],
            decimals: 2,
            color: dataset.borderColor,
            xValue: isDateTimestamp ? dates[index] : timestamps[index],
          }))
        },
      })
    }

    // Add hover area
    const hoverArea = svg
      .append('rect')
      .attr('transform', `translate(${config.layout.margin.left},${config.layout.margin.top})`)
      .attr('class', 'overlay')
      .attr('width', chartWidth)
      .attr('height', chartHeight)
      .style('fill', 'none')
      .style('pointer-events', 'all')

    tooltipRef.current?.addTo(hoverArea)
  }

  const svgRef = useD3(createChart, [datasets, timestamps, tzOffset, trend, config])

  return (
    <div
      ref={containerRef}
      className="w-full h-full"
    >
      <svg
        ref={svgRef}
        style={{
          width: '100%',
          height: '100%',
          userSelect: 'none',
          WebkitUserSelect: 'none',
        }}
      />
    </div>
  )
}

export default LineChart
