import React, { useRef, useState, useEffect, useMemo } from 'react'
import * as d3 from 'd3'
import { createChartAxes } from '../shared/ChartAxes'
import { createChartSections } from '../shared/ChartSections'
import { createChartLegend } from '../shared/ChartLegend'
import { Tooltip } from '../shared/Tooltip'
import { useD3 } from '../hooks/useD3'
import deepMerge from 'shared/utils/core/helpers/objects/deepObjectsMerge'

interface DataPoint {
  nameX: string
  nameY: string
  valueX: number
  valueY: number
}

interface Dataset {
  label: string
  data: DataPoint[]
  color: string
  strokeWidth?: number
  dashArray?: string
  yAxisID?: 'y' | 'y1' // Support for dual Y axes
  fill?: boolean
  backgroundColor?: string
}

interface LegendItem {
  label: string
  color: string
  isDashed?: boolean
  isHidden?: boolean
}

export interface NumericLineChartConfig {
  layout: {
    margin: {
      top: number
      right: number
      bottom: number
      left: number
    }
    lineWidth: number
  }
  interactions: {
    tooltip: {
      enabled: boolean
      showVerticalLine: boolean
    }
    legend: {
      enabled: boolean
      clickable: boolean
      /**
       * @description (NOT WORKING) The position of the legend.
       */
      position: 'top' | 'bottom'
      /**
       * Legend padding from the bottom of the chart
       */
      bottomPadding: number
    }
  }
  axes: {
    x: {
      label: string
      bottomLabelMargin: number
    }
    y: {
      label: string
      /**
       * @description (NOT WORKING, ALWAYS ROUNDED) Whether to round the ticks to the nearest integer.
       */
      nice: boolean
      ticks: number
      startFromZero: boolean
    }
    y1: {
      enabled: boolean
      label: string
      /**
       * @description (NOT WORKING, ALWAYS ROUNDED) Whether to round the ticks to the nearest integer.
       */
      nice: boolean
      ticks: number
    }
  }
  chartSections: {
    enabled: boolean
    massFlowRateLowerThreshold: number
    massFlowRateUpperThreshold: number
  }
}

export const defaultConfig: NumericLineChartConfig = {
  layout: {
    margin: { top: 20, right: 80, bottom: 60, left: 80 },
    lineWidth: 2,
  },
  interactions: {
    tooltip: {
      enabled: false,
      showVerticalLine: false,
    },
    legend: {
      enabled: true,
      clickable: true,
      position: 'bottom',
      bottomPadding: 57,
    },
  },
  axes: {
    x: {
      label: '',
      bottomLabelMargin: 5,
    },
    y: {
      label: '',
      nice: true,
      ticks: 7,
      startFromZero: true,
    },
    y1: {
      enabled: true,
      label: '',
      nice: true,
      ticks: 7,
    },
  },
  chartSections: {
    enabled: false,
    massFlowRateLowerThreshold: 0,
    massFlowRateUpperThreshold: 0,
  },
}

export type NumericLineChartCustomConfig = {
  layout?: {
    margin?: Partial<NumericLineChartConfig['layout']['margin']>
    lineWidth?: number
  }
  interactions?: {
    tooltip?: Partial<NumericLineChartConfig['interactions']['tooltip']>
    legend?: Partial<NumericLineChartConfig['interactions']['legend']>
  }
  axes?: {
    x?: Partial<NumericLineChartConfig['axes']['x']>
    y?: Partial<NumericLineChartConfig['axes']['y']>
    y1?: Partial<NumericLineChartConfig['axes']['y1']>
  }
  chartSections?: Partial<NumericLineChartConfig['chartSections']>
}

interface Props {
  datasets: Dataset[]
  /**
   * Callback function called when a legend item is clicked.
   * The index of the clicked dataset is passed as an argument.
   */
  onLegendClick?: (index: number) => void
  config?: NumericLineChartCustomConfig
}

const NumericLineChart: React.FC<Props> = ({ datasets, onLegendClick, config: customConfig }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<Tooltip | null>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  const config = useMemo(
    () => deepMerge({ ...defaultConfig }, customConfig || {}) as NumericLineChartConfig,
    [customConfig]
  )

  const createChart = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => {
    if (!dimensions || dimensions.width === 0 || dimensions.height === 0 || datasets.length === 0) return

    // Clean up previous tooltip
    if (tooltipRef.current) {
      tooltipRef.current.destroy()
      tooltipRef.current = null
    }

    // Clear previous chart
    svg.selectAll('*').remove()

    const { width, height } = dimensions
    const chartWidth = width - config.layout.margin.left - config.layout.margin.right
    const chartHeight = height - config.layout.margin.top - config.layout.margin.bottom

    // Set up SVG attributes
    svg.attr('width', width).attr('height', height)

    // Find min/max values for X and Y axes
    const allDataPoints = datasets.flatMap((dataset) => dataset.data)
    const xValues = allDataPoints.map((d) => d.valueX)
    const yValues = allDataPoints
      .filter((d) => {
        const dataset = datasets.find((ds) => ds.data.includes(d))
        return dataset?.yAxisID !== 'y1'
      })
      .map((d) => d.valueY)
    const y1Values = allDataPoints
      .filter((d) => {
        const dataset = datasets.find((ds) => ds.data.includes(d))
        return dataset?.yAxisID === 'y1'
      })
      .map((d) => d.valueY)

    const xMin = d3.min(xValues) || 0
    const xMax = d3.max(xValues) || 0
    const yMin = d3.min(yValues) || 0
    const yMax = d3.max(yValues) || 0
    const y1Min = y1Values.length ? d3.min(y1Values) || 0 : 0
    const y1Max = y1Values.length ? d3.max(y1Values) || 0 : 0

    // Add padding to X axis range to prevent points from being cut off at edges
    const xPadding = (xMax - xMin) * 0.05

    // Calculate padding for Y axis
    const yRange = yMax - yMin
    const yPaddingBottom = yRange * 0.1 // 10% padding below minimum
    const yPaddingTop = yRange * 0.1 // 10% padding above maximum

    // Calculate padding for Y1 axis if needed
    const y1Range = y1Max - y1Min
    const y1PaddingBottom = y1Range * 0.1
    const y1PaddingTop = y1Range * 0.1

    // Create scales
    const xScale = d3
      .scaleLinear()
      .domain([xMin - xPadding, xMax + xPadding])
      .range([0, chartWidth])
      .nice(9)

    const yScale = d3
      .scaleLinear()
      .domain([config.axes.y.startFromZero ? 0 : Math.max(0, yMin - yPaddingBottom), yMax + yPaddingTop])
      .range([chartHeight, 0])
      .nice(7)

    // Create secondary Y scale if needed
    const y1Scale = y1Values.length
      ? d3
          .scaleLinear()
          .domain([config.axes.y.startFromZero ? 0 : Math.max(0, y1Min - y1PaddingBottom), y1Max + y1PaddingTop])
          .range([chartHeight, 0])
          .nice(7)
      : yScale

    // Add chart sections if needed
    if (config.chartSections.enabled) {
      createChartSections({
        svg,
        width,
        height,
        margin: config.layout.margin,
        yScale,
        massFlowRateLowerThreshold: config.chartSections.massFlowRateLowerThreshold,
        massFlowRateUpperThreshold: config.chartSections.massFlowRateUpperThreshold,
      })
    }

    // Add axes using ChartAxes component
    const chartGroup = createChartAxes({
      svg,
      width,
      height,
      margin: config.layout.margin,
      xScale,
      yScale,
      y1Scale,
      xAxisLabel: config.axes.x.label,
      yAxisLabel: config.axes.y.label,
      y1AxisLabel: config.axes.y1.label,
      xAxisLabelMargin: config.axes.x.bottomLabelMargin,
    })

    // Create a group for lines with clip path
    const clipPathId = `line-chart-clip-${Math.random().toString(36).substr(2, 9)}`
    chartGroup
      .append('defs')
      .append('clipPath')
      .attr('id', clipPathId)
      .append('rect')
      .attr('width', chartWidth)
      .attr('height', chartHeight)

    const linesGroup = chartGroup.append('g').attr('clip-path', `url(#${clipPathId})`)

    // Create line paths
    datasets.forEach((dataset, i) => {
      if (dataset.data.length === 0) return

      const line = d3
        .line<DataPoint>()
        .x((d) => xScale(d.valueX))
        .y((d) => {
          const scale = dataset.yAxisID === 'y1' && y1Scale !== null ? y1Scale : yScale
          return scale(d.valueY)
        })

      linesGroup
        .append('path')
        .datum(dataset.data)
        .attr('fill', 'none')
        .attr('stroke', dataset.color)
        .attr('stroke-width', dataset.strokeWidth || 2)
        .attr('stroke-dasharray', dataset.dashArray || '')
        .attr('class', `line-${i}`)
        .attr('d', line)

      // Add fill if requested
      if (dataset.fill && dataset.backgroundColor) {
        const area = d3
          .area<DataPoint>()
          .x((d) => xScale(d.valueX))
          .y0(chartHeight)
          .y1((d) => {
            if (dataset.yAxisID === 'y1' && y1Scale) {
              return y1Scale(d.valueY)
            }
            return yScale(d.valueY)
          })
          .curve(d3.curveMonotoneX)

        linesGroup
          .append('path')
          .datum(dataset.data)
          .attr('class', `area-${i}`)
          .attr('fill', dataset.backgroundColor)
          .attr('d', area)
      }
    })

    // Add legend
    createChartLegend({
      svg,
      items: datasets.map((d) => ({ label: d.label, color: d.color })),
      width,
      height,
      margin: config.layout.margin,
      onLegendClick: (index) => {
        const elements = svg.selectAll(`.line-${index}, .area-${index}`)
        const isHidden = elements.style('opacity') === '0'
        elements.style('opacity', isHidden ? 1 : 0)
        onLegendClick?.(index)
      },
      bottomPadding: 57,
    })

    // Add tooltip if Tooltip is enabled
    if (config.interactions.tooltip.enabled && containerRef.current) {
      tooltipRef.current = new Tooltip({
        container: containerRef.current,
        chartGroup,
        xScale,
        height: chartHeight,
        showVerticalLine: config.interactions.tooltip.showVerticalLine,
        getValues: (x: number) => {
          // Find the closest x value in each dataset
          const values: any[] = []

          datasets.forEach((dataset, i) => {
            // Find closest point
            const bisect = d3.bisector((d: DataPoint) => d.valueX).left
            const sortedData = [...dataset.data].sort((a, b) => a.valueX - b.valueX)
            const index = bisect(sortedData, x)

            // Get the closest point (left or right)
            let point: DataPoint | null = null
            if (index >= sortedData.length) {
              point = sortedData[sortedData.length - 1]
            } else if (index === 0) {
              point = sortedData[0]
            } else {
              const leftPoint = sortedData[index - 1]
              const rightPoint = sortedData[index]
              point = Math.abs(leftPoint.valueX - x) < Math.abs(rightPoint.valueX - x) ? leftPoint : rightPoint
            }

            if (point) {
              values.push({
                label: dataset.label,
                value: point.valueY,
                color: dataset.color,
                xValue: point.valueX,
              })
            }
          })

          // Add x value as first item
          if (values.length > 0) {
            values.unshift({
              label: config.axes.x.label || datasets[0]?.data[0]?.nameX || 'X',
              value: values[0].xValue,
            })
          }

          return values
        },
      })

      // Add hover area
      const hoverArea = chartGroup
        .append('rect')
        .attr('class', 'overlay')
        .attr('width', chartWidth)
        .attr('height', chartHeight)
        .style('fill', 'none')
        .style('pointer-events', 'all')

      tooltipRef.current.addTo(hoverArea)
    }
  }

  // Update dimensions on container resize
  const resizeObserver = new ResizeObserver((entries) => {
    if (entries[0]) {
      const { width, height } = entries[0].contentRect
      setDimensions({ width, height })
    }
  })

  useEffect(() => {
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }
    return () => {
      resizeObserver.disconnect()
      if (tooltipRef.current) {
        tooltipRef.current.destroy()
      }
    }
  }, [config])

  const svgRef = useD3(createChart, [datasets, dimensions, config])

  return (
    <div
      ref={containerRef}
      style={{ width: '100%', height: '100%', userSelect: 'none', WebkitUserSelect: 'none' }}
    >
      <style>
        {`
          .line-0, .line-1, .line-2, .line-3, .line-4, .line-5, .line-6, .line-7, .line-8, .line-9 {
            opacity: 0.8;
          }
          .area-0, .area-1, .area-2, .area-3, .area-4, .area-5, .area-6, .area-7, .area-8, .area-9 {
            opacity: 0.3;
          }
          .hidden {
            opacity: 0 !important;
          }
        `}
      </style>
      <svg
        ref={svgRef}
        style={{ width: '100%', height: '100%', userSelect: 'none', WebkitUserSelect: 'none' }}
      />
    </div>
  )
}

export default NumericLineChart
