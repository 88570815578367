import React, { ChangeEvent, ReactNode } from 'react'
import SearchIcon from '../../../../assets/search_icon.png'

interface ReusableInputFieldProps {
  label?: string
  type?: string
  value: string
  onChange: (value: string) => void
  required?: boolean
  placeholder?: string
  children?: ReactNode
  width?: string
  height?: string
  isSearchInput?: boolean
  icon?: ReactNode
  onIconClick?: () => void
  className?: string
  error?: boolean
  name?: string
}

const ReusableInputField: React.FC<ReusableInputFieldProps> = ({
  label,
  type = 'text',
  value,
  onChange,
  required = false,
  placeholder,
  children,
  width,
  height,
  isSearchInput = false,
  icon,
  onIconClick,
  className,
  error,
  name,
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  const inputStyle = {
    width: width ?? '100%',
    height: height ?? 'auto',
    borderColor: error ? 'rgb(253, 62, 63)' : '',
  }

  return (
    <div className="relative">
      {label && (
        <label
          htmlFor={type}
          className="block text-14px text-[#5D657A] font-semibold leading-6 max-lg:mb-1 max-lg:pt-3"
        >
          {label}
        </label>
      )}
      <div className="relative">
        {isSearchInput && (
          <img
            src={SearchIcon}
            alt="Search"
            className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 cursor-pointer"
            onClick={onIconClick}
          />
        )}
        <input
          type={type}
          className={`border-1 border-grayOutlineColor rounded-lg focus:ring-2 focus:ring-[#DAE2E7] focus:border-[#D0D5DD] py-2 pl-${isSearchInput ? '10' : '4'} pr-4 ${className}`}
          value={value}
          onChange={handleChange}
          required={required}
          placeholder={placeholder}
          style={inputStyle}
          name={name}
        />
        {icon && typeof icon === 'string' && (
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer">
            <img
              src={icon}
              alt="Icon"
              onClick={onIconClick}
            />
          </div>
        )}
      </div>
      {children}
    </div>
  )
}

export default ReusableInputField
