import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'

import { vesselsApi } from './apis/vesselsApi'
import { inTimeGraphApi } from './apis/inTimeGraphApi'
import { totalsGraphApi } from './apis/totalsGraphApi'
import { inKnotsGraphApi } from './apis/inKnotsGraphApi'
import { inPowerGraphApi } from './apis/inPowerGraphApi'
import { analyticsReducer } from './slices/analyticsSlice'
import { authReducer } from './slices/authSlice'
import { userApi } from './apis/userApi'
import { appReducer } from './slices/appSlice'
import { exportApi } from './apis/exportApi'
import { lineChartReducer } from './slices/lineChartSlice'
import { newAnalyticsReducer } from './slices/newAnalyticsSlice'
import { areaGraphApi } from './apis/areaGraphApi'
import { companyApi } from './apis/companyApi'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['app'],
  migrate: (state: any) => {
    if (!state || typeof state !== 'object') {
      return Promise.resolve({
        app: {
          termsAccepted: true,
          dateRange: {
            startTime: Date.now(),
            endTime: Date.now(),
          },
          selectedTimezone: {
            abbrev: 'GMT',
            label: '(GMT+0:00) UTC',
            value: 'Etc/GMT',
            offset: 0,
            altName: 'British Standard Time',
          },
        },
      })
    }

    if (state.app) {
      return Promise.resolve({
        ...state,
        app: {
          ...state.app,
          termsAccepted: state.app.termsAccepted ?? true,
          dateRange: state.app.dateRange || {
            startTime: Date.now(),
            endTime: Date.now(),
          },
          selectedTimezone: state.app.selectedTimezone || {
            abbrev: 'GMT',
            label: '(GMT+0:00) UTC',
            value: 'Etc/GMT',
            offset: 0,
            altName: 'British Standard Time',
          },
        },
      })
    }
    return Promise.resolve(state)
  },
}

const rootReducer = combineReducers({
  auth: authReducer,
  [userApi.reducerPath]: userApi.reducer,
  [vesselsApi.reducerPath]: vesselsApi.reducer,
  [inTimeGraphApi.reducerPath]: inTimeGraphApi.reducer,
  [inKnotsGraphApi.reducerPath]: inKnotsGraphApi.reducer,
  [inPowerGraphApi.reducerPath]: inPowerGraphApi.reducer,
  [totalsGraphApi.reducerPath]: totalsGraphApi.reducer,
  [exportApi.reducerPath]: exportApi.reducer,
  [areaGraphApi.reducerPath]: areaGraphApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  analytics: analyticsReducer,
  app: appReducer,
  lineChart: lineChartReducer,
  newAnalyticsSlice: newAnalyticsReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        // Ignore redux-persist actions
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }).concat(
      userApi.middleware,
      inTimeGraphApi.middleware,
      vesselsApi.middleware,
      totalsGraphApi.middleware,
      inKnotsGraphApi.middleware,
      inPowerGraphApi.middleware,
      exportApi.middleware,
      areaGraphApi.middleware,
      companyApi.middleware
    )
  },
})

const persistor = persistStore(store)

setupListeners(store.dispatch)

export { store, persistor }

export * from 'core/store/thunks/auth/login'
export * from 'core/store/thunks/auth/logout'
export * from 'core/store/thunks/auth/validateAndSetNewPassword'
export * from 'core/store/thunks/auth/getUserCustomClaims'

export {
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useResetPasswordMutation,
  useSetUserCustomClaimsMutation,
} from './apis/userApi'

export {
  changeMeasuredBy,
  changeGraph1Source,
  changeGraph1Metric,
  changeGraph1Engines,
  changeGraph1TrendlineStatus,
  changeGraph2Source,
  changeGraph2Metric,
  changeGraph2Engines,
  changeGraph2TrendlineStatus,
  changeDateFilters,
  changeErrorStatus,
} from './slices/analyticsSlice'

export {
  changeGraphIsFetching,
  changeChartIsRendering,
  changeTimezoneOffset,
  changeLineChartTickCount,
  changeSelectedVessel,
  changeDateRange,
  setViewMode,
  setTermsAccepted,
} from './slices/appSlice'

export { changeAuthIsLoading, changeInitiallyRequestedUrl, changeUser, changeAuthError } from './slices/authSlice'

export {
  changeLineChartAreaWidth,
  changePerformanceOverviewAlignmentDone,
  resetLineChartState,
} from './slices/lineChartSlice'

export {
  useGetAllVesselsQuery,
  useGetVesselListQuery,
  useGetVesselByIdQuery,
  useAddUserToVesselMutation,
  useRemoveUserFromVesselMutation,
  useRunDataImportOrProcessingMutation,
  useGetVesselLastTripsQuery,
  useGetCaptainListQuery,
} from './apis/vesselsApi'

export {
  useGetAverageSFOCInRangeQuery,
  useGetSingleDataMetricsWithChangeInRangeQuery,
  useGetAverageFuelEfficiencyInRangeQuery,
  useGetTotalFuelConsumedBarChartInRangeQuery,
  useGetCO2EmissionsBarChartInRangeQuery,
  useGetPerformanceBarChartInRangeQuery,
  useGetTripPathInRangeQuery,
  useGetMissedSavingsBarChartQuery,
  useGetMassFlowRateDistributionQuery,
  useGetVesselDataForDashboardQuery,
} from './apis/totalsGraphApi'

export {
  useGetPowerInRangeInTimeQuery,
  useGetMassFlowRateInRangeInTimeQuery,
  useGetRPMInRangeInTimeQuery,
  useGetSOGInRangeInTimeQuery,
  useGetTotalFuelEfficiencyInTimeQuery,
} from './apis/inTimeGraphApi'

export { useGetMassFlowRateRangeAreaQuery } from './apis/areaGraphApi'

export { changeNumberOfTripsToShow } from './slices/newAnalyticsSlice'

export { useGetMassFlowRateInKnotsQuery, useGetSpeedFuelEfficiencyInKnotsQuery } from './apis/inKnotsGraphApi'

export { useLazyExportVesselDataQuery } from './apis/exportApi'

export { useGetAllCompaniesQuery, useCreateCompanyMutation, useDeleteCompanyMutation } from './apis/companyApi'

// Get the type of our store variable
export type AppStore = typeof store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
