// Create a thunk for validateAndSetNewPassword
import { createAsyncThunk } from '@reduxjs/toolkit'
import { auth } from '../../../config/firebase'
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth'

const validateAndSetNewPassword = createAsyncThunk(
  'auth/validateAndSetNewPassword',
  async ({ actionCode, password }: { actionCode: string; password: string }) => {
    await verifyPasswordResetCode(auth, actionCode)
    await confirmPasswordReset(auth, actionCode, password)
  }
)

export { validateAndSetNewPassword }
