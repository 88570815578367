import { useAppDispatch } from 'shared/hooks/useReduxHooks'
import { VesselData } from 'shared/types/vessel'
import { useEffect, useMemo } from 'react'
import { useGetTotalFuelEfficiencyInTimeQuery } from 'core/store'
import ChartWrapper from 'shared/components/data-visualization/charts/wrappers/ChartWrapper'
import Loader from 'shared/components/data-visualization/indicators/Loader'
import NoData from 'shared/components/data-visualization/indicators/NoData'
import LineChart from 'shared/components/data-visualization/charts/line-chart/LineChart'
import { LineGraphData } from 'shared/types/graphApi'
import { defaultConfig as lineChartConfig } from 'shared/components/data-visualization/charts/line-chart/LineChart'
interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
}

function FuelEfficiency({ vessel, startTime, endTime, offset }: Props) {
  const chartTitle = 'Fuel efficiency [kg/nm]'

  const { data: response, isFetching } = useGetTotalFuelEfficiencyInTimeQuery({
    vesselId: vessel.id,
    allEngineFlowMeters: [vessel.main_engines[0].flowMeter[0]],
    startTime: startTime,
    endTime: endTime,
  })

  const dataAvailable = !!response?.data && response.data.length > 0

  // Process data for D3 chart
  const chartData = useMemo(() => {
    if (!response?.data || response.data.length === 0) return null

    // Sample data if more than 1000 points
    const sampleRate = Math.ceil(response.data.length / 1000)
    const sampledData = response.data.filter((_, i) => i % sampleRate === 0)

    const timestamps = sampledData.map((d) => d.startDate._seconds)
    const values = sampledData.map((d) => d.value)

    const isPositiveOrZero = (response?.trend ?? 0) <= 0
    const lineColor = isPositiveOrZero ? '#118F1E' : '#E11D48'
    const fillColor = isPositiveOrZero ? 'rgba(17, 143, 30, 0.3)' : 'rgba(225, 29, 72, 0.3)'

    return {
      timestamps,
      datasets: [
        {
          label: chartTitle,
          data: values,
          borderColor: lineColor,
          backgroundColor: fillColor,
          fill: true,
          yAxisID: 'y',
          tension: 0.2,
        },
      ],
      trend: response.trend,
    }
  }, [response])

  if (isFetching) {
    return (
      <ChartWrapper
        title={chartTitle}
        className="h-96 relative"
      >
        <Loader />
      </ChartWrapper>
    )
  }

  if (!dataAvailable) {
    return (
      <ChartWrapper
        title={chartTitle}
        className="h-96 relative"
      >
        <NoData
          type="warning"
          title="No data available for selected period"
          text="Please, try different date range."
        />
      </ChartWrapper>
    )
  }

  return (
    <ChartWrapper
      title={chartTitle}
      className="h-96 relative"
      tooltipId="FuelEfficiency"
      tooltipText="Chart colors represent the fuel efficiency trend for selected time period: Green indicates an improvement in fuel efficiency, while Red signifies a decline."
      chartHelpText="( Click on legend items to show or hide specific lines in the chart )"
    >
      {chartData && (
        <LineChart
          datasets={chartData.datasets}
          timestamps={chartData.timestamps}
          trend={chartData.trend}
          tzOffset={offset}
          config={{
            axes: {
              y: {
                label: chartTitle,
              },
            },
          }}
        />
      )}
    </ChartWrapper>
  )
}

export default FuelEfficiency
