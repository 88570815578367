import { VesselData } from 'shared/types/vessel'
import { useGetSpeedFuelEfficiencyInKnotsQuery } from 'core/store'
import ScatterChart from 'shared/components/data-visualization/charts/scatter-chart/ScatterChart'
import ChartWrapper from 'shared/components/data-visualization/charts/wrappers/ChartWrapper'
import ToggleSwitch from 'shared/components/forms/ToggleSwitch'
import HexbinChart from 'shared/components/data-visualization/charts/hexbin-chart/HexbinChart'
import { useState, useMemo } from 'react'
import Loader from 'shared/components/data-visualization/indicators/Loader'
import NoData from 'shared/components/data-visualization/indicators/NoData'

interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
}

function SpeedOverGroundVsFuelConsumption({ vessel, startTime, endTime }: Props) {
  const { data, isFetching } = useGetSpeedFuelEfficiencyInKnotsQuery({
    vesselId: vessel.id,
    flowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
    startTime: startTime,
    endTime: endTime,
    isSplit: vessel.main_engines[0].flowMeter[0].split,
  })

  const scatterData = useMemo(
    () =>
      Array.isArray(data)
        ? data.map((item: any) => ({
            nameX: item.nameX,
            nameY: item.nameY,
            nameZ: item.nameZ,
            timestamp: item.timestamp,
            valueX: item.valueX,
            valueY: item.valueY,
            valueZ: item.valueZ,
          }))
        : [],
    [data]
  )

  const [showScatterChartSections, setShowScatterSections] = useState(true)
  const [showHexbinChartSections, setShowHexbinSections] = useState(true)

  return (
    <div className="flex flex-row gap-4">
      <ChartWrapper
        title="Speed over ground vs Fuel Consumption (Fuel efficiency as Color)"
        className="h-120"
        tooltipText="Scatter Plot of Speed, Fuel Consumption, and Fuel Efficiency Trends for the selected time period."
        tooltipId="speed-over-ground-vs-fuel-consumption-scatter-tooltip"
      >
        <div className="flex flex-1 h-full rounded-lg items-center flex-col justify-center">
          {isFetching ? (
            <Loader />
          ) : scatterData.length === 0 ? (
            <NoData
              type="warning"
              title="No data available for selected period"
              text="Please, try different date range."
            />
          ) : (
            <>
              <ScatterChart
                data={scatterData}
                config={{
                  layout: {
                    margin: { top: 10, right: 100, bottom: 60, left: 60 },
                  },
                  interactions: {
                    colorLegend: {
                      enabled: true,
                      title: 'Fuel Efficiency [kg/nm]',
                      titleFontSize: 16,
                      titlePosition: 'right',
                    },
                  },
                  chartSections: {
                    enabled: showScatterChartSections,
                    massFlowRateLowerThreshold: vessel.main_engines[0].massFlowRateLowerThreshold,
                    massFlowRateUpperThreshold: vessel.main_engines[0].massFlowRateUpperThreshold,
                  },
                  axes: {
                    x: {
                      label: scatterData[0]?.nameX,
                    },
                    y: {
                      label: scatterData[0]?.nameY,
                    },
                    z: {
                      label: scatterData[0]?.nameZ,
                    },
                  },
                }}
              />
              <div className="flex flex-row gap-2">
                <p>Company Policy Overlay</p>
                <ToggleSwitch
                  value={showScatterChartSections}
                  onChange={() => {
                    setShowScatterSections(!showScatterChartSections)
                  }}
                ></ToggleSwitch>
              </div>
            </>
          )}
        </div>
      </ChartWrapper>
      <ChartWrapper
        title="Speed over ground vs Fuel Consumption"
        className="h-120"
        tooltipText="Hex-Bin Plot of Speed Over Ground vs Fuel Consumption with Frequency Distribution for the selected time period."
        tooltipId="speed-over-ground-vs-fuel-consumption-hexbin-tooltip"
      >
        <div className="flex flex-1 h-full rounded-lg items-center flex-col justify-center">
          {isFetching ? (
            <Loader />
          ) : scatterData.length === 0 ? (
            <NoData
              type="warning"
              title="No data available for selected period"
              text="Please, try different date range."
            />
          ) : (
            <>
              <HexbinChart
                data={scatterData}
                config={{
                  layout: {
                    margin: { top: 10, right: 100, bottom: 60, left: 60 },
                  },
                  interactions: {
                    colorLegend: {
                      enabled: true,
                      title: 'Frequency [minutes]',
                      titleFontSize: 16,
                      titlePosition: 'right',
                    },
                  },
                  chartSections: {
                    enabled: showHexbinChartSections,
                    massFlowRateLowerThreshold: vessel.main_engines[0].massFlowRateLowerThreshold,
                    massFlowRateUpperThreshold: vessel.main_engines[0].massFlowRateUpperThreshold,
                  },
                  axes: {
                    x: {
                      label: scatterData[0]?.nameX,
                    },
                    y: {
                      label: scatterData[0]?.nameY,
                    },
                    z: {
                      label: scatterData[0]?.nameZ,
                    },
                  },
                }}
              />
              <div className="flex flex-row gap-2">
                <p>Company Policy Overlay</p>
                <ToggleSwitch
                  value={showHexbinChartSections}
                  onChange={() => {
                    setShowHexbinSections(!showHexbinChartSections)
                  }}
                ></ToggleSwitch>
              </div>
            </>
          )}
        </div>
      </ChartWrapper>
    </div>
  )
}

export default SpeedOverGroundVsFuelConsumption
