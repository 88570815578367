import React, { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useReduxHooks'
import { VesselData } from 'shared/types/vessel'
import { changeChartIsRendering, RootState, useGetMassFlowRateRangeAreaQuery } from 'core/store'
import ChartWrapper from 'shared/components/data-visualization/charts/wrappers/ChartWrapper'
import Loader from 'shared/components/data-visualization/indicators/Loader'
import NoData from 'shared/components/data-visualization/indicators/NoData'
import { chartColorCodes } from 'shared/utils/business/chart/chartColorCodes'
import lineRangeChartWorker from 'shared/utils/core/workers/charts/lineRangeChartWorker'
import LineChart from 'shared/components/data-visualization/charts/line-chart/LineChart'
import * as d3 from 'd3'
import { defaultConfig as lineChartConfig } from 'shared/components/data-visualization/charts/line-chart/LineChart'
interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
  tooltipText?: string
  tooltipId?: string
}

interface ChartData {
  xlabels: string[]
  timestamps: number[]
  dataset1Values: number[]
  dataset2Values: number[]
  dataset3Values: number[]
}

function MassFlowRate({ vessel, startTime, endTime, offset, tooltipText, tooltipId }: Props) {
  const dispatch = useAppDispatch()
  const [chartData, setChartData] = React.useState<ChartData | null>(null)
  const chartTitle = 'Mass flow rate [kg/h]'

  const { data, isFetching } = useGetMassFlowRateRangeAreaQuery({
    vesselId: vessel.id,
    flowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
    startTime: startTime,
    endTime: endTime,
    isSplit: true,
  })

  const dataAvailable = !!data && data.length > 0

  useEffect(() => {
    setChartData(null)
  }, [startTime, endTime])

  useEffect(() => {
    if (isFetching || !data) return
    const worker = new Worker(lineRangeChartWorker)
    worker.postMessage({ graphData: data, tzOffset: offset })
    worker.onmessage = (e: MessageEvent) => {
      setChartData(e.data)
      dispatch(changeChartIsRendering(false))
    }
    return () => {
      worker.terminate()
    }
  }, [data, isFetching, offset, dispatch])

  const processedData = useMemo(() => {
    if (!chartData) return null

    const { timestamps, dataset1Values, dataset2Values, dataset3Values } = chartData

    return {
      timestamps,
      datasets: [
        {
          label: 'Max',
          data: dataset2Values,
          borderColor: 'transparent',
          backgroundColor: `rgba(${chartColorCodes.green}, 0.3)`,
          fill: false,
          tension: 0.2,
          yAxisID: 'y',
        },
        {
          label: 'Min',
          data: dataset3Values,
          borderColor: 'transparent',
          backgroundColor: `rgba(${chartColorCodes.green}, 0.5)`,
          fill: false,
          tension: 0.2,
          yAxisID: 'y',
        },
        {
          label: 'Avg',
          data: dataset1Values,
          borderColor: `rgb(${chartColorCodes.green})`,
          backgroundColor: 'transparent',
          fill: false,
          tension: 0.2,
          yAxisID: 'y',
        },
      ],
    }
  }, [chartData])

  if (isFetching || !processedData) {
    return (
      <ChartWrapper
        title={chartTitle}
        className="h-96 MassFlowRate"
        tooltipText={tooltipText}
        tooltipId={tooltipId}
      >
        <Loader />
      </ChartWrapper>
    )
  }

  if (!dataAvailable) {
    return (
      <ChartWrapper
        title={chartTitle}
        className="h-96 MassFlowRate"
        tooltipText={tooltipText}
        tooltipId={tooltipId}
      >
        <NoData
          type="warning"
          title="No data available for selected period"
          text="Please, try different date range."
        />
      </ChartWrapper>
    )
  }

  return (
    <ChartWrapper
      title={chartTitle}
      className="h-96 MassFlowRate"
      tooltipText={tooltipText}
      tooltipId={tooltipId}
      chartHelpText="( Click on legend items to show or hide specific lines in the chart )"
    >
      <LineChart
        datasets={processedData.datasets}
        timestamps={processedData.timestamps}
        config={{
          interactions: {
            legend: {
              excludeFromLegend: [0, 1],
              useExternalLegendClick: true,
              onLegendClick: (index) => {
                const svg = d3.select('.MassFlowRate svg')
                const avgLine = svg.selectAll('.dataset-2')
                const isHidden = avgLine.style('opacity') === '0'
                avgLine.style('opacity', isHidden ? 1 : 0)
              },
            },
          },
          axes: {
            y: {
              fillBetweenDatasets: true,
              label: chartTitle,
            },
          },
        }}
      />
    </ChartWrapper>
  )
}

export default MassFlowRate
