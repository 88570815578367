export default function formatTimeAgo(minutes: number): string {
  if (minutes < 1) return 'Less than a minute ago'
  if (minutes < 60) return `${minutes} minutes ago`

  const hours = Math.floor(minutes / 60)
  if (hours < 24) return `${hours} hours ago`

  const days = Math.floor(hours / 24)
  if (days < 7) return `${days} days ago`

  const weeks = Math.floor(days / 7)
  return `${weeks} weeks ago`
}
