import { VesselData } from 'shared/types/vessel'
import { useGetMissedSavingsBarChartQuery } from 'core/store/apis/totalsGraphApi'
import { useEffect, useMemo } from 'react'
import DoughnutChartV2 from '../../../shared/components/data-visualization/charts/doughnut-chart/DoughnutChartV2'
import BarChart from '../../../shared/components/data-visualization/charts/bar-chart/BarChart'
import ChartWrapper from '../../../shared/components/data-visualization/charts/wrappers/ChartWrapper'
import Loader from '../../../shared/components/data-visualization/indicators/Loader'
import NoData from '../../../shared/components/data-visualization/indicators/NoData'
import { chartColorCodes } from 'shared/utils/business/chart/chartColorCodes'
import { defaultConfig as barChartDefaultConfig } from '../../../shared/components/data-visualization/charts/bar-chart/BarChart'
interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
  tooltipText?: string
  tooltipId?: string
}

function MissedSavings({ vessel, startTime, endTime, offset, tooltipText, tooltipId }: Props) {
  const {
    data: apiData,
    isFetching,
    error,
  } = useGetMissedSavingsBarChartQuery(
    {
      vesselId: vessel.id,
      mainEngineFlowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
      isSplit: false,
      startTime: startTime,
      endTime: endTime,
      massFlowRateUpperThreshold: vessel.main_engines[0].massFlowRateUpperThreshold,
      massFlowRateLowerThreshold: vessel.main_engines[0].massFlowRateLowerThreshold,
    },
    { skip: startTime === 0 || endTime === 0 }
  )

  const barChartData = useMemo(() => {
    if (!apiData) return []

    return [
      {
        label: 'Perfect Consumption',
        data: apiData.data.map((item) => ({
          category: item.category,
          value: item.value.perfectConsumption,
        })),
        backgroundColor: `rgb(${chartColorCodes.blue})`,
      },
      {
        label: 'Missed Savings',
        data: apiData.data.map((item) => ({
          category: item.category,
          value: item.value.missedSavings,
        })),
        backgroundColor: `rgb(${chartColorCodes.gray})`,
      },
    ]
  }, [apiData])

  const doughnutChartData = useMemo(() => {
    if (!apiData) return []

    const totalPerfectConsumption = Number(
      apiData.data.reduce((acc, curr) => acc + curr.value.perfectConsumption, 0).toFixed(1)
    )
    const totalMissedSavings = Number(apiData.data.reduce((acc, curr) => acc + curr.value.missedSavings, 0).toFixed(1))

    return [
      {
        name: 'Perfect Consumption',
        value: totalPerfectConsumption,
        unit: 't',
        color: `rgb(${chartColorCodes.blue})`,
      },
      {
        name: 'Missed Savings',
        value: totalMissedSavings,
        unit: 't',
        color: `rgb(${chartColorCodes.gray})`,
      },
    ]
  }, [apiData])

  const hasData = useMemo(() => {
    if (!apiData) return false
    const totalPerfectConsumption = apiData.data.reduce((acc, curr) => acc + curr.value.perfectConsumption, 0)
    const totalMissedSavings = apiData.data.reduce((acc, curr) => acc + curr.value.missedSavings, 0)
    return totalPerfectConsumption > 0 || totalMissedSavings > 0
  }, [apiData])

  return (
    <div className="flex gap-4">
      <div className="w-80 xl:w-96 3xl:w-[28rem]">
        <DoughnutChartV2
          data={doughnutChartData}
          title="M/E Fuel Consumption and Missed Savings"
          loading={isFetching}
          dataAvailable={hasData}
          tooltipText={tooltipText}
          tooltipId={tooltipId}
          className="h-72"
          noMarginBottom={true}
          config={{
            center: {
              enabled: true,
              text: {
                primary: `${doughnutChartData[1]?.value || 0} t`,
                secondary: 'Missed Savings',
              },
            },
            interactions: {
              legend: {
                showUnits: true,
              },
            },
          }}
        />
      </div>
      <div className="flex-1">
        <ChartWrapper
          title="M/E Fuel Consumption and Missed Savings"
          tooltipText={tooltipText}
          tooltipId={tooltipId}
          className="h-72"
        >
          {isFetching ? (
            <Loader />
          ) : !hasData ? (
            <NoData
              type="warning"
              title="No data available for selected period"
              text="Please, try different date range."
            />
          ) : (
            <BarChart
              datasets={barChartData}
              tzOffset={offset}
              config={{
                layout: {
                  margin: { top: 6, right: 80, bottom: 90, left: 70 },
                },
                axes: {
                  y: {
                    label: 'Perfect Consumption [mt]',
                  },
                  x: {
                    diagonalText: true,
                  },
                },
              }}
            />
          )}
        </ChartWrapper>
      </div>
    </div>
  )
}

export default MissedSavings
