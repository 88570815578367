import React, { useRef, useState, useMemo } from 'react'
import * as d3 from 'd3'
import { createChartAxes } from '../shared/ChartAxes'
import { createChartLegend } from '../shared/ChartLegend'
import { Tooltip } from '../shared/Tooltip'
import { useD3 } from '../hooks/useD3'
import deepMerge from 'shared/utils/core/helpers/objects/deepObjectsMerge'
import { getBeaufortColor, getBeaufortTextColor } from 'shared/utils/business/chart/beaufortColors'
interface DataPoint {
  category: string
  value: number
  beaufortNumber?: number
}

interface Dataset {
  label: string
  data: DataPoint[]
  backgroundColor: string
}

// chart config
export interface BarChartConfig {
  layout: {
    margin: {
      top: number
      right: number
      bottom: number
      left: number
    }
    barWidth: number
    barPadding: number
    axisPadding: number
  }
  interactions: {
    tooltip: {
      enabled: boolean
      showVerticalLine: boolean
    }
    legend: {
      enabled: boolean
      clickable: boolean
      /**
       * @description (NOT WORKING) The position of the legend.
       */
      position: 'top' | 'bottom'
      /**
       * @description The padding between the legend and the bottom of the container.
       */
      bottomPadding: number
      /**
       * @description The gap between the legend items.
       */
      legendGap: number
    }
  }
  axes: {
    x: {
      label: string
      diagonalText: boolean
    }
    y: {
      label: string
      /**
       * @description (NOT WORKING, ALWAYS ROUNDED) Whether to round the ticks to the nearest integer.
       */
      nice: boolean
      ticks: number
      /**
       * @description Custom range for y-axis. If not provided, will be calculated automatically.
       */
      range?: {
        min: number
        max: number
      }
      hideValues?: boolean
    }
    y1: {
      enabled: boolean
      label: string
      /**
       * @description (NOT WORKING, ALWAYS ROUNDED) Whether to round the ticks to the nearest integer.
       */
      nice: boolean
      ticks: number
    }
  }
}

// default config
export const defaultConfig: BarChartConfig = {
  layout: {
    margin: { top: 5, right: 40, bottom: 40, left: 80 },
    barWidth: 100,
    barPadding: 20,
    axisPadding: 20,
  },
  interactions: {
    tooltip: {
      enabled: true,
      showVerticalLine: true,
    },
    legend: {
      enabled: true,
      clickable: true,
      position: 'bottom',
      bottomPadding: 70,
      legendGap: 20,
    },
  },
  axes: {
    x: {
      label: '',
      diagonalText: false,
    },
    y: {
      label: '',
      nice: true,
      ticks: 7,
      hideValues: false,
    },
    y1: {
      enabled: true,
      label: '',
      nice: true,
      ticks: 7,
    },
  },
}

export type BarChartCustomConfig = {
  layout?: {
    margin?: Partial<BarChartConfig['layout']['margin']>
    barWidth?: number
    barPadding?: number
    axisPadding?: number
  }
  interactions?: {
    tooltip?: Partial<BarChartConfig['interactions']['tooltip']>
    legend?: Partial<BarChartConfig['interactions']['legend']>
  }
  axes?: {
    x?: Partial<BarChartConfig['axes']['x']>
    y?: {
      range?: BarChartConfig['axes']['y']['range']
      nice?: boolean
      ticks?: number
      label?: string
      hideValues?: boolean
    }
    y1?: Partial<BarChartConfig['axes']['y1']>
  }
}

interface Props {
  datasets: Dataset[]
  onLegendClick?: (index: number) => void
  tzOffset?: number
  config?: BarChartCustomConfig
}

const BarChart: React.FC<Props> = ({ datasets, config: customConfig, onLegendClick, tzOffset = 0 }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<Tooltip | null>(null)
  const [hiddenDatasets, setHiddenDatasets] = useState<Set<number>>(new Set())
  const [showBeaufort, setShowBeaufort] = useState(true)

  // merge default config with custom config
  const config = useMemo(() => deepMerge({ ...defaultConfig }, customConfig || {}) as BarChartConfig, [customConfig])

  // Function to determine if time should be shown based on date range
  const shouldShowTime = useMemo(() => {
    if (!datasets.length || !datasets[0].data.length) return false

    const dates = datasets[0].data.map((d) => {
      const date = new Date(parseInt(d.category))
      date.setTime(date.getTime() + tzOffset * 3600000)
      return date
    })

    // Calculate average interval between bars in hours
    const intervals = []
    for (let i = 1; i < dates.length; i++) {
      intervals.push((dates[i].getTime() - dates[i - 1].getTime()) / (1000 * 60 * 60))
    }
    const avgInterval = intervals.reduce((a, b) => a + b, 0) / intervals.length

    // Show time if average interval between bars is less than 6 hours
    return avgInterval < 6
  }, [datasets, tzOffset])

  const createChart = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => {
    if (!containerRef.current || !datasets.length) return

    // Clean up previous tooltip
    if (tooltipRef.current) {
      tooltipRef.current.destroy()
      tooltipRef.current = null
    }

    // Get container dimensions
    const containerRect = containerRef.current.getBoundingClientRect()
    const width = containerRect.width
    const height = containerRect.height

    // Clear previous chart
    svg.selectAll('*').remove()

    svg
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')

    const chartWidth = width - config.layout.margin.left - config.layout.margin.right
    const chartHeight = height - config.layout.margin.top - config.layout.margin.bottom

    // Filter out hidden datasets
    const visibleDatasets = datasets.filter((_, index) => !hiddenDatasets.has(index))

    // Process data for stacking with only visible datasets
    const dates = Array.from(
      new Set(
        datasets[0].data.map((d) => {
          const date = new Date(parseInt(d.category))
          date.setTime(date.getTime() + tzOffset * 3600000)
          return date
        })
      )
    )
    interface StackedDataPoint {
      date: Date
      beaufortNumber?: number
      [key: string]: any
    }
    const stackedData: StackedDataPoint[] = dates.map((date) => {
      const obj: StackedDataPoint = { date }
      visibleDatasets.forEach((dataset) => {
        obj['beaufortNumber'] = dataset.data.find((d) => {
          const pointDate = new Date(parseInt(d.category))
          pointDate.setTime(pointDate.getTime() + tzOffset * 3600000)
          return pointDate.getTime() === date.getTime()
        })?.beaufortNumber
        const point = dataset.data.find((d) => {
          const pointDate = new Date(parseInt(d.category))
          pointDate.setTime(pointDate.getTime() + tzOffset * 3600000)
          return pointDate.getTime() === date.getTime()
        })
        obj[dataset.label] = point ? point.value : 0
      })
      return obj
    })

    // Calculate bar width and padding
    const barWidth = Math.min((chartWidth / dates.length) * 0.8, config.layout.barWidth)
    const axisPadding = config.layout.axisPadding

    // Create scales
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(dates) as [Date, Date])
      .range([barWidth / 2 + axisPadding, chartWidth - barWidth / 2 - axisPadding])

    const yScale = d3
      .scaleLinear()
      .domain([
        config.axes.y.range?.min ?? 0,
        config.axes.y.range?.max ??
          (d3.max(stackedData, (d) => {
            return d3.sum(visibleDatasets.map((dataset) => d[dataset.label]))
          }) ||
            0),
      ])
      .range([chartHeight, 0])
      .nice(config.axes.y.ticks)

    const y1Scale = config.axes.y1.enabled
      ? d3.scaleLinear().domain(yScale.domain()).range([chartHeight, 0]).nice(config.axes.y1.ticks)
      : undefined

    // Add axes using ChartAxes component
    const chartGroup = createChartAxes({
      svg,
      width,
      height,
      margin: config.layout.margin,
      xScale,
      yScale,
      y1Scale,
      xAxisLabel: config.axes.x.label,
      yAxisLabel: config.axes.y.label,
      y1AxisLabel: config.axes.y1.label,
      xAxisTickValues: dates,
      diagonalXAxisText: config.axes.x.diagonalText,
      showTimeOnXAxis: shouldShowTime,
      hideYAxisValues: config.axes.y.hideValues,
    })

    // Create stack generator for visible datasets only
    const stack = d3
      .stack()
      .keys(visibleDatasets.map((d) => d.label))
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone)

    const stackedBars = stack(stackedData)

    // Create and render bars for visible datasets
    visibleDatasets.forEach((dataset, visibleIndex) => {
      const originalIndex = datasets.findIndex((d) => d.label === dataset.label)
      chartGroup
        .selectAll(`.bars-${originalIndex}`)
        .data(stackedBars[visibleIndex])
        .join('rect')
        .attr('class', `bars-${originalIndex}`)
        .attr('x', (d) => xScale(d.data.date) - barWidth / 2)
        .attr('y', (d) => yScale(d[1]))
        .attr('height', (d) => yScale(d[0]) - yScale(d[1]))
        .attr('width', barWidth)
        .attr('fill', dataset.backgroundColor)
    })

    // Add Beaufort numbers above bars
    if (datasets[0]?.data.some((d) => d.beaufortNumber)) {
      if (datasets[0]?.data) {
        const shouldRotate = datasets[0].data.length > 30
        const beaufortGroup = chartGroup
          .selectAll('.beaufort-group')
          .data(datasets[0].data)
          .join('g')
          .attr('class', 'beaufort-group')
          .attr('style', (d) => {
            return `display: ${d.beaufortNumber ? 'block' : 'none'}`
          })
          .attr('transform', (d) => {
            const date = new Date(parseInt(d.category))
            date.setTime(date.getTime() + tzOffset * 3600000)
            const x = xScale(date)
            const y = (() => {
              const maxValue =
                d3.max(stackedBars, (stack) => {
                  const barData = stack.find((b) => {
                    const barDate = new Date(b.data.date)
                    return barDate.getTime() === date.getTime()
                  })
                  return barData ? barData[1] : 0
                }) || 0
              return yScale(maxValue) - (shouldRotate ? 15 : 5)
            })()
            return `translate(${x},${y})`
          })

        // Add background rectangle
        beaufortGroup
          .append('rect')
          .attr('width', (d) =>
            d.beaufortNumber ? (shouldRotate ? 16 : d.beaufortNumber.toString().length > 1 ? 24 : 16) : 0
          )
          .attr('height', (d) =>
            d.beaufortNumber ? (shouldRotate ? (d.beaufortNumber.toString().length > 1 ? 24 : 16) : 16) : 0
          )
          .attr('x', (d) =>
            d.beaufortNumber ? (shouldRotate ? -8 : d.beaufortNumber.toString().length > 1 ? -12 : -8) : 0
          )
          .attr('y', (d) =>
            d.beaufortNumber ? (shouldRotate ? (d.beaufortNumber.toString().length > 1 ? -12 : -8) : -12) : 0
          )
          .attr('rx', 4)
          .attr('fill', (d) => getBeaufortColor(d.beaufortNumber))

        // Add text
        beaufortGroup
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'middle')
          .attr('font-size', '12px')
          .attr('font-weight', '800')
          .attr('fill', (d) => getBeaufortTextColor(d.beaufortNumber))
          .attr('transform', shouldRotate ? 'rotate(-90)' : '')
          .attr('y', shouldRotate ? '0' : '-3')
          .text((d) => d.beaufortNumber?.toString() || '')
      }
    }
    // Add legend
    if (config.interactions.legend.enabled) {
      // check if we have beaufort numbers in data
      const hasBeaufortNumbers = datasets[0]?.data.some((d) => d.beaufortNumber)

      createChartLegend({
        svg,
        items: [
          ...datasets.map((d) => ({ label: d.label, color: d.backgroundColor })),
          // add beaufort item only if we have beaufort numbers
          ...(hasBeaufortNumbers
            ? [
                {
                  label: 'Beaufort',
                  color: '#D1E189',
                  textInside: true,
                },
              ]
            : []),
        ],
        width,
        height,
        margin: config.layout.margin,
        onLegendClick: (index) => {
          if (hasBeaufortNumbers && index === datasets.length) {
            // if clicked on beaufort, toggle visibility
            setShowBeaufort(!showBeaufort)
          } else {
            // if clicked on normal item, toggle visibility
            setHiddenDatasets((prev) => {
              const newHidden = new Set(prev)
              if (newHidden.has(index)) {
                newHidden.delete(index)
              } else {
                newHidden.add(index)
              }
              return newHidden
            })
            onLegendClick?.(index)
          }
        },
        bottomPadding: config.interactions.legend.bottomPadding,
        clickable: config.interactions.legend.clickable,
        activeIndices: [
          ...datasets.map((_, i) => !hiddenDatasets.has(i)),
          ...(hasBeaufortNumbers ? [showBeaufort] : []),
        ],
        legendGap: config.interactions.legend.legendGap,
      })

      // update beaufort visibility only if we have beaufort numbers
      if (hasBeaufortNumbers) {
        chartGroup
          .selectAll<SVGGElement, DataPoint>('.beaufort-group')
          .style('display', (d) => (showBeaufort && d.beaufortNumber ? 'block' : 'none'))
      }
    }

    // Initialize tooltip
    if (config.interactions.tooltip.enabled) {
      tooltipRef.current = new Tooltip({
        container: containerRef.current,
        chartGroup,
        xScale,
        height: chartHeight,
        showVerticalLine: config.interactions.tooltip.showVerticalLine,
        showTimeOnXAxis: shouldShowTime,
        getValues: (date: Date) => {
          const mouseX = xScale(date)
          const bisect = d3.bisector((d: Date) => d).left
          const index = bisect(dates, date)

          const x0 = dates[Math.max(0, index - 1)]
          const x1 = dates[Math.min(dates.length - 1, index)]

          const distanceToD0 = Math.abs(xScale(x0) - mouseX)
          const distanceToD1 = Math.abs(xScale(x1) - mouseX)

          const barThreshold = barWidth / 2
          if (Math.min(distanceToD0, distanceToD1) > barThreshold) {
            return []
          }

          const closestDate = distanceToD0 < distanceToD1 ? x0 : x1
          const closestIndex = dates.findIndex((d) => d.getTime() === closestDate.getTime())

          // find beaufort number for this date
          const beaufortNumber = datasets[0]?.data.find((d) => {
            const pointDate = new Date(parseInt(d.category))
            pointDate.setTime(pointDate.getTime() + tzOffset * 3600000)
            return pointDate.getTime() === closestDate.getTime()
          })?.beaufortNumber

          const values = visibleDatasets.map((dataset) => {
            const stackData = stackedBars.find((s) => s.key === dataset.label)
            const value = stackData ? stackData[closestIndex][1] - stackData[closestIndex][0] : 0
            return {
              label: dataset.label,
              value,
              decimals: 2,
              color: dataset.backgroundColor,
              xValue: closestDate,
            }
          })

          // add beaufort number to values
          if (beaufortNumber !== undefined && beaufortNumber !== null) {
            values.push({
              label: 'Beaufort',
              value: beaufortNumber,
              decimals: 0,
              color: getBeaufortColor(beaufortNumber),
              xValue: closestDate,
            })
          }

          return values
        },
      })

      // Add hover area
      const hoverArea = svg
        .append('rect')
        .attr('transform', `translate(${config.layout.margin.left},${config.layout.margin.top})`)
        .attr('class', 'overlay')
        .attr('width', chartWidth)
        .attr('height', chartHeight)
        .style('fill', 'none')
        .style('pointer-events', 'all')

      tooltipRef.current.addTo(hoverArea)
    }
  }

  const svgRef = useD3(createChart, [datasets, config, onLegendClick, hiddenDatasets, tzOffset, showBeaufort])

  return (
    <div
      ref={containerRef}
      style={{ width: '100%', height: '100%', userSelect: 'none', WebkitUserSelect: 'none' }}
    >
      <svg
        ref={svgRef}
        style={{ width: '100%', height: '100%', userSelect: 'none', WebkitUserSelect: 'none' }}
      />
    </div>
  )
}

export default BarChart
