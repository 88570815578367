import ChartWrapper from 'shared/components/data-visualization/charts/wrappers/ChartWrapper'
import HeatmapChart from 'shared/components/data-visualization/charts/heatmap-chart/HeatmapChart'
import Loader from 'shared/components/data-visualization/indicators/Loader'
import NoData from 'shared/components/data-visualization/indicators/NoData'
import { VesselData } from 'shared/types/vessel'
import { useGetMassFlowRateDistributionQuery } from 'core/store'
import { useEffect, useState } from 'react'

interface ChartDataPoint {
  timeRange: number
  massFlowRange: string
  percentage: number
}

interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
  tooltipText?: string
  tooltipId?: string
}

function MassFlowRateDistribution({ vessel, startTime, endTime, offset, tooltipText, tooltipId }: Props) {
  const [adjustedData, setAdjustedData] = useState<ChartDataPoint[]>([])

  const { data: apiData, isFetching } = useGetMassFlowRateDistributionQuery({
    vesselId: vessel.id,
    mainEngineFlowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
    isSplit: false,
    startTime: startTime,
    endTime: endTime,
  })

  useEffect(() => {
    if (!apiData?.data || !apiData.ranges) return

    const adjustedChartData: ChartDataPoint[] = []

    for (const timePoint of apiData.data) {
      const adjustedTimestamp = timePoint.timestamp + offset * 3600 * 1000

      for (const range of apiData.ranges) {
        adjustedChartData.push({
          timeRange: adjustedTimestamp,
          massFlowRange: range,
          percentage: (timePoint[range] as number) || 0,
        })
      }
    }

    setAdjustedData(adjustedChartData)
  }, [apiData, offset])

  return (
    <div>
      <ChartWrapper
        title="Mass Flow Rate Distribution"
        className="h-[600px]"
        tooltipText={tooltipText}
        tooltipId={tooltipId}
      >
        {isFetching ? (
          <div className="flex h-full items-center justify-center">
            <Loader />
          </div>
        ) : !apiData?.data || apiData.data.length === 0 ? (
          <div className="flex h-full items-center justify-center">
            <NoData
              type="warning"
              title="No data available for selected period"
              text="Please, try different date range."
            />
          </div>
        ) : (
          <HeatmapChart
            data={adjustedData}
            config={{
              layout: {
                margin: { top: 40, right: 100, bottom: 80, left: 100 },
              },
            }}
          />
        )}
      </ChartWrapper>
    </div>
  )
}

export default MassFlowRateDistribution
