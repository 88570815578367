import React, { useRef, useState, useEffect, useMemo } from 'react'
import * as d3 from 'd3'
import * as d3Hexbin from 'd3-hexbin'
import { createChartAxes } from '../shared/ChartAxes'
import { createChartSections } from '../shared/ChartSections'
import { Tooltip } from '../shared/Tooltip'
import { createColorLegend } from '../shared/ColorLegend'
import { useD3 } from '../hooks/useD3'
import deepMerge from 'shared/utils/core/helpers/objects/deepObjectsMerge'
interface DataPoint {
  nameX: string
  nameY: string
  nameZ: string
  timestamp: number | null
  valueX: number
  valueY: number
  valueZ: number
}

export interface HexbinChartConfig {
  layout: {
    margin: {
      top: number
      right: number
      bottom: number
      left: number
    }
    radius: number
  }
  interactions: {
    colorLegend: {
      enabled: boolean
      title: string
      titlePosition: 'left' | 'right'
      titleFontSize: number
    }
  }
  axes: {
    x: {
      label: string
      ticks: number
    }
    y: {
      label: string
      ticks: number
    }
    z: {
      label: string
    }
  }
  chartSections: {
    enabled: boolean
    massFlowRateLowerThreshold: number
    massFlowRateUpperThreshold: number
  }
}

export const defaultConfig: HexbinChartConfig = {
  layout: {
    margin: { top: 5, right: 100, bottom: 60, left: 60 },
    radius: 10,
  },
  interactions: {
    colorLegend: {
      enabled: true,
      title: '',
      titlePosition: 'right',
      titleFontSize: 16,
    },
  },
  axes: {
    x: {
      label: '',
      ticks: 10,
    },
    y: {
      label: '',
      ticks: 10,
    },
    z: {
      label: '',
    },
  },
  chartSections: {
    enabled: false,
    massFlowRateLowerThreshold: 0,
    massFlowRateUpperThreshold: 0,
  },
}

export type HexbinChartCustomConfig = {
  layout?: {
    margin?: Partial<HexbinChartConfig['layout']['margin']>
    radius?: number
  }
  interactions?: {
    colorLegend?: Partial<HexbinChartConfig['interactions']['colorLegend']>
  }
  axes?: {
    x?: Partial<HexbinChartConfig['axes']['x']>
    y?: Partial<HexbinChartConfig['axes']['y']>
    z?: Partial<HexbinChartConfig['axes']['z']>
  }
  chartSections?: Partial<HexbinChartConfig['chartSections']>
}

interface Props {
  data: DataPoint[]
  config?: HexbinChartCustomConfig
}

const useChartDimensions = (containerRef: React.RefObject<HTMLDivElement>) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (!containerRef.current) return

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect
        setDimensions({ width, height })
      }
    })

    resizeObserver.observe(containerRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return dimensions
}

const HexbinChart: React.FC<Props> = ({ data, config: customConfig }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<Tooltip | null>(null)
  const dimensions = useChartDimensions(containerRef)

  const config = useMemo(() => deepMerge({ ...defaultConfig }, customConfig || {}) as HexbinChartConfig, [customConfig])

  useEffect(() => {
    return () => {
      if (tooltipRef.current) {
        tooltipRef.current.destroy()
      }
    }
  }, [])

  const createChart = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => {
    if (!data || !dimensions || dimensions.width === 0 || dimensions.height === 0) return

    // Clean up previous chart and tooltip
    svg.selectAll('*').remove()
    if (tooltipRef.current) {
      tooltipRef.current.destroy()
      tooltipRef.current = null
    }

    const { width, height } = dimensions
    const chartWidth = width - config.layout.margin.left - config.layout.margin.right
    const chartHeight = height - config.layout.margin.top - config.layout.margin.bottom

    // Set up SVG attributes
    svg.attr('width', width).attr('height', height)

    const maxSpeed = d3.max(data, (d) => d.valueX) || 0
    const maxFuelConsumption = d3.max(data, (d) => d.valueY) || 0
    const xAxisPadding = maxSpeed * 0.1

    const xScale = d3
      .scaleLinear()
      .domain([0, maxSpeed + xAxisPadding])
      .range([0, chartWidth])
      .nice()

    const yScale = d3
      .scaleLinear()
      .domain([0, maxFuelConsumption * 1.1])
      .range([chartHeight, 0])
      .nice(config.axes.y.ticks)

    if (config.chartSections.enabled) {
      createChartSections({
        svg,
        width,
        height,
        margin: config.layout.margin,
        yScale,
        massFlowRateLowerThreshold: config.chartSections.massFlowRateLowerThreshold,
        massFlowRateUpperThreshold: config.chartSections.massFlowRateUpperThreshold,
      })
    }

    createChartAxes({
      svg,
      width,
      height,
      margin: config.layout.margin,
      xScale,
      yScale,
      xAxisLabel: config.axes.x.label,
      yAxisLabel: config.axes.y.label,
      y1AxisLabel: config.axes.z.label,
      yAxisTickCount: config.axes.y.ticks,
    })

    // Create tooltip
    if (containerRef.current) {
      tooltipRef.current = new Tooltip({
        container: containerRef.current,
        getValues: (d) => {
          const points = d as DataPoint[]
          const avgSpeed = d3.mean(points, (point) => point.valueX)
          const avgConsumption = d3.mean(points, (point) => point.valueY)
          return [
            { label: 'Avg Speed', value: avgSpeed || 0, unit: ' knots' },
            { label: 'Avg Consumption', value: avgConsumption || 0, unit: ' kg/h' },
            { label: 'Frequency', value: points.length },
          ]
        },
        showTitle: false,
      })
    }

    const hexbin = d3Hexbin
      .hexbin<DataPoint>()
      .x((d) => xScale(d.valueX))
      .y((d) => yScale(d.valueY))
      .radius(config.layout.radius)
      .extent([
        [0, 0],
        [chartWidth, chartHeight],
      ])

    const bins = hexbin(data)
    const maxValue = 100

    const colorScale = d3.scaleSequential().domain([0, maxValue]).interpolator(d3.interpolateViridis).clamp(true)

    const chartGroup = svg
      .append('g')
      .attr('transform', `translate(${config.layout.margin.left},${config.layout.margin.top})`)

    // Create a unique identifier for the clipPath
    const clipPathId = `chart-area-clip-${Math.floor(Math.random() * 10000)}`

    // Add clipPath to crop hexagons that extend beyond the chart boundaries
    chartGroup
      .append('defs')
      .append('clipPath')
      .attr('id', clipPathId)
      .append('rect')
      .attr('width', chartWidth)
      .attr('height', chartHeight)
      .attr('x', 0)
      .attr('y', 0)

    // Create a group for hexagons with clipPath applied
    const hexbinGroup = chartGroup.append('g').attr('clip-path', `url(#${clipPathId})`)

    const hexbins = hexbinGroup
      .selectAll('path')
      .data(bins)
      .enter()
      .append('path')
      .attr('d', hexbin.hexagon())
      .attr('transform', (d) => `translate(${d.x},${d.y})`)
      .attr('fill', (d) => colorScale(d.length))
      .attr('stroke', '#000')
      .attr('stroke-width', '0.1')
      .attr('opacity', 1)

    if (tooltipRef.current) {
      tooltipRef.current.addTo(hexbins)
    }

    createColorLegend({
      svg,
      width,
      height,
      margin: config.layout.margin,
      colorScale,
      maxValue: maxValue,
      title: config.interactions.colorLegend.title,
      titlePosition: config.interactions.colorLegend.titlePosition,
      titleFontSize: config.interactions.colorLegend.titleFontSize,
    })
  }

  const svgRef = useD3(createChart, [data, dimensions, config])

  return (
    <div
      ref={containerRef}
      style={{ width: '100%', height: '100%', userSelect: 'none', WebkitUserSelect: 'none' }}
    >
      <svg
        ref={svgRef}
        style={{ width: '100%', height: '100%', userSelect: 'none', WebkitUserSelect: 'none' }}
      />
    </div>
  )
}

export default HexbinChart
