import { ErrorCodes } from '../../../../core/config/constants/enums'

export function handleError(errorString: string): string {
  const errorCode = extractContent(errorString)
  switch (errorCode) {
    case ErrorCodes.INSUFFICIENT_PERMISSION:
      return 'Insufficient permission'
    case ErrorCodes.EMAIL_ALREADY_EXISTS:
      return 'Provided email is already in use'
    case ErrorCodes.ID_TOKEN_EXPIRED:
      return 'User token expired'
    case ErrorCodes.INTERNAL_ERROR:
      return 'The Authentication server encountered an unexpected error while trying to process the request'
    case ErrorCodes.INVALID_CREDENTIAL:
      return 'Invalid credentials'
    case ErrorCodes.INVALID_EMAIL:
      return 'The provided value for the email is invalid'
    case ErrorCodes.INVALID_TOKEN:
      return 'The provided ID token is not a valid'
    case ErrorCodes.INVALID_PASSWORD:
      return 'The provided value for the password user property is invalid'
    case ErrorCodes.INVALID_UID:
      return 'Invalid user ID'
    case ErrorCodes.MISSING_UID:
      return 'Missing user ID'
    case ErrorCodes.TOO_MANY_REQUESTS:
      return 'The number of requests exceeds the maximum allowed'
    case ErrorCodes.USER_NOT_FOUND:
      return 'User not found'
    case ErrorCodes.WRONG_PASSWORD:
      return 'Wrong password'
    case ErrorCodes.USER_DISABLED:
      return 'User is not enabled'
    default:
      return 'An unknown error occurred. Please try again later.'
  }
}

function extractContent(inputString: string) {
  const regex = /\/(.*?)\)/
  const match = inputString.match(regex)

  if (match) {
    return match[1]
  } else {
    return null
  }
}

export function handleThunkError(error: any) {
  console.log('error', error)
}
